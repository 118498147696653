@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

:root {
    --mainColor:  #1e76bd;
    --boxShadowColor: #1e76bd33;
    --headerColor: var(--mainColor);
    --headerFontColor: #fff;
    --menuColor:  #fff;
    --menuFontColor: #444;
    --grayBack: #f9f6f6;
    --serviceBackColor: #f6f7f9;
    --footerColor: #f7f7f7;
    --footerBottomColor: var(--mainColor);
    --advantagesColor: var(--mainColor);
    --fontFamily: 'Montserrat', sans-serif;
    --fontFamily2: 'Roboto', sans-serif;
}
@import './icons/style';
@import './css/style';

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}
.accordion-item{
    margin-bottom: 10px;
    border: 0;
}

.accordion-button:not(.collapsed){
    box-shadow: none;
    background-color: #fff;
}

.accordion-body{
    background-color: #f6f7f5;
    color: #787878;
}

.accordion-button:focus{
    box-shadow: none;
}

.reviews-carousel{
    padding-bottom: 120px;

    .slick-prev{
        right: 50px;
        left: auto;
    }
    .slick-prev:before, .slick-next:before{
        color: var(--mainColor);
    }
    .slick-prev:hover{
        &:before{
            color: #fff;
        }
    }
    .slick-next:hover{
        &:before{
            color: #fff;
        }
    }
}

.sm-gallery-row {

    img{
        width: calc(100%/5);
        object-fit: cover;
        height: 234px;
        transition: .3s;

        &:hover{
            transform: scale(1.1);
        }
    }
}

.post-meta{
    .post-meta-social{
        button{
            margin-right: 4px;
        }
    }
}

@media(max-width: 1023px){
    .sm-gallery-row img{
        width: 25%;
        height: 184px;
    }
}
@media(max-width: 767px){
    .sm-gallery-row img{
        width: 50%;
        height: 172px;
    }
}

.text-component{
    img[style*="float: left"] {
        margin-right:20px;
    }
      
    img[style*="float: right"] {
        margin-left: 20px;
    }
}

.footer-social{
    svg path{
        fill: var(--mainColor);
    }
}

.menu-list{
    list-style: none;
    padding: 0;
    margin: 0;
}

.header-social{
    .svg-a{
        svg{
            position: relative;
            bottom: 4px;
        }
        .fill-a{
            fill: var(--headerColor);
        }
    }
}

@media(max-width: 767px){
    .footer-logo{
        display: flex;
        justify-content: center;
    }
    .footer-text{
        text-align: center;
    }
    .footer-social{
        display: flex;
        justify-content: center;
    }
    .footer-center{
        display: flex;
        justify-content: center; 
    }
}

.languages-dropdown{
    .dropdown{
        .btn.dropdown-toggle{
            color: var(--headerFontColor);
            font-size: 16px;
            padding: 0 14px;
            background-color: transparent;
            border: none;
            font-weight: 400;
        }
        .btn:focus{
            color: var(--headerFontColor);
            padding: 0 14px;
            font-weight: 400;
        }
        .btn:hover{
            background: none;
            border: none;
        }
        .dropdown-menu{
            background-color: var(--mainColor);
            width: fit-content;
            .dropdown-item{
                color: var(--headerFontColor);
                padding: 6px 10px;

                &:hover, &:focus{
                    background-color: var(--mainColor);
                    opacity: .6;
                }
            }
        }
    }
}

.video-item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 20px;

    img{
        height: 100%;
    }

    cursor: pointer;
    .video-span{
        width: 100px;
        height: 100px;
        background-color: rgba($color: #ffffff, $alpha: .3);
        position: absolute;
        z-index: 2;
        color: white;
        font-size: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #fff;
        transition: .3s;
    }

    &:hover{
        .video-span{
            transform: scale(1.1);
        } 
    }
}