@charset "UTF-8";
/*-------------------------------
[Table of contents]

1. Bootstrap Core
2. Theme Styles
	2.1 Typography
	2.2 Form Elements
	2.3 Preloader
	2.4 Page Structure
	2.5 Header
	2.6 Footer
	2.7 Main Slider
	2.8 Services
	2.9 Special Offer
	2.10 Banner
	2.11 FAQ
	2.12 Newsletter
	2.13 Modal
	2.14 Simple Slider
	2.15 Filters
	2.16 Review
	2.17 Gallery
	2.18 Slick Carousel
	2.19 Blog;
	2.20 Calendar Skin
	2.21 Contact Box
	2.22 Quick Links
	2.23 Schedule
	2.24 Doctop Box

-----------------------------------*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: var(--mainColor);
  --primary-alt: var(--mainColor);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1230px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #787878;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #787878;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: var(--mainColor);
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: var(--fontFamily);
  font-weight: 500;
  line-height: 1.2;
  color: #444; }

h1, .h1 {
  font-size: 36px; }

h2, .h2 {
  font-size: 36px; }

h3, .h3 {
  font-size: 26px; }

h4, .h4 {
  font-size: 24px; }

h5, .h5 {
  font-size: 20px; }

h6, .h6 {
  font-size: 19px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 30px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 15px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 1230px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-sm-first {
    order: -1; }

  .order-sm-1 {
    order: 1; }

  .order-sm-2 {
    order: 2; }

  .order-sm-3 {
    order: 3; }

  .order-sm-4 {
    order: 4; }

  .order-sm-5 {
    order: 5; }

  .order-sm-6 {
    order: 6; }

  .order-sm-7 {
    order: 7; }

  .order-sm-8 {
    order: 8; }

  .order-sm-9 {
    order: 9; }

  .order-sm-10 {
    order: 10; }

  .order-sm-11 {
    order: 11; }

  .order-sm-12 {
    order: 12; }

  .offset-sm-0 {
    margin-left: 0; }

  .offset-sm-1 {
    margin-left: 8.3333333333%; }

  .offset-sm-2 {
    margin-left: 16.6666666667%; }

  .offset-sm-3 {
    margin-left: 25%; }

  .offset-sm-4 {
    margin-left: 33.3333333333%; }

  .offset-sm-5 {
    margin-left: 41.6666666667%; }

  .offset-sm-6 {
    margin-left: 50%; }

  .offset-sm-7 {
    margin-left: 58.3333333333%; }

  .offset-sm-8 {
    margin-left: 66.6666666667%; }

  .offset-sm-9 {
    margin-left: 75%; }

  .offset-sm-10 {
    margin-left: 83.3333333333%; }

  .offset-sm-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-md-first {
    order: -1; }

  .order-md-1 {
    order: 1; }

  .order-md-2 {
    order: 2; }

  .order-md-3 {
    order: 3; }

  .order-md-4 {
    order: 4; }

  .order-md-5 {
    order: 5; }

  .order-md-6 {
    order: 6; }

  .order-md-7 {
    order: 7; }

  .order-md-8 {
    order: 8; }

  .order-md-9 {
    order: 9; }

  .order-md-10 {
    order: 10; }

  .order-md-11 {
    order: 11; }

  .order-md-12 {
    order: 12; }

  .offset-md-0 {
    margin-left: 0; }

  .offset-md-1 {
    margin-left: 8.3333333333%; }

  .offset-md-2 {
    margin-left: 16.6666666667%; }

  .offset-md-3 {
    margin-left: 25%; }

  .offset-md-4 {
    margin-left: 33.3333333333%; }

  .offset-md-5 {
    margin-left: 41.6666666667%; }

  .offset-md-6 {
    margin-left: 50%; }

  .offset-md-7 {
    margin-left: 58.3333333333%; }

  .offset-md-8 {
    margin-left: 66.6666666667%; }

  .offset-md-9 {
    margin-left: 75%; }

  .offset-md-10 {
    margin-left: 83.3333333333%; }

  .offset-md-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-lg-first {
    order: -1; }

  .order-lg-1 {
    order: 1; }

  .order-lg-2 {
    order: 2; }

  .order-lg-3 {
    order: 3; }

  .order-lg-4 {
    order: 4; }

  .order-lg-5 {
    order: 5; }

  .order-lg-6 {
    order: 6; }

  .order-lg-7 {
    order: 7; }

  .order-lg-8 {
    order: 8; }

  .order-lg-9 {
    order: 9; }

  .order-lg-10 {
    order: 10; }

  .order-lg-11 {
    order: 11; }

  .order-lg-12 {
    order: 12; }

  .offset-lg-0 {
    margin-left: 0; }

  .offset-lg-1 {
    margin-left: 8.3333333333%; }

  .offset-lg-2 {
    margin-left: 16.6666666667%; }

  .offset-lg-3 {
    margin-left: 25%; }

  .offset-lg-4 {
    margin-left: 33.3333333333%; }

  .offset-lg-5 {
    margin-left: 41.6666666667%; }

  .offset-lg-6 {
    margin-left: 50%; }

  .offset-lg-7 {
    margin-left: 58.3333333333%; }

  .offset-lg-8 {
    margin-left: 66.6666666667%; }

  .offset-lg-9 {
    margin-left: 75%; }

  .offset-lg-10 {
    margin-left: 83.3333333333%; }

  .offset-lg-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1230px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-xl-first {
    order: -1; }

  .order-xl-1 {
    order: 1; }

  .order-xl-2 {
    order: 2; }

  .order-xl-3 {
    order: 3; }

  .order-xl-4 {
    order: 4; }

  .order-xl-5 {
    order: 5; }

  .order-xl-6 {
    order: 6; }

  .order-xl-7 {
    order: 7; }

  .order-xl-8 {
    order: 8; }

  .order-xl-9 {
    order: 9; }

  .order-xl-10 {
    order: 10; }

  .order-xl-11 {
    order: 11; }

  .order-xl-12 {
    order: 12; }

  .offset-xl-0 {
    margin-left: 0; }

  .offset-xl-1 {
    margin-left: 8.3333333333%; }

  .offset-xl-2 {
    margin-left: 16.6666666667%; }

  .offset-xl-3 {
    margin-left: 25%; }

  .offset-xl-4 {
    margin-left: 33.3333333333%; }

  .offset-xl-5 {
    margin-left: 41.6666666667%; }

  .offset-xl-6 {
    margin-left: 50%; }

  .offset-xl-7 {
    margin-left: 58.3333333333%; }

  .offset-xl-8 {
    margin-left: 66.6666666667%; }

  .offset-xl-9 {
    margin-left: 75%; }

  .offset-xl-10 {
    margin-left: 83.3333333333%; }

  .offset-xl-11 {
    margin-left: 91.6666666667%; } }
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0d9ed; }

.table-hover .table-primary:hover {
  background-color: #accde7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #accde7; }

.table-primary-alt,
.table-primary-alt > th,
.table-primary-alt > td {
  background-color: #c0d9ed; }

.table-hover .table-primary-alt:hover {
  background-color: #accde7; }
  .table-hover .table-primary-alt:hover > td,
  .table-hover .table-primary-alt:hover > th {
    background-color: #accde7; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e9ecef; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm.table-bordered {
      border: 0; } }
@media (max-width: 767px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md.table-bordered {
      border: 0; } }
@media (max-width: 1023px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg.table-bordered {
      border: 0; } }
@media (max-width: 1229px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl.table-bordered {
      border: 0; } }
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive.table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 19px;
  color: #424242;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e5e5e5;
    outline: none;
    box-shadow: none; }
  .form-control::placeholder {
    color: #787878;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }
select.form-control:focus::-ms-value {
  color: #424242;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(10px + 1px);
  padding-bottom: calc(10px + 1px);
  margin-bottom: 0;
  line-height: 19px; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.col-form-legend {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 16px; }

.form-control-plaintext {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  line-height: 19px;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .form-control-plaintext.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .form-control-plaintext.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #868e96; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }

.valid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus,
  .was-validated .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25); }
.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }
.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
  .was-validated .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }
.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }
.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 2px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: var(--mainColor);
  border-color: var(--mainColor); }
  .btn-primary:hover {
    color: #fff;
    background-color: #19619c;
    border-color: #175b91; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: var(--mainColor);
    border-color: var(--mainColor); }
  .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #175b91;
    border-color: #155486;
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }

.btn-primary-alt {
  color: #fff;
  background-color: var(--mainColor);
  border-color: var(--mainColor); }
  .btn-primary-alt:hover {
    color: #fff;
    background-color: #19619c;
    border-color: #175b91; }
  .btn-primary-alt:focus, .btn-primary-alt.focus {
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }
  .btn-primary-alt.disabled, .btn-primary-alt:disabled {
    background-color: var(--mainColor);
    border-color: var(--mainColor); }
  .btn-primary-alt:not([disabled]):not(.disabled):active, .btn-primary-alt:not([disabled]):not(.disabled).active, .show > .btn-primary-alt.dropdown-toggle {
    color: #fff;
    background-color: #175b91;
    border-color: #155486;
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }

.btn-outline-primary {
  color: var(--mainColor);
  background-color: transparent;
  background-image: none;
  border-color: var(--mainColor); }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: var(--mainColor);
    border-color: var(--mainColor); }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: var(--mainColor);
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--mainColor);
    border-color: var(--mainColor);
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }

.btn-outline-primary-alt {
  color: var(--mainColor);
  background-color: transparent;
  background-image: none;
  border-color: var(--mainColor); }
  .btn-outline-primary-alt:hover {
    color: #fff;
    background-color: var(--mainColor);
    border-color: var(--mainColor); }
  .btn-outline-primary-alt:focus, .btn-outline-primary-alt.focus {
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }
  .btn-outline-primary-alt.disabled, .btn-outline-primary-alt:disabled {
    color: var(--mainColor);
    background-color: transparent; }
  .btn-outline-primary-alt:not([disabled]):not(.disabled):active, .btn-outline-primary-alt:not([disabled]):not(.disabled).active, .show > .btn-outline-primary-alt.dropdown-toggle {
    color: #fff;
    background-color: var(--mainColor);
    border-color: var(--mainColor);
    box-shadow: 0 0 0 0 rgba(30, 118, 189, 0.5); }

.btn-link {
  font-weight: 400;
  color: #787878;
  background-color: transparent; 
  text-decoration: none;
  cursor: pointer;
}
  .btn-link:hover {
    color: var(--mainColor);
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }
.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 16px;
  color: #787878;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-divider {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: inherit;
    text-decoration: none;
    background-color: transparent; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px; }
  .btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .btn-group-vertical > .btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0; }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap; }

.input-group-addon {
  padding: 10px 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #495057;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
      z-index: 3; }
  .input-group-btn:first-child > .btn + .btn {
    margin-left: 0; }
  .input-group-btn:not(:last-child) > .btn,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -1px; }
  .input-group-btn:not(:first-child) > .btn,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: 0; }
    .input-group-btn:not(:first-child) > .btn:first-child,
    .input-group-btn:not(:first-child) > .btn-group:first-child {
      margin-left: -1px; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.75rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: var(--mainColor); }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    background-color: #9ecbf0; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #e9ecef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #868e96; }

.custom-control-indicator {
  position: absolute;
  top: 0.375rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #424242;
      background-color: #fff; }
  .custom-select[multiple] {
    height: auto;
    background-image: none; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #007bff; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }
.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
@media (max-width: 1023px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
@media (max-width: 1229px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1230px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff; }
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
      color: #fff; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/"; }
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }
.breadcrumb-item.active {
  color: #868e96; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }
.page-item.active .page-link {
  z-index: 2;
  color: #787878;
  background-color: transparent;
  border-color: var(--mainColor); }
.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent; }

.page-link {
  position: relative;
  display: block;
  padding: 5px 10px;
  margin-left: -1px;
  line-height: 39px;
  color: #d5d5d5;
  background-color: transparent;
  border: 1px solid #d5d5d5; }
  .page-link:focus, .page-link:hover {
    color: var(--mainColor);
    text-decoration: none;
    background-color: transparent;
    border-color: var(--mainColor); }

.pagination-lg .page-link {
  padding: 5px 10px;
  font-size: 1.25rem;
  line-height: 1.5; }
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.5; }
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: var(--mainColor); }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #175b91; }

.badge-primary-alt {
  color: #fff;
  background-color: var(--mainColor); }
  .badge-primary-alt[href]:focus, .badge-primary-alt[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #175b91; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #103d62;
  background-color: #d2e4f2;
  border-color: #c0d9ed; }
  .alert-primary hr {
    border-top-color: #accde7; }
  .alert-primary .alert-link {
    color: #092236; }

.alert-primary-alt {
  color: #103d62;
  background-color: #d2e4f2;
  border-color: #c0d9ed; }
  .alert-primary-alt hr {
    border-top-color: #accde7; }
  .alert-primary-alt .alert-link {
    color: #092236; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.nav-link-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .nav-link-action:focus, .nav-link-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .nav-link-action:active {
    color: #212529;
    background-color: #e9ecef; }

.nav-link {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .nav-link:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .nav-link:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled, .nav-link:disabled {
    color: #868e96;
    background-color: #fff; }
  .nav-link.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .nav-link {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
.list-group-flush:first-child .nav-link:first-child {
  border-top: 0; }
.list-group-flush:last-child .nav-link:last-child {
  border-bottom: 0; }

.nav-link-primary {
  color: #103d62;
  background-color: #c0d9ed; }

a.nav-link-primary,
button.nav-link-primary {
  color: #103d62; }
  a.nav-link-primary:focus, a.nav-link-primary:hover,
  button.nav-link-primary:focus,
  button.nav-link-primary:hover {
    color: #103d62;
    background-color: #accde7; }
  a.nav-link-primary.active,
  button.nav-link-primary.active {
    color: #fff;
    background-color: #103d62;
    border-color: #103d62; }

.nav-link-primary-alt {
  color: #103d62;
  background-color: #c0d9ed; }

a.nav-link-primary-alt,
button.nav-link-primary-alt {
  color: #103d62; }
  a.nav-link-primary-alt:focus, a.nav-link-primary-alt:hover,
  button.nav-link-primary-alt:focus,
  button.nav-link-primary-alt:hover {
    color: #103d62;
    background-color: #accde7; }
  a.nav-link-primary-alt.active,
  button.nav-link-primary-alt.active {
    color: #fff;
    background-color: #103d62;
    border-color: #103d62; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border:none;

}
  .modal-header .close {
    padding: 15px;
    margin: -15px -15px -15px auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }

  .modal-sm {
    max-width: 300px; } }
@media (min-width: 1024px) {
  .modal-lg {
    max-width: 800px; } }
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -3px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -3px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 315px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: var(--mainColor);
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 8px;
    height: 8px; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 8px; }
  .popover .arrow::after {
    content: "";
    border-width: 8px; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 8px; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -8px;
      margin-left: -8px;
      border-top-color: var(--mainColor); }
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: calc((8px - 0) * -1);
      margin-left: -8px;
      border-top-color: var(--mainColor); }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 8px; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -8px;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -8px;
      border-right-color: var(--mainColor); }
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: calc((8px - 0) * -1);
      border-right-color: var(--mainColor); }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 8px; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -8px;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -8px;
      border-bottom-color: var(--mainColor); }
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: calc((8px - 0) * -1);
      border-bottom-color: var(--mainColor); }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 0 solid #f7f7f7; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 8px; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -8px;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -8px;
      border-left-color: var(--mainColor); }
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: calc((8px - 0) * -1);
      border-left-color: var(--mainColor); }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 0 solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 0);
  border-top-right-radius: calc(0.3rem - 0); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #fff; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1e76bd !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #175b91 !important; }

.bg-primary-alt {
  background-color: #1e76bd !important; }

a.bg-primary-alt:focus, a.bg-primary-alt:hover {
  background-color: #175b91 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1e76bd !important; }

.border-primary-alt {
  border-color: #1e76bd !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }

  .d-sm-inline {
    display: inline !important; }

  .d-sm-inline-block {
    display: inline-block !important; }

  .d-sm-block {
    display: block !important; }

  .d-sm-table {
    display: table !important; }

  .d-sm-table-row {
    display: table-row !important; }

  .d-sm-table-cell {
    display: table-cell !important; }

  .d-sm-flex {
    display: flex !important; }

  .d-sm-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }

  .d-md-inline {
    display: inline !important; }

  .d-md-inline-block {
    display: inline-block !important; }

  .d-md-block {
    display: block !important; }

  .d-md-table {
    display: table !important; }

  .d-md-table-row {
    display: table-row !important; }

  .d-md-table-cell {
    display: table-cell !important; }

  .d-md-flex {
    display: flex !important; }

  .d-md-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }

  .d-lg-inline {
    display: inline !important; }

  .d-lg-inline-block {
    display: inline-block !important; }

  .d-lg-block {
    display: block !important; }

  .d-lg-table {
    display: table !important; }

  .d-lg-table-row {
    display: table-row !important; }

  .d-lg-table-cell {
    display: table-cell !important; }

  .d-lg-flex {
    display: flex !important; }

  .d-lg-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1230px) {
  .d-xl-none {
    display: none !important; }

  .d-xl-inline {
    display: inline !important; }

  .d-xl-inline-block {
    display: inline-block !important; }

  .d-xl-block {
    display: block !important; }

  .d-xl-table {
    display: table !important; }

  .d-xl-table-row {
    display: table-row !important; }

  .d-xl-table-cell {
    display: table-cell !important; }

  .d-xl-flex {
    display: flex !important; }

  .d-xl-inline-flex {
    display: inline-flex !important; } }
.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; 
  height: 100%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }


.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }

  .flex-sm-column {
    flex-direction: column !important; }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-sm-wrap {
    flex-wrap: wrap !important; }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .justify-content-sm-start {
    justify-content: flex-start !important; }

  .justify-content-sm-end {
    justify-content: flex-end !important; }

  .justify-content-sm-center {
    justify-content: center !important; }

  .justify-content-sm-between {
    justify-content: space-between !important; }

  .justify-content-sm-around {
    justify-content: space-around !important; }

  .align-items-sm-start {
    align-items: flex-start !important; }

  .align-items-sm-end {
    align-items: flex-end !important; }

  .align-items-sm-center {
    align-items: center !important; }

  .align-items-sm-baseline {
    align-items: baseline !important; }

  .align-items-sm-stretch {
    align-items: stretch !important; }

  .align-content-sm-start {
    align-content: flex-start !important; }

  .align-content-sm-end {
    align-content: flex-end !important; }

  .align-content-sm-center {
    align-content: center !important; }

  .align-content-sm-between {
    align-content: space-between !important; }

  .align-content-sm-around {
    align-content: space-around !important; }

  .align-content-sm-stretch {
    align-content: stretch !important; }

  .align-self-sm-auto {
    align-self: auto !important; }

  .align-self-sm-start {
    align-self: flex-start !important; }

  .align-self-sm-end {
    align-self: flex-end !important; }

  .align-self-sm-center {
    align-self: center !important; }

  .align-self-sm-baseline {
    align-self: baseline !important; }

  .align-self-sm-stretch {
    align-self: stretch !important; } }
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }

  .flex-md-column {
    flex-direction: column !important; }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-md-wrap {
    flex-wrap: wrap !important; }

  .flex-md-nowrap {
    flex-wrap: nowrap !important; }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .justify-content-md-start {
    justify-content: flex-start !important; }

  .justify-content-md-end {
    justify-content: flex-end !important; }

  .justify-content-md-center {
    justify-content: center !important; }

  .justify-content-md-between {
    justify-content: space-between !important; }

  .justify-content-md-around {
    justify-content: space-around !important; }

  .align-items-md-start {
    align-items: flex-start !important; }

  .align-items-md-end {
    align-items: flex-end !important; }

  .align-items-md-center {
    align-items: center !important; }

  .align-items-md-baseline {
    align-items: baseline !important; }

  .align-items-md-stretch {
    align-items: stretch !important; }

  .align-content-md-start {
    align-content: flex-start !important; }

  .align-content-md-end {
    align-content: flex-end !important; }

  .align-content-md-center {
    align-content: center !important; }

  .align-content-md-between {
    align-content: space-between !important; }

  .align-content-md-around {
    align-content: space-around !important; }

  .align-content-md-stretch {
    align-content: stretch !important; }

  .align-self-md-auto {
    align-self: auto !important; }

  .align-self-md-start {
    align-self: flex-start !important; }

  .align-self-md-end {
    align-self: flex-end !important; }

  .align-self-md-center {
    align-self: center !important; }

  .align-self-md-baseline {
    align-self: baseline !important; }

  .align-self-md-stretch {
    align-self: stretch !important; } }
@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }

  .flex-lg-column {
    flex-direction: column !important; }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-lg-wrap {
    flex-wrap: wrap !important; }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .justify-content-lg-start {
    justify-content: flex-start !important; }

  .justify-content-lg-end {
    justify-content: flex-end !important; }

  .justify-content-lg-center {
    justify-content: center !important; }

  .justify-content-lg-between {
    justify-content: space-between !important; }

  .justify-content-lg-around {
    justify-content: space-around !important; }

  .align-items-lg-start {
    align-items: flex-start !important; }

  .align-items-lg-end {
    align-items: flex-end !important; }

  .align-items-lg-center {
    align-items: center !important; }

  .align-items-lg-baseline {
    align-items: baseline !important; }

  .align-items-lg-stretch {
    align-items: stretch !important; }

  .align-content-lg-start {
    align-content: flex-start !important; }

  .align-content-lg-end {
    align-content: flex-end !important; }

  .align-content-lg-center {
    align-content: center !important; }

  .align-content-lg-between {
    align-content: space-between !important; }

  .align-content-lg-around {
    align-content: space-around !important; }

  .align-content-lg-stretch {
    align-content: stretch !important; }

  .align-self-lg-auto {
    align-self: auto !important; }

  .align-self-lg-start {
    align-self: flex-start !important; }

  .align-self-lg-end {
    align-self: flex-end !important; }

  .align-self-lg-center {
    align-self: center !important; }

  .align-self-lg-baseline {
    align-self: baseline !important; }

  .align-self-lg-stretch {
    align-self: stretch !important; } }
@media (min-width: 1230px) {
  .flex-xl-row {
    flex-direction: row !important; }

  .flex-xl-column {
    flex-direction: column !important; }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-xl-wrap {
    flex-wrap: wrap !important; }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .justify-content-xl-start {
    justify-content: flex-start !important; }

  .justify-content-xl-end {
    justify-content: flex-end !important; }

  .justify-content-xl-center {
    justify-content: center !important; }

  .justify-content-xl-between {
    justify-content: space-between !important; }

  .justify-content-xl-around {
    justify-content: space-around !important; }

  .align-items-xl-start {
    align-items: flex-start !important; }

  .align-items-xl-end {
    align-items: flex-end !important; }

  .align-items-xl-center {
    align-items: center !important; }

  .align-items-xl-baseline {
    align-items: baseline !important; }

  .align-items-xl-stretch {
    align-items: stretch !important; }

  .align-content-xl-start {
    align-content: flex-start !important; }

  .align-content-xl-end {
    align-content: flex-end !important; }

  .align-content-xl-center {
    align-content: center !important; }

  .align-content-xl-between {
    align-content: space-between !important; }

  .align-content-xl-around {
    align-content: space-around !important; }

  .align-content-xl-stretch {
    align-content: stretch !important; }

  .align-self-xl-auto {
    align-self: auto !important; }

  .align-self-xl-start {
    align-self: flex-start !important; }

  .align-self-xl-end {
    align-self: flex-end !important; }

  .align-self-xl-center {
    align-self: center !important; }

  .align-self-xl-baseline {
    align-self: baseline !important; }

  .align-self-xl-stretch {
    align-self: stretch !important; } }
.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }

  .float-sm-right {
    float: right !important; }

  .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }

  .float-md-right {
    float: right !important; }

  .float-md-none {
    float: none !important; } }
@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }

  .float-lg-right {
    float: right !important; }

  .float-lg-none {
    float: none !important; } }
@media (min-width: 1230px) {
  .float-xl-left {
    float: left !important; }

  .float-xl-right {
    float: right !important; }

  .float-xl-none {
    float: none !important; } }
.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 10px !important; }

.mt-1,
.my-1 {
  margin-top: 10px !important; }

.mr-1,
.mx-1 {
  margin-right: 10px !important; }

.mb-1,
.my-1 {
  margin-bottom: 10px !important; }

.ml-1,
.mx-1 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-2 {
  margin: 20px !important; }

.mt-2,
.my-2 {
  margin-top: 20px !important; }

.mr-2,
.mx-2 {
  margin-right: 20px !important; }

.mb-2,
.my-2 {
  margin-bottom: 20px !important; }

.ml-2,
.mx-2 {
  margin-left: 20px !important; }

.m-3 {
  margin: 30px !important; }

.mt-3,
.my-3 {
  margin-top: 30px !important; }

.mr-3,
.mx-3 {
  margin-right: 30px !important; }

.mb-3,
.my-3 {
  margin-bottom: 30px !important; }

.ml-3,
.mx-3 {
  margin-left: 30px !important; }

.m-4 {
  margin: 40px !important; }

.mt-4,
.my-4 {
  margin-top: 40px !important; }

.mr-4,
.mx-4 {
  margin-right: 40px !important; }

.mb-4,
.my-4 {
  margin-bottom: 40px !important; }

.ml-4,
.mx-4 {
  margin-left: 40px !important; }

.m-5 {
  margin: 50px !important; }

.mt-5,
.my-5 {
  margin-top: 50px !important; }

.mr-5,
.mx-5 {
  margin-right: 50px !important; }

.mb-5,
.my-5 {
  margin-bottom: 50px !important; }

.ml-5,
.mx-5 {
  margin-left: 50px !important; }

.m-6 {
  margin: 60px !important; }

.mt-6,
.my-6 {
  margin-top: 60px !important; }

.mr-6,
.mx-6 {
  margin-right: 60px !important; }

.mb-6,
.my-6 {
  margin-bottom: 60px !important; }

.ml-6,
.mx-6 {
  margin-left: 60px !important; }

.m-7 {
  margin: 70px !important; }

.mt-7,
.my-7 {
  margin-top: 70px !important; }

.mr-7,
.mx-7 {
  margin-right: 70px !important; }

.mb-7,
.my-7 {
  margin-bottom: 70px !important; }

.ml-7,
.mx-7 {
  margin-left: 70px !important; }

.m-8 {
  margin: 80px !important; }

.mt-8,
.my-8 {
  margin-top: 80px !important; }

.mr-8,
.mx-8 {
  margin-right: 80px !important; }

.mb-8,
.my-8 {
  margin-bottom: 80px !important; }

.ml-8,
.mx-8 {
  margin-left: 80px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 10px !important; }

.pt-1,
.py-1 {
  padding-top: 10px !important; }

.pr-1,
.px-1 {
  padding-right: 10px !important; }

.pb-1,
.py-1 {
  padding-bottom: 10px !important; }

.pl-1,
.px-1 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-2 {
  padding: 20px !important; }

.pt-2,
.py-2 {
  padding-top: 20px !important; }

.pr-2,
.px-2 {
  padding-right: 20px !important; }

.pb-2,
.py-2 {
  padding-bottom: 20px !important; }

.pl-2,
.px-2 {
  padding-left: 20px !important; }

.p-3 {
  padding: 30px !important; }

.pt-3,
.py-3 {
  padding-top: 30px !important; }

.pr-3,
.px-3 {
  padding-right: 30px !important; }

.pb-3,
.py-3 {
  padding-bottom: 30px !important; }

.pl-3,
.px-3 {
  padding-left: 30px !important; }

.p-4 {
  padding: 40px !important; }

.pt-4,
.py-4 {
  padding-top: 40px !important; }

.pr-4,
.px-4 {
  padding-right: 40px !important; }

.pb-4,
.py-4 {
  padding-bottom: 40px !important; }

.pl-4,
.px-4 {
  padding-left: 40px !important; }

.p-5 {
  padding: 50px !important; }

.pt-5,
.py-5 {
  padding-top: 50px !important; }

.pr-5,
.px-5 {
  padding-right: 50px !important; }

.pb-5,
.py-5 {
  padding-bottom: 50px !important; }

.pl-5,
.px-5 {
  padding-left: 50px !important; }

.p-6 {
  padding: 60px !important; }

.pt-6,
.py-6 {
  padding-top: 60px !important; }

.pr-6,
.px-6 {
  padding-right: 60px !important; }

.pb-6,
.py-6 {
  padding-bottom: 60px !important; }

.pl-6,
.px-6 {
  padding-left: 60px !important; }

.p-7 {
  padding: 70px !important; }

.pt-7,
.py-7 {
  padding-top: 70px !important; }

.pr-7,
.px-7 {
  padding-right: 70px !important; }

.pb-7,
.py-7 {
  padding-bottom: 70px !important; }

.pl-7,
.px-7 {
  padding-left: 70px !important; }

.p-8 {
  padding: 80px !important; }

.pt-8,
.py-8 {
  padding-top: 80px !important; }

.pr-8,
.px-8 {
  padding-right: 80px !important; }

.pb-8,
.py-8 {
  padding-bottom: 80px !important; }

.pl-8,
.px-8 {
  padding-left: 80px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }

  .m-sm-1 {
    margin: 10px !important; }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 10px !important; }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 10px !important; }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 10px !important; }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 10px !important; }

  .m-sm-15 {
    margin: 15px !important; }

  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }

  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }

  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }

  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }

  .m-sm-2 {
    margin: 20px !important; }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 20px !important; }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 20px !important; }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 20px !important; }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 20px !important; }

  .m-sm-3 {
    margin: 30px !important; }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 30px !important; }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 30px !important; }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 30px !important; }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 30px !important; }

  .m-sm-4 {
    margin: 40px !important; }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 40px !important; }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 40px !important; }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 40px !important; }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 40px !important; }

  .m-sm-5 {
    margin: 50px !important; }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 50px !important; }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 50px !important; }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 50px !important; }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 50px !important; }

  .m-sm-6 {
    margin: 60px !important; }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 60px !important; }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 60px !important; }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 60px !important; }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 60px !important; }

  .m-sm-7 {
    margin: 70px !important; }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 70px !important; }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 70px !important; }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 70px !important; }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 70px !important; }

  .m-sm-8 {
    margin: 80px !important; }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 80px !important; }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 80px !important; }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 80px !important; }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 80px !important; }

  .p-sm-0 {
    padding: 0 !important; }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }

  .p-sm-1 {
    padding: 10px !important; }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 10px !important; }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 10px !important; }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 10px !important; }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 10px !important; }

  .p-sm-15 {
    padding: 15px !important; }

  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }

  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }

  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }

  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }

  .p-sm-2 {
    padding: 20px !important; }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 20px !important; }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 20px !important; }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 20px !important; }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 20px !important; }

  .p-sm-3 {
    padding: 30px !important; }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 30px !important; }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 30px !important; }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 30px !important; }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 30px !important; }

  .p-sm-4 {
    padding: 40px !important; }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 40px !important; }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 40px !important; }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 40px !important; }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 40px !important; }

  .p-sm-5 {
    padding: 50px !important; }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 50px !important; }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 50px !important; }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 50px !important; }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 50px !important; }

  .p-sm-6 {
    padding: 60px !important; }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 60px !important; }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 60px !important; }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 60px !important; }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 60px !important; }

  .p-sm-7 {
    padding: 70px !important; }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 70px !important; }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 70px !important; }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 70px !important; }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 70px !important; }

  .p-sm-8 {
    padding: 80px !important; }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 80px !important; }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 80px !important; }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 80px !important; }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 80px !important; }

  .m-sm-auto {
    margin: auto !important; }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }

  .m-md-1 {
    margin: 10px !important; }

  .mt-md-1,
  .my-md-1 {
    margin-top: 10px !important; }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 10px !important; }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 10px !important; }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 10px !important; }

  .m-md-15 {
    margin: 15px !important; }

  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }

  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important; }

  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }

  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important; }

  .m-md-2 {
    margin: 20px !important; }

  .mt-md-2,
  .my-md-2 {
    margin-top: 20px !important; }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 20px !important; }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 20px !important; }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 20px !important; }

  .m-md-3 {
    margin: 30px !important; }

  .mt-md-3,
  .my-md-3 {
    margin-top: 30px !important; }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 30px !important; }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 30px !important; }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 30px !important; }

  .m-md-4 {
    margin: 40px !important; }

  .mt-md-4,
  .my-md-4 {
    margin-top: 40px !important; }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 40px !important; }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 40px !important; }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 40px !important; }

  .m-md-5 {
    margin: 50px !important; }

  .mt-md-5,
  .my-md-5 {
    margin-top: 50px !important; }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 50px !important; }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 50px !important; }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 50px !important; }

  .m-md-6 {
    margin: 60px !important; }

  .mt-md-6,
  .my-md-6 {
    margin-top: 60px !important; }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 60px !important; }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 60px !important; }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 60px !important; }

  .m-md-7 {
    margin: 70px !important; }

  .mt-md-7,
  .my-md-7 {
    margin-top: 70px !important; }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 70px !important; }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 70px !important; }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 70px !important; }

  .m-md-8 {
    margin: 80px !important; }

  .mt-md-8,
  .my-md-8 {
    margin-top: 80px !important; }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 80px !important; }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 80px !important; }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 80px !important; }

  .p-md-0 {
    padding: 0 !important; }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }

  .p-md-1 {
    padding: 10px !important; }

  .pt-md-1,
  .py-md-1 {
    padding-top: 10px !important; }

  .pr-md-1,
  .px-md-1 {
    padding-right: 10px !important; }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 10px !important; }

  .pl-md-1,
  .px-md-1 {
    padding-left: 10px !important; }

  .p-md-15 {
    padding: 15px !important; }

  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }

  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important; }

  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }

  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important; }

  .p-md-2 {
    padding: 20px !important; }

  .pt-md-2,
  .py-md-2 {
    padding-top: 20px !important; }

  .pr-md-2,
  .px-md-2 {
    padding-right: 20px !important; }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 20px !important; }

  .pl-md-2,
  .px-md-2 {
    padding-left: 20px !important; }

  .p-md-3 {
    padding: 30px !important; }

  .pt-md-3,
  .py-md-3 {
    padding-top: 30px !important; }

  .pr-md-3,
  .px-md-3 {
    padding-right: 30px !important; }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 30px !important; }

  .pl-md-3,
  .px-md-3 {
    padding-left: 30px !important; }

  .p-md-4 {
    padding: 40px !important; }

  .pt-md-4,
  .py-md-4 {
    padding-top: 40px !important; }

  .pr-md-4,
  .px-md-4 {
    padding-right: 40px !important; }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 40px !important; }

  .pl-md-4,
  .px-md-4 {
    padding-left: 40px !important; }

  .p-md-5 {
    padding: 50px !important; }

  .pt-md-5,
  .py-md-5 {
    padding-top: 50px !important; }

  .pr-md-5,
  .px-md-5 {
    padding-right: 50px !important; }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 50px !important; }

  .pl-md-5,
  .px-md-5 {
    padding-left: 50px !important; }

  .p-md-6 {
    padding: 60px !important; }

  .pt-md-6,
  .py-md-6 {
    padding-top: 60px !important; }

  .pr-md-6,
  .px-md-6 {
    padding-right: 60px !important; }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 60px !important; }

  .pl-md-6,
  .px-md-6 {
    padding-left: 60px !important; }

  .p-md-7 {
    padding: 70px !important; }

  .pt-md-7,
  .py-md-7 {
    padding-top: 70px !important; }

  .pr-md-7,
  .px-md-7 {
    padding-right: 70px !important; }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 70px !important; }

  .pl-md-7,
  .px-md-7 {
    padding-left: 70px !important; }

  .p-md-8 {
    padding: 80px !important; }

  .pt-md-8,
  .py-md-8 {
    padding-top: 80px !important; }

  .pr-md-8,
  .px-md-8 {
    padding-right: 80px !important; }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 80px !important; }

  .pl-md-8,
  .px-md-8 {
    padding-left: 80px !important; }

  .m-md-auto {
    margin: auto !important; }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }
@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }

  .m-lg-1 {
    margin: 10px !important; }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 10px !important; }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 10px !important; }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 10px !important; }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 10px !important; }

  .m-lg-15 {
    margin: 15px !important; }

  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }

  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }

  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }

  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }

  .m-lg-2 {
    margin: 20px !important; }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 20px !important; }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 20px !important; }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 20px !important; }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 20px !important; }

  .m-lg-3 {
    margin: 30px !important; }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 30px !important; }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 30px !important; }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 30px !important; }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 30px !important; }

  .m-lg-4 {
    margin: 40px !important; }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 40px !important; }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 40px !important; }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 40px !important; }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 40px !important; }

  .m-lg-5 {
    margin: 50px !important; }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 50px !important; }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 50px !important; }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 50px !important; }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 50px !important; }

  .m-lg-6 {
    margin: 60px !important; }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 60px !important; }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 60px !important; }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 60px !important; }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 60px !important; }

  .m-lg-7 {
    margin: 70px !important; }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 70px !important; }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 70px !important; }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 70px !important; }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 70px !important; }

  .m-lg-8 {
    margin: 80px !important; }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 80px !important; }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 80px !important; }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 80px !important; }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 80px !important; }

  .p-lg-0 {
    padding: 0 !important; }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }

  .p-lg-1 {
    padding: 10px !important; }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 10px !important; }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 10px !important; }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 10px !important; }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 10px !important; }

  .p-lg-15 {
    padding: 15px !important; }

  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }

  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }

  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }

  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }

  .p-lg-2 {
    padding: 20px !important; }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 20px !important; }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 20px !important; }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 20px !important; }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 20px !important; }

  .p-lg-3 {
    padding: 30px !important; }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 30px !important; }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 30px !important; }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 30px !important; }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 30px !important; }

  .p-lg-4 {
    padding: 40px !important; }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 40px !important; }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 40px !important; }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 40px !important; }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 40px !important; }

  .p-lg-5 {
    padding: 50px !important; }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 50px !important; }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 50px !important; }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 50px !important; }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 50px !important; }

  .p-lg-6 {
    padding: 60px !important; }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 60px !important; }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 60px !important; }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 60px !important; }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 60px !important; }

  .p-lg-7 {
    padding: 70px !important; }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 70px !important; }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 70px !important; }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 70px !important; }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 70px !important; }

  .p-lg-8 {
    padding: 80px !important; }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 80px !important; }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 80px !important; }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 80px !important; }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 80px !important; }

  .m-lg-auto {
    margin: auto !important; }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }
@media (min-width: 1230px) {
  .m-xl-0 {
    margin: 0 !important; }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }

  .m-xl-1 {
    margin: 10px !important; }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 10px !important; }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 10px !important; }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 10px !important; }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 10px !important; }

  .m-xl-15 {
    margin: 15px !important; }

  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important; }

  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important; }

  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important; }

  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important; }

  .m-xl-2 {
    margin: 20px !important; }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 20px !important; }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 20px !important; }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 20px !important; }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 20px !important; }

  .m-xl-3 {
    margin: 30px !important; }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 30px !important; }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 30px !important; }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 30px !important; }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 30px !important; }

  .m-xl-4 {
    margin: 40px !important; }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 40px !important; }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 40px !important; }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 40px !important; }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 40px !important; }

  .m-xl-5 {
    margin: 50px !important; }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 50px !important; }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 50px !important; }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 50px !important; }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 50px !important; }

  .m-xl-6 {
    margin: 60px !important; }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 60px !important; }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 60px !important; }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 60px !important; }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 60px !important; }

  .m-xl-7 {
    margin: 70px !important; }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 70px !important; }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 70px !important; }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 70px !important; }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 70px !important; }

  .m-xl-8 {
    margin: 80px !important; }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 80px !important; }

  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 80px !important; }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 80px !important; }

  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 80px !important; }

  .p-xl-0 {
    padding: 0 !important; }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }

  .p-xl-1 {
    padding: 10px !important; }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 10px !important; }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 10px !important; }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 10px !important; }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 10px !important; }

  .p-xl-15 {
    padding: 15px !important; }

  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important; }

  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important; }

  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important; }

  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important; }

  .p-xl-2 {
    padding: 20px !important; }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 20px !important; }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 20px !important; }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 20px !important; }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 20px !important; }

  .p-xl-3 {
    padding: 30px !important; }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 30px !important; }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 30px !important; }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 30px !important; }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 30px !important; }

  .p-xl-4 {
    padding: 40px !important; }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 40px !important; }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 40px !important; }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 40px !important; }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 40px !important; }

  .p-xl-5 {
    padding: 50px !important; }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 50px !important; }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 50px !important; }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 50px !important; }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 50px !important; }

  .p-xl-6 {
    padding: 60px !important; }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 60px !important; }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 60px !important; }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 60px !important; }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 60px !important; }

  .p-xl-7 {
    padding: 70px !important; }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 70px !important; }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 70px !important; }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 70px !important; }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 70px !important; }

  .p-xl-8 {
    padding: 80px !important; }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 80px !important; }

  .pr-xl-8,
  .px-xl-8 {
    padding-right: 80px !important; }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 80px !important; }

  .pl-xl-8,
  .px-xl-8 {
    padding-left: 80px !important; }

  .m-xl-auto {
    margin: auto !important; }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }
.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }

  .text-sm-right {
    text-align: right !important; }

  .text-sm-center {
    text-align: center !important; } }
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }

  .text-md-right {
    text-align: right !important; }

  .text-md-center {
    text-align: center !important; } }
@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }

  .text-lg-right {
    text-align: right !important; }

  .text-lg-center {
    text-align: center !important; } }
@media (min-width: 1230px) {
  .text-xl-left {
    text-align: left !important; }

  .text-xl-right {
    text-align: right !important; }

  .text-xl-center {
    text-align: center !important; } }
.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #1e76bd !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #175b91 !important; }

.text-primary-alt {
  color: #1e76bd !important; }

a.text-primary-alt:focus, a.text-primary-alt:hover {
  color: #175b91 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

html, body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; }

body {
  color: #787878;
  font-weight: 400;
  font-size: 16px;
  font-family: var(--fontFamily2);
  line-height: 27px;
  overflow-x: hidden; }
  @media (max-width: 1023px) {
    body {
      font-size: 15px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    body {
      font-size: 14px;
      line-height: 20px; } }

*:focus {
  outline: 0 !important; }

b,
strong {
  font-weight: bold; }

button,
.btn {
  cursor: pointer; }

.theme-color {
  color: var(--mainColor); }

.p-lg {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 30px; }
  @media (max-width: 1229px) {
    .p-lg {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 20px; } }
  @media (max-width: 800px) {
    .p-lg {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 21px; } }
  @media (max-width: 767px) {
    .p-lg {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px; } }

p {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 27px; }
  @media (max-width: 1229px) {
    p {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 20px; } }
  @media (max-width: 800px) {
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 21px; } }
  @media (max-width: 767px) {
    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px; } }

.p-sm {
  font-size: 14px;
  line-height: 22px; }

p:last-child {
  margin-bottom: 0; }

h1, h2, h3, h4, h5, h6 {
  color: #444; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #444;
    transition: .2s; }
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    color: var(--mainColor);
    text-decoration: none; }

h1,
.h1 {
  font-family: var(--fontFamily);
  line-height: 1em;
  margin-bottom: 10px;
  font-weight: 600; }
  @media (max-width: 1023px) {
    h1,
    .h1 {
      font-size: 30px;
      line-height: 1.2em; } }
  @media (max-width: 767px) {
    h1,
    .h1 {
      font-size: 25px;
      line-height: 30px; } }
  @media (max-width: 1023px) {
    h1 + .h-decor,
    .h1 + .h-decor {
      position: relative;
      top: -5px; } }
  @media (max-width: 767px) {
    h1 + .h-decor,
    .h1 + .h-decor {
      top: -10px; } }
  @media (max-width: 575px) {
    h1 + .h-decor,
    .h1 + .h-decor {
      position: relative;
      top: -7px; } }

h2 {
  font-family: var(--fontFamily);
  line-height: 1em;
  margin-bottom: 10px;
  font-weight: 600; }
  @media (max-width: 1023px) {
    h2 {
      font-size: 30px;
      line-height: 1.2em; } }
  @media (max-width: 767px) {
    h2 {
      font-size: 26px;
      line-height: 30px; } }
  @media (max-width: 1023px) {
    h2 + .h-decor {
      position: relative;
      top: -5px; } }
  @media (max-width: 767px) {
    h2 + .h-decor {
      top: -10px; } }
  @media (max-width: 575px) {
    h2 + .h-decor {
      position: relative;
      top: -10px; } }

h3 {
  font-family: var(--fontFamily);
  line-height: 1em;
  margin-bottom: 20px;
  font-weight: 600; }
  @media (max-width: 1023px) {
    h3 {
      font-size: 20px; } }
  @media (max-width: 767px) {
    h3 {
      font-size: 18px; } }
  h3 + .h-decor {
    position: relative;
    top: -20px;
    height: 2px;
    width: 27px; }

h4 {
  font-family: var(--fontFamily);
  line-height: 1.55em;
  margin-bottom: 18px;
  font-weight: 600; }
  @media (max-width: 1023px) {
    h4 {
      font-size: 20px; } }
  @media (max-width: 767px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: var(--fontFamily);
  line-height: 1em;
  margin-bottom: 20px;
  font-weight: 600; }
  @media (max-width: 1023px) {
    h5 {
      font-size: 17px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    h5 {
      font-size: 15px;
      line-height: 22px; } }
  h5 + .h-decor {
    position: relative;
    top: -20px;
    height: 1px;
    width: 25px; }

h2 + h5 {
  margin-top: 5px; }

h6 {
  font-family: var(--fontFamily);
  line-height: 1.2em;
  margin-bottom: 22px; }
  @media (max-width: 1229px) {
    h6 {
      font-size: 17px; } }
  @media (max-width: 1023px) {
    h6 {
      font-size: 16px; } }

.h-sub {
  position: relative;
  z-index: 1;
  margin-bottom: 17px;
  font-size: 17px;
  line-height: 1.2em;
  font-weight: 500;
  font-family: var(--fontFamily);
  letter-spacing: 1px; }
  @media (max-width: 1023px) {
    .h-sub {
      font-size: 15px;
      margin-bottom: 10px; } }
  @media (max-width: 767px) {
    .h-sub {
      font-size: 13px;
      margin-bottom: 10px;
      letter-spacing: .5px; } }

.h-sub:not(.theme-color) {
  color: #494949; }

.h-decor {
  position: relative;
  display: inline-block;
  height: 3px;
  width: 53px;
  background-color: var(--mainColor); }
  .h-decor + h5 {
    line-height: 34px;
    margin-bottom: 0; }
  @media (max-width: 1023px) {
    .h-decor {
      height: 2px;
      width: 34px; } }
  @media (max-width: 575px) {
    .h-decor {
      height: 2px;
      width: 34px; } }

.more-link {
  font-size: 24px;
  line-height: 1em;
  color: var(--mainColor);
  text-transform: uppercase; }
  .more-link:hover {
    text-decoration: none;
    color: #787878; }

.title-wrap {
  position: relative; }
  .title-wrap + * {
    margin-top: 34px; }
    @media (max-width: 1023px) {
      .title-wrap + * {
        margin-top: 24px; } }
    @media (max-width: 767px) {
      .title-wrap + * {
        margin-top: 20px; } }
  @media (max-width: 1023px) {
    .title-wrap.d-none.d-lg-block + * {
      margin-top: 0; } }

.title-wrap-alt + * {
  margin-top: 24px; }
  @media (max-width: 1023px) {
    .title-wrap-alt + * {
      margin-top: 20px; } }
  @media (max-width: 767px) {
    .title-wrap-alt + * {
      margin-top: 12px; } }

.page-title {
  background-color: #f5f6f7; }
  .page-title .container > * + * {
    margin-top: 5px; }

.breadcrumbs-wrap {
  background-color: #f8f8f8;
  padding: 20px 0; }

.breadcrumbs {
  font-size: 13px;
  line-height: 18px; }
  .breadcrumbs, .breadcrumbs a {
    color: #9b9b9b; }
  .breadcrumbs > * {
    position: relative; }
  .breadcrumbs > a:after {
    content: '/';
    display: inline-block;
    padding: 0 4px 0 5px; }

.txt-gradient {
  background: -webkit-linear-gradient(left, #278cdd, #88bfec);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.star-rating {
  color: var(--mainColor);
  line-height: 1em; }
  .star-rating [class*='icon-'] {
    font-size: 11px;
    padding-right: 1px; }

.star-rating .txt-gradient {
  background: -webkit-linear-gradient(left, #1e76bd, #1e76bd);
  -webkit-background-clip: text; }

.p-sm {
  font-size: 13px;
  line-height: 18px; }

.btn-link {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
  letter-spacing: .6px;
  color: var(--mainColor); }
  .btn-link [class*='icon'] {
    position: relative;
    display: inline-block;
    padding-left: 10px;
    top: 0;
    font-size: 12px;
    transition: 0.3s; }
  .btn-link:hover {
    text-decoration: none;
    color: #424242; }
  @media (max-width: 767px) {
    .btn-link {
      font-size: 14px;
      line-height: 16px; } }

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: 0; }

.btn, body:not(.touch) .btn:focus {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-shadow: none;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
  padding: 15px 25px;
  color: var(--mainColor);
  border: 2px solid var(--mainColor);
  border-radius: 26px;
  background: transparent;
  text-transform: none;
  letter-spacing: .05em;
  transition: 0.3s; }
  @media (max-width: 1229px) {
    .btn, body:not(.touch) .btn:focus {
      font-size: 14px;
      line-height: 18px;
      padding: 15px 25px;
      border-radius: 25px; } }
  @media (max-width: 1023px) {
    .btn, body:not(.touch) .btn:focus {
      font-size: 13px;
      line-height: 18px;
      padding: 10px 23px;
      border-radius: 21px;
      letter-spacing: 0; } }
  @media (max-width: 767px) {
    .btn, body:not(.touch) .btn:focus {
      font-size: 12px;
      line-height: 16px;
      padding: 9px 22px;
      border-radius: 18px;
      border-width: 1px;
      box-shadow: inset 0 0 0 1px var(--mainColor); } }
  .btn [class*='icon'], body:not(.touch) .btn:focus [class*='icon'] {
    position: relative;
    display: inline-block;
    top: 0;
    font-size: 14px;
    transition: 0.3s; }
    @media (max-width: 767px) {
      .btn [class*='icon'], body:not(.touch) .btn:focus [class*='icon'] {
        top: 1px; } }
    @media (max-width: 575px) {
      .btn [class*='icon'], body:not(.touch) .btn:focus [class*='icon'] {
        top: 1px; } }
  .btn .icon-right-arrow, body:not(.touch) .btn:focus .icon-right-arrow {
    font-size: 12px; }
  .btn.btn-noanimate [class*='icon']:first-child, body:not(.touch) .btn:focus.btn-noanimate [class*='icon']:first-child {
    margin-right: 15px; }
  .btn:not(.btn-noanimate) [class*='icon']:first-child, body:not(.touch) .btn:focus:not(.btn-noanimate) [class*='icon']:first-child {
    transform: translateX(-55px);
    opacity: 0;
    width: 0; }
  .btn:not(.btn-noanimate) [class*='icon']:last-child, body:not(.touch) .btn:focus:not(.btn-noanimate) [class*='icon']:last-child {
    padding-left: 10px; }
    @media (max-width: 1229px) {
      .btn:not(.btn-noanimate) [class*='icon']:last-child, body:not(.touch) .btn:focus:not(.btn-noanimate) [class*='icon']:last-child {
        padding-left: .6em; } }
  .btn.icn-left:not(.btn-noanimate) [class*='icon']:first-child, body:not(.touch) .btn:focus.icn-left:not(.btn-noanimate) [class*='icon']:first-child {
    width: auto;
    padding-right: 10px;
    transform: translateX(0);
    opacity: 1; }
    @media (max-width: 1229px) {
      .btn.icn-left:not(.btn-noanimate) [class*='icon']:first-child, body:not(.touch) .btn:focus.icn-left:not(.btn-noanimate) [class*='icon']:first-child {
        padding-right: .6em; } }
  .btn.icn-left:not(.btn-noanimate) [class*='icon']:last-child, body:not(.touch) .btn:focus.icn-left:not(.btn-noanimate) [class*='icon']:last-child {
    width: 0;
    padding-left: 0;
    transform: translateX(55px);
    opacity: 0; }

body:not(.touch) .btn:not(.btn-noanimate):hover [class*='icon']:first-child {
  width: auto;
  padding-right: 10px;
  transform: translateX(0);
  opacity: 1; }
  @media (max-width: 1229px) {
    body:not(.touch) .btn:not(.btn-noanimate):hover [class*='icon']:first-child {
      padding-right: .6em; } }
body:not(.touch) .btn:not(.btn-noanimate):hover [class*='icon']:last-child {
  width: 0;
  padding-left: 0;
  transform: translateX(55px);
  opacity: 0; }
body:not(.touch) .btn:not(.btn-noanimate):hover.icn-left [class*='icon']:first-child {
  width: 0;
  padding-right: 0;
  transform: translateX(-55px);
  opacity: 0; }
body:not(.touch) .btn:not(.btn-noanimate):hover.icn-left [class*='icon']:last-child {
  width: auto;
  padding-left: 10px;
  transform: translateX(0);
  opacity: 1; }
  @media (max-width: 1229px) {
    body:not(.touch) .btn:not(.btn-noanimate):hover.icn-left [class*='icon']:last-child {
      padding-left: .6em; } }

.btn-xs, body:not(.touch) .btn-xs:focus {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 16px;
  font-weight: 400;
  text-transform: none;
  border-width: 1px;
  box-shadow: inset 0 0 0 1px var(--mainColor); }
  .btn-xs [class*='icon'], body:not(.touch) .btn-xs:focus [class*='icon'] {
    font-size: 14px;
    line-height: 18px; }
  @media (max-width: 767px) {
    .btn-xs, body:not(.touch) .btn-xs:focus {
      font-size: 12px;
      line-height: 16px;
      padding: 8px 22px;
      border-radius: 18px;
      border-width: 1px;
      box-shadow: inset 0 0 0 1px var(--mainColor); } }

.btn-sm, .btn-group-sm > .btn, body:not(.touch) .btn-sm:focus, body:not(.touch) .btn-group-sm > .btn:focus {
  padding: 11px 21px;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  border-radius: 20px;
  text-transform: none;
  border-width: 1px;
  box-shadow: inset 0 0 0 1px var(--mainColor); }
  @media (max-width: 767px) {
    .btn-sm, .btn-group-sm > .btn, body:not(.touch) .btn-sm:focus, body:not(.touch) .btn-group-sm > .btn:focus {
      font-size: 12px;
      line-height: 16px;
      padding: 8px 22px;
      border-radius: 18px;
      border-width: 1px;
      box-shadow: inset 0 0 0 1px var(--mainColor); } }
  .btn-sm [class*='icon'], .btn-group-sm > .btn [class*='icon'], body:not(.touch) .btn-sm:focus [class*='icon'], body:not(.touch) .btn-group-sm > .btn:focus [class*='icon'] {
    line-height: 19px; }

.btn-lg, .btn-group-lg > .btn, body:not(.touch) .btn-lg:focus, body:not(.touch) .btn-group-lg > .btn:focus {
  padding: 15px 35px;
  font-size: 24px;
  line-height: 34px;
  border-radius: 32px;
  border-width: 2px; }
  .btn-lg [class*='icon'], .btn-group-lg > .btn [class*='icon'], body:not(.touch) .btn-lg:focus [class*='icon'], body:not(.touch) .btn-group-lg > .btn:focus [class*='icon'] {
    font-size: 16px;
    line-height: 34px;
    top: -1px; }
  @media (max-width: 767px) {
    .btn-lg, .btn-group-lg > .btn, body:not(.touch) .btn-lg:focus, body:not(.touch) .btn-group-lg > .btn:focus {
      padding: 9px 25px;
      font-size: 18px;
      line-height: 30px;
      border-radius: 25px;
      border-width: 1px; }
      .btn-lg [class*='icon'], .btn-group-lg > .btn [class*='icon'], body:not(.touch) .btn-lg:focus [class*='icon'], body:not(.touch) .btn-group-lg > .btn:focus [class*='icon'] {
        font-size: 16px;
        line-height: 24px;
        top: 1px; } }

body:not(.touch) .btn-hover-fill:hover {
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  box-shadow: none !important; }
  body:not(.touch) .btn-hover-fill:hover [class*='icon'] {
    color: #fff; }

.btn-gradient, body:not(.touch) .btn-gradient:focus {
  border: 0;
  background-image: linear-gradient(to right, #1e76bd 0%, #469ce2 100%);
  background-repeat: repeat-x;
  color: #fff;
  box-shadow: none; }
  .btn-gradient [class*='icon'], body:not(.touch) .btn-gradient:focus [class*='icon'] {
    color: #fff;
    box-shadow: none; }

body:not(.touch) .btn-gradient:hover {
  color: #fff; }
  body:not(.touch) .btn-gradient:hover [class*='icon'] {
    color: #fff; }

.btn-fill, body:not(.touch) .btn-fill:focus {
  border-color: var(--mainColor);
  background: var(--mainColor);
  color: #fff;
  box-shadow: none; }
  .btn-fill:hover, body:not(.touch) .btn-fill:focus:hover {
    background: var(--mainColor);
    border-color: var(--mainColor);
    color: #fff;
    box-shadow: none; }
    .btn-fill:hover [class*='icon'], body:not(.touch) .btn-fill:focus:hover [class*='icon'] {
      color: #fff; }

.btn-white, .btn-white:focus, body:not(.touch) .btn-white:focus {
  border-color: #fff;
  color: #fff; }
  @media (max-width: 767px) {
    .btn-white, .btn-white:focus, body:not(.touch) .btn-white:focus {
      box-shadow: inset 0 0 0 1px #fff; } }

body:not(.touch) .btn-white:hover, .btn-white:hover {
  color: #fff; }
  @media (max-width: 767px) {
    body:not(.touch) .btn-white:hover, .btn-white:hover {
      box-shadow: inset 0 0 0 1px #fff; } }
  body:not(.touch) .btn-white:hover [class*='icon'], .btn-white:hover [class*='icon'] {
    color: #fff; }

.dropdown {
  max-width: 370px; }

.btn.dropdown-toggle {
  padding: 15px 30px 15px 22px;
  min-height: 60px;
  border-radius: 0;
  text-align: left;
  font-weight: 300;
  font-size: 28px;
  line-height: 38px;
  cursor: pointer; }
  @media (max-width: 1023px) {
    .btn.dropdown-toggle {
      padding: 10px 30px 10px 20px;
      font-size: 24px;
      line-height: 30px;
      min-height: 50px; } }
  .btn.dropdown-toggle [class*='icon'] {
    padding-right: 16px;
    top: 4px; }
    @media (max-width: 1023px) {
      .btn.dropdown-toggle [class*='icon'] {
        font-size: 22px; } }
  .btn.dropdown-toggle.btn-light, .btn.dropdown-toggle.btn-light:focus {
    background: #fff;
    color: #787878;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #f3f3f3; }
    .btn.dropdown-toggle.btn-light [class*='icon'], .btn.dropdown-toggle.btn-light:focus [class*='icon'] {
      color: var(--mainColor); }

.btn + .btn {
  margin-left: 12px; }

.btn-sm + .btn-sm, .btn-group-sm > .btn + .btn-sm, .btn-group-sm > .btn-sm + .btn, .btn-group-sm > .btn + .btn {
  margin-left: 8px; }

@media (max-width: 575px) {
  .flex-column.flex-sm-row .btn {
    margin: 0 auto 0 0; }
  .flex-column.flex-sm-row .btn + .btn {
    margin: 10px auto 0 0; } }
.dropdown + .dropdown {
  margin-top: 16px; }

.nav-item {
    .btn.dropdown-toggle:after {
  position: absolute;
  top: 50%;
  right: 46px;
  margin-top: -16px;
  border: 0;
  content: '\e935';
  font-size: 19px;
  font-family: 'hotelfont'; }

.dropdown.show .btn.dropdown-toggle:after {
  content: '\e936'; }

.dropdown-info,
.dropdown-form {
  width: 100%;
  margin: -1px 0 0 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  border-width: 0 1px 1px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05); }
  .dropdown-info table td,
  .dropdown-form table td {
    font-size: 15px;
    line-height: 25px;
    padding: 10px; }
  .dropdown-info table span,
  .dropdown-form table span {
    font-weight: bold;
    color: var(--mainColor); }

.dropdown-info > .inside {
  padding: 0 20px 20px;
  font-size: 14px;
  line-height: 20px; }
  .dropdown-info > .inside p {
    margin-bottom: 20px; }
  .dropdown-info > .inside p:last-child {
    margin-bottom: 0; }

.dropdown-form > .inside {
  padding: 20px 20px 20px;
  font-size: 14px;
  line-height: 20px;
  position: relative; }

.dropdown-info ul.list-unstyled {
  font-size: 16px;
  line-height: 23px; }
  .dropdown-info ul.list-unstyled li {
    margin-bottom: 0 !important; }
  .dropdown-info ul.list-unstyled a {
    color: var(--mainColor); }

.dropdown-info[x-placement="top-start"],
.dropdown-form[x-placement="top-start"] {
  margin: 0 0 -1px 0;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.05);
  border-width: 1px 1px 0; }
  .dropdown-info[x-placement="top-start"] > .inside,
  .dropdown-form[x-placement="top-start"] > .inside {
    padding: 20px 20px 20px; }

.dropdown-form {
  font-size: 14px;
  line-height: 20px; }

.dropdown-menu .close {
  position: absolute;
  z-index: 1;
  font-size: 14px;
  right: 15px;
  top: 15px;
  color: #212121;
  cursor: pointer;
  opacity: 1;
  transition: 0.2s; }
  .dropdown-menu .close:hover {
    text-decoration: none;
    color: var(--mainColor); }
}
.flex-wrap-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center; }

ul.list-unstyled li:not(:last-child) {
  margin-bottom: 8px; }

ul.numbered-list {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  counter-reset: li;
  list-style-type: none;
  font-size: 16px;
  line-height: 24px; }
  @media (max-width: 1023px) {
    ul.numbered-list {
      font-size: 15px;
      line-height: 20px; } }
  ul.numbered-list li {
    position: relative;
    padding: 0 0 0 33px; }
    ul.numbered-list li:before {
      font-size: 15px;
      line-height: 21px;
      font-weight: 600;
      content: counter(li);
      counter-increment: li;
      height: 21px;
      width: 21px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      position: absolute;
      left: 0;
      top: 2px;
      background-color: var(--mainColor); }
  ul.numbered-list li:not(:last-child) {
    margin-bottom: 10px; }
    @media (max-width: 1023px) {
      ul.numbered-list li:not(:last-child) {
        margin-bottom: 7px; } }

ul.numbered-list-lg {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  counter-reset: li;
  list-style-type: none;
  font-size: 16px;
  line-height: 27px; }
  @media (max-width: 1023px) {
    ul.numbered-list-lg {
      font-size: 15px;
      line-height: 20px; } }
  ul.numbered-list-lg li {
    position: relative;
    padding: 0 35px 0 70px; }
    @media (max-width: 1023px) {
      ul.numbered-list-lg li {
        padding: 0 0 0 50px; } }
    ul.numbered-list-lg li:before {
      position: absolute;
      font-size: 16px;
      line-height: 35px;
      font-weight: 700;
      content: counter(li);
      counter-increment: li;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      left: 15px;
      top: -6px;
      background-color: var(--mainColor); }
      @media (max-width: 1023px) {
        ul.numbered-list-lg li:before {
          font-size: 15px;
          line-height: 35px;
          left: 0; } }
  ul.numbered-list-lg li:not(:last-child) {
    margin-bottom: 24px; }
    @media (max-width: 1023px) {
      ul.numbered-list-lg li:not(:last-child) {
        margin-bottom: 20px; } }

ul.numbered-list-xl {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  counter-reset: li;
  list-style-type: none;
  font-size: 16px;
  line-height: 27px; }
  @media (max-width: 1023px) {
    ul.numbered-list-xl {
      font-size: 15px;
      line-height: 20px; } }
  ul.numbered-list-xl h5 {
    margin-bottom: 15px;
    top: 10px; }
    @media (max-width: 1023px) {
      ul.numbered-list-xl h5 {
        margin-bottom: 7px;
        padding-top: 0; } }
  ul.numbered-list-xl li {
    position: relative;
    padding: 0 0 0 110px; }
    @media (max-width: 1023px) {
      ul.numbered-list-xl li {
        padding: 0 0 0 67px; } }
    ul.numbered-list-xl li:before {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      line-height: 1em;
      font-weight: 700;
      font-family: var(--fontFamily);
      content: attr(data-num);
      counter-increment: li;
      height: 83px;
      width: 83px;
      padding-left: 2px;
      border-radius: 50%;
      color: var(--mainColor);
      left: 0;
      top: -6px;
      background-color: #fff;
      box-shadow: 0 10px 20px rgba(101, 81, 46, 0.1);
      transition: 0.2s; }
      @media (max-width: 1023px) {
        ul.numbered-list-xl li:before {
          font-size: 18px;
          height: 56px;
          width: 56px; } }
    ul.numbered-list-xl li:hover:before {
      color: #fff;
      background-color: var(--mainColor); }
  ul.numbered-list-xl li:not(:last-child) {
    margin-bottom: 40px; }
    @media (max-width: 1023px) {
      ul.numbered-list-xl li:not(:last-child) {
        margin-bottom: 20px; } }

ul.circle-list {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  @media (max-width: 1023px) {
    ul.circle-list {
      font-size: 15px;
      line-height: 22px; } }
  ul.circle-list li {
    position: relative;
    padding-left: 24px; }
    ul.circle-list li:before {
      content: '\25CF';
      font-size: 16px;
      position: absolute;
      left: 0;
      top: -1px;
      color: var(--mainColor); }
  ul.circle-list li:not(:last-child) {
    margin-bottom: 14px; }
    @media (max-width: 1023px) {
      ul.circle-list li:not(:last-child) {
        margin-bottom: 10px; } }

ul.circle-list-dark {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  ul.circle-list-dark, ul.circle-list-dark a {
    color: #000; }
  @media (max-width: 1023px) {
    ul.circle-list-dark {
      font-size: 15px;
      line-height: 22px; } }
  ul.circle-list-dark li {
    position: relative;
    padding-left: 52px; }
    ul.circle-list-dark li:before {
      content: '\25CF';
      font-size: 16px;
      position: absolute;
      left: 26px;
      top: -1px;
      color: var(--mainColor); }
  ul.circle-list-dark li:not(:last-child) {
    margin-bottom: 12px; }
    @media (max-width: 767px) {
      ul.circle-list-dark li:not(:last-child) {
        margin-bottom: 7px; } }

ul.circle-list-bordered {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 20px; }
  @media (max-width: 1023px) {
    ul.circle-list-bordered {
      font-size: 15px;
      line-height: 22px; } }
  ul.circle-list-bordered li {
    position: relative;
    padding-left: 47px; }
    @media (max-width: 575px) {
      ul.circle-list-bordered li {
        padding-left: 27px; } }
    ul.circle-list-bordered li:after {
      content: '';
      position: absolute;
      left: 20px;
      right: 0;
      height: 1px;
      bottom: -10px;
      background-color: #eee; }
      @media (max-width: 575px) {
        ul.circle-list-bordered li:after {
          left: 0; } }
    ul.circle-list-bordered li:before {
      content: '\25CF';
      font-size: 16px;
      position: absolute;
      left: 20px;
      top: -1px;
      color: var(--mainColor); }
      @media (max-width: 575px) {
        ul.circle-list-bordered li:before {
          left: 0; } }
  ul.circle-list-bordered li:last-child:after {
    display: none; }
  ul.circle-list-bordered li:not(:last-child) {
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      ul.circle-list-bordered li:not(:last-child) {
        margin-bottom: 15px; } }

ul.marker-list-md {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  @media (max-width: 1023px) {
    ul.marker-list-md {
      font-size: 15px;
      line-height: 22px; } }
  @media (max-width: 767px) {
    ul.marker-list-md {
      font-size: 14px;
      line-height: 22px; } }
  ul.marker-list-md li {
    position: relative;
    padding-left: 34px; }
    ul.marker-list-md li:before {
      content: '\25CF';
      font-size: 16px;
      position: absolute;
      left: 10px;
      top: -1px;
      color: var(--mainColor); }
  ul.marker-list-md li:not(:last-child) {
    margin-bottom: 14px; }
    @media (max-width: 1023px) {
      ul.marker-list-md li:not(:last-child) {
        margin-bottom: 7px; } }

ul.marker-list-md-line {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  @media (max-width: 1023px) {
    ul.marker-list-md-line {
      font-size: 15px;
      line-height: 22px; } }
  @media (max-width: 767px) {
    ul.marker-list-md-line {
      font-size: 14px;
      line-height: 20px; } }
  ul.marker-list-md-line li {
    position: relative;
    padding-left: 23px; }
    ul.marker-list-md-line li:before {
      content: '-';
      font-size: 31px;
      position: absolute;
      left: 0;
      top: -3px;
      color: var(--mainColor); }
  ul.marker-list-md-line li:not(:last-child) {
    margin-bottom: 16px; }
    @media (max-width: 1023px) {
      ul.marker-list-md-line li:not(:last-child) {
        margin-bottom: 12px; } }

ul.marker-list-md-dark {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  ul.marker-list-md-dark, ul.marker-list-md-dark a {
    color: #000; }
  @media (max-width: 1023px) {
    ul.marker-list-md-dark {
      font-size: 15px;
      line-height: 22px; } }
  ul.marker-list-md-dark li {
    position: relative;
    padding-left: 52px; }
    ul.marker-list-md-dark li:before {
      content: '\25CF';
      font-size: 16px;
      position: absolute;
      left: 26px;
      top: -1px;
      color: var(--mainColor); }
  ul.marker-list-md-dark li:not(:last-child) {
    margin-bottom: 12px; }
    @media (max-width: 767px) {
      ul.marker-list-md-dark li:not(:last-child) {
        margin-bottom: 7px; } }

ul.marker-list-md-bordered {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 20px; }
  @media (max-width: 1023px) {
    ul.marker-list-md-bordered {
      font-size: 15px;
      line-height: 22px; } }
  ul.marker-list-md-bordered li {
    position: relative;
    padding-left: 47px; }
    @media (max-width: 575px) {
      ul.marker-list-md-bordered li {
        padding-left: 27px; } }
    ul.marker-list-md-bordered li:after {
      content: '';
      position: absolute;
      left: 20px;
      right: 0;
      height: 1px;
      bottom: -10px;
      background-color: #eee; }
      @media (max-width: 575px) {
        ul.marker-list-md-bordered li:after {
          left: 0; } }
    ul.marker-list-md-bordered li:before {
      content: '\25CF';
      font-size: 16px;
      position: absolute;
      left: 20px;
      top: -1px;
      color: var(--mainColor); }
      @media (max-width: 575px) {
        ul.marker-list-md-bordered li:before {
          left: 0; } }
  ul.marker-list-md-bordered li:last-child:after {
    display: none; }
  ul.marker-list-md-bordered li:not(:last-child) {
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      ul.marker-list-md-bordered li:not(:last-child) {
        margin-bottom: 15px; } }

ul.categories-list {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  @media (max-width: 1023px) {
    ul.categories-list {
      font-size: 15px;
      line-height: 26px; } }
  ul.categories-list li {
    position: relative;
    padding-left: 24px; }
    ul.categories-list li:before {
      content: '\e906';
      font-size: 10px;
      font-family: 'dentco';
      position: absolute;
      left: 0;
      top: 1px;
      color: var(--mainColor); }
  ul.categories-list li:not(:last-child) {
    margin-bottom: 16px; }
    @media (max-width: 1023px) {
      ul.categories-list li:not(:last-child) {
        margin-bottom: 7px; } }

ul.marker-list {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  list-style: none;
  font-size: 19px;
  line-height: 29px;
  font-weight: 400;
  font-family: var(--fontFamily); }
  @media (max-width: 1023px) {
    ul.marker-list {
      font-size: 17px;
      line-height: 22px; } }
  ul.marker-list li {
    position: relative;
    padding-left: 33px; }
    ul.marker-list li:before {
      content: '\e906';
      font-size: 15px;
      font-family: 'dentco';
      position: absolute;
      left: 0;
      top: 0;
      color: var(--mainColor); }
    ul.marker-list li span, ul.marker-list li b {
      font-size: 14px;
      font-weight: 600;
      color: var(--mainColor); }
  ul.marker-list li:not(:last-child) {
    margin-bottom: 20px; }
    @media (max-width: 1023px) {
      ul.marker-list li:not(:last-child) {
        margin-bottom: 16px; } }

ul.marker-list-md {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 22px; }
  @media (max-width: 1023px) {
    ul.marker-list-md {
      font-size: 14px;
      line-height: 18px; } }
  ul.marker-list-md li {
    position: relative;
    padding-left: 24px; }
    ul.marker-list-md li:before {
      content: '\e906';
      font-size: 10px;
      font-family: 'dentco';
      position: absolute;
      left: 0;
      top: 1px;
      color: var(--mainColor); }
  ul.marker-list-md li:not(:last-child) {
    margin-bottom: 16px; }
    @media (max-width: 1023px) {
      ul.marker-list-md li:not(:last-child) {
        margin-bottom: 9px; } }

ul.marker-list-sm {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 20px; }
  @media (max-width: 1023px) {
    ul.marker-list-sm {
      font-size: 14px;
      line-height: 20px; } }
  ul.marker-list-sm li {
    position: relative;
    padding-left: 20px; }
    ul.marker-list-sm li:before {
      content: '\e906';
      font-size: 12px;
      font-family: 'dentco';
      position: absolute;
      left: 0;
      top: 0;
      color: var(--mainColor); }
  ul.marker-list-sm li:not(:last-child) {
    margin-bottom: 4px; }

@media (min-width: 576px) {
  .two-col-list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }
@media (min-width: 1230px) {
  .two-col-list-xl {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }
@media (min-width: 1024px) and (max-width: 1229px) {
  .two-col-list-lg {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }
@media (min-width: 768px) and (max-width: 1023px) {
  .two-col-list-md {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }
@media (min-width: 576px) and (max-width: 767px) {
  .two-col-list-sm {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }
ul.icn-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 24px; }
  @media (max-width: 1023px) {
    ul.icn-list {
      font-size: 15px;
      line-height: 22px; } }
  @media (max-width: 1023px) {
    ul.icn-list {
      font-size: 14px;
      line-height: 20px; } }
  ul.icn-list li {
    position: relative;
    padding-left: 35px; }
    ul.icn-list li > [class*='icon'] {
      position: absolute;
      left: 0;
      top: 2px;
      font-size: 20px;
      color: var(--mainColor); }
      @media (max-width: 767px) {
        ul.icn-list li > [class*='icon'] {
          font-size: 14px; } }
    ul.icn-list li b {
      font-weight: 600;
      color: var(--mainColor); }
  ul.icn-list li:not(:last-child) {
    margin-bottom: 18px; }

ul.icn-list-sm {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 20px; }
  @media (max-width: 1023px) {
    ul.icn-list-sm {
      font-size: 14px;
      line-height: 20px; } }
  ul.icn-list-sm li {
    position: relative;
    padding-left: 24px; }
    ul.icn-list-sm li > [class*='icon'] {
      position: absolute;
      left: 0;
      top: 3px;
      font-size: 14px;
      color: var(--mainColor); }
    ul.icn-list-sm li > .icon-black-envelope {
      top: 4px; }
    ul.icn-list-sm li b {
      font-weight: 500; }
  ul.icn-list-sm li:not(:last-child) {
    margin-bottom: 10px; }

ul.icn-list-lg {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 27px; }
  @media (max-width: 1023px) {
    ul.icn-list-lg {
      font-size: 15px;
      line-height: 22px; } }
  ul.icn-list-lg li {
    position: relative;
    padding-left: 35px; }
    ul.icn-list-lg li > [class*='icon'] {
      position: absolute;
      left: 0;
      top: 6px;
      font-size: 18px;
      color: var(--mainColor); }
      @media (max-width: 1023px) {
        ul.icn-list-lg li > [class*='icon'] {
          top: 3px; } }
    ul.icn-list-lg li b {
      font-weight: 600; }
    @media (max-width: 1023px) {
      ul.icn-list-lg li .d-flex {
        max-width: 300px; } }
  ul.icn-list-lg li:not(:last-child) {
    margin-bottom: 5px; }
  ul.icn-list-lg .d-flex > span {
    min-width: 50%; }

ul.header-icn-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 17px;
  line-height: 30px; }
  @media (max-width: 1023px) {
    ul.header-icn-list {
      font-size: 15px;
      line-height: 22px; } }
  ul.header-icn-list li {
    position: relative;
    padding-left: 35px; }
    ul.header-icn-list li > [class*='icon'] {
      position: absolute;
      left: 0;
      top: 6px;
      font-size: 18px;
      color: var(--mainColor); }
      @media (max-width: 1023px) {
        ul.header-icn-list li > [class*='icon'] {
          top: 3px; } }
    ul.header-icn-list li b {
      font-weight: 600; }
    @media (max-width: 1023px) {
      ul.header-icn-list li .d-flex {
        max-width: 300px; } }
  ul.header-icn-list li:not(:last-child) {
    margin-bottom: 5px; }

.icn-list + .icn-list {
  margin-top: 30px; }

ul.services-list {
  margin: -5px 0 0 0;
  padding: 0;
  list-style: none;
  max-width: 100%; }
  ul.services-list li {
    position: relative;
    border-bottom: 1px dotted #ccc7c8;
    font-size: 14px;
    line-height: 20px;
    padding: 7px 0 7px 30px;
    text-align: right; }
    @media (max-width: 1023px) {
      ul.services-list li {
        font-size: 13px;
        line-height: 18px;
        padding-left: 20px; } }
    ul.services-list li [class*='icon-'] {
      color: #695c5d;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -9px;
      font-size: 17px; }
      @media (max-width: 1023px) {
        ul.services-list li [class*='icon-'] {
          font-size: 15px; } }
  ul.services-list li:last-child {
    border-bottom: 0; }

.messengers-list a {
  padding: 0 10px;
  color: #c3c3c3;
  font-size: 28px;
  line-height: 40px; }
  .messengers-list a:hover {
    text-decoration: none; }

@media (min-width: 768px) {
  .icn-carousel {
    opacity: 1 !important; } }
@media (max-width: 767px) {
  .icn-carousel [class*='col-'] + * {
    margin-top: 15px; }
  .icn-carousel .title-wrap {
    margin-top: 20px;
    margin-bottom: 20px; } }
@media (max-width: 767px) {
  .icn-carousel > *:not(:first-child) {
    margin-top: 20px; } }
.icn-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 368px;
  text-decoration: none; }
  @media (max-width: 767px) {
    .icn-text + .icn-text {
      margin-top: 15px; } }
  @media (max-width: 767px) {
    .icn-text {
      padding-left: 0;
      padding-right: 0; } }
  .icn-text.icn-text-wmax {
    max-width: 343px; }
  .icn-text:hover {
    text-decoration: none; }
    .icn-text:hover .icn-text-title {
      color: var(--mainColor);
      transition: 0.25s; }
    .icn-text:hover .icn-text-circle {
      box-shadow: 0 0 0 7px var(--boxShadowColor); 
    }
  .icn-text .icn-text-title {
    margin-bottom: 0;
    transition: 0.25s; }
    .icn-text .icn-text-title + * {
      margin-top: 23px; }
      @media (max-width: 1023px) {
        .icn-text .icn-text-title + * {
          margin-top: 15px; } }
      @media (max-width: 767px) {
        .icn-text .icn-text-title + * {
          margin-top: 10px; } }
  .icn-text .icn-text-simple [class*='icon'] {
    font-size: 55px;
    line-height: 67px;
    color: var(--mainColor); }
    .icn-text .icn-text-simple [class*='icon'].icon-innovation:before {
      font-size: 67px;
      line-height: 60px; }
  .icn-text .icn-text-circle {
    margin-top: 11px;
    flex-basis: 81px;
    width: 81px;
    height: 81px;
    background-image: linear-gradient(to right, var(--advantagesColor) 0%, var(--advantagesColor) 100%);
    background-repeat: repeat-x;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    transition: 0.25s;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08); 
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 42px;
        height: 42px;
        object-fit: contain;
    }
}
    @media (max-width: 767px) {
      .icn-text .icn-text-circle {
        flex-basis: 74px;
        width: 74px;
        height: 74px;
        margin: 0; } }
    @media (max-width: 1023px) {
      .icn-text .icn-text-circle {
        margin-top: 10px; } }
  .icn-text [class*='icon'] {
    font-size: 42px;
    line-height: 81px; }
    @media (max-width: 767px) {
      .icn-text [class*='icon'] {
        line-height: 74px; } }
  .icn-text .icn-text-circle--sm [class*='icon'] {
    font-size: 36px;
    line-height: 78px; }
  .icn-text .icon-syringe {
    line-height: 72px; }
  .icn-text > * + * {
    margin-top: 34px; }
    @media (max-width: 1023px) {
      .icn-text > * + * {
        margin-top: 22px; } }
    @media (max-width: 767px) {
      .icn-text > * + * {
        margin-top: 18px; } }
    @media (max-width: 575px) {
      .icn-text > * + * {
        margin-top: 15px; } }
  .icn-text > .icn-text-simple + * {
    margin-top: 22px; }
  @media (max-width: 767px) {
    .icn-text p {
      font-size: 14px;
      line-height: 20px; } }

.icn-text-sm {
  margin-bottom: 10px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 16px;
  line-height: 22px;
  min-width: 100px; }
  @media (max-width: 767px) {
    .icn-text-sm {
      font-size: 14px;
      line-height: 22px;
      padding: 0; } }
  .icn-text-sm .icn-text-circle {
    display: inline-block;
    width: 54px;
    height: 54px;
    background-color: var(--mainColor);
    border-radius: 50%;
    color: #fff;
    margin-bottom: 10px; }
  .icn-text-sm [class*='icon'] {
    font-size: 28px;
    line-height: 54px; }
  .icn-text-sm span {
    font-size: 14px;
    font-weight: 600;
    color: var(--mainColor); }

@media (max-width: 575px) {
  .row > *:not(:first-child) .icn-text-num {
    margin-top: 30px; } }
.icn-text-num-number {
  font-size: 72px;
  line-height: 1em;
  font-weight: 600;
  font-family: var(--fontFamily);
  margin-bottom: 10px;
  color: var(--mainColor); }
  @media (max-width: 1023px) {
    .icn-text-num-number {
      font-size: 52px; } }
.icn-text-num-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: #444; }
  @media (max-width: 1023px) {
    .icn-text-num-title {
      font-size: 17px;
      line-height: 24px; } }
  @media (max-width: 1023px) {
    .icn-text-num-title {
      font-size: 15px;
      line-height: 24px; } }
.icn-text-num-text:not(:first-child) {
  margin-top: 15px; }
  @media (max-width: 767px) {
    .icn-text-num-text:not(:first-child) {
      margin-top: 10px; } }

.icn-text-alt {
  display: flex;
  text-decoration: none;
  max-width: 358px;
  margin-bottom: 6px; }
  @media (max-width: 1023px) {
    .icn-text-alt {
      flex-direction: column;
      text-align: center; } }
  .icn-text-alt:hover {
    text-decoration: none; }
    .icn-text-alt:hover .icn-text-title {
      color: var(--mainColor);
      transition: 0.25s; }
    .icn-text-alt:hover .icn-text-circle {
      box-shadow: 0 0 0 10px rgba(30, 118, 189, 0.2); }
  .icn-text-alt .icn-text-alt-title {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 34px;
    transition: 0.25s; }
    @media (max-width: 1023px) {
      .icn-text-alt .icn-text-alt-title {
        font-size: 17px;
        line-height: 24px; } }
    @media (max-width: 767px) {
      .icn-text-alt .icn-text-alt-title {
        font-size: 16px;
        line-height: 20px; } }
    .icn-text-alt .icn-text-alt-title + * {
      margin-top: 14px; }
      @media (max-width: 767px) {
        .icn-text-alt .icn-text-alt-title + * {
          margin-top: 10px; } }
  .icn-text-alt .icn-text-alt-icn {
    padding-top: 7px;
    padding-left: 15px;
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .icn-text-alt .icn-text-alt-icn {
        padding-left: 0; } }
    .icn-text-alt .icn-text-alt-icn [class*='icon'] {
      font-size: 56px;
      line-height: 1em; }
      @media (max-width: 1023px) {
        .icn-text-alt .icn-text-alt-icn [class*='icon'] {
          font-size: 42px; } }
      @media (max-width: 767px) {
        .icn-text-alt .icn-text-alt-icn [class*='icon'] {
          font-size: 36px; } }
  .icn-text-alt > * + * {
    margin-left: 28px; }
    @media (max-width: 1023px) {
      .icn-text-alt > * + * {
        margin-left: 0;
        margin-top: 7px; } }

.icn-text-vert {
  text-align: center; }
  .icn-text-vert:hover {
    text-decoration: none; }
    .icn-text-vert:hover .icn-text-vert-title {
      color: var(--mainColor); }
    .icn-text-vert:hover .icn-text-vert-icn {
      color: #ffffff;
      background-color: var(--mainColor); }
  .icn-text-vert .icn-text-vert-title {
    max-width: 185px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .4px;
    transition: 0.25s; }
    @media (max-width: 1023px) {
      .icn-text-vert .icn-text-vert-title {
        font-size: 13px;
        line-height: 18px; } }
  .icn-text-vert .icn-text-vert-icn {
    position: relative;
    display: inline-flex;
    width: 104px;
    height: 104px;
    align-items: center;
    justify-content: center;
    background-color: #f8f9f7;
    color: var(--mainColor);
    border-radius: 50%;
    transition: 0.25s; }
    .icn-text-vert .icn-text-vert-icn [class*='icon'] {
      font-size: 44px;
      line-height: 1em; }
    .icn-text-vert .icn-text-vert-icn + * {
      margin-top: 14px; }
      @media (max-width: 767px) {
        .icn-text-vert .icn-text-vert-icn + * {
          margin-top: 10px; } }
  @media (max-width: 575px) {
    .icn-text-vert > * + * {
      margin-top: 7px; } }

@media (max-width: 575px) {
  .col-sm-4:not(:first-child) .icn-text-vert {
    margin-top: 20px; } }
@media (min-width: 1024px) {
  .row > *:not(:first-child) > .icn-text-vert:after {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #f2f2f3; } }
.tab-vert-wrap {
  background-color: #ffffff; }
  .tab-vert-wrap h3 {
    font-size: 30px;
    line-height: 1.2em;
    margin-bottom: 25px; }
    @media (max-width: 1023px) {
      .tab-vert-wrap h3 {
        font-size: 26px;
        line-height: 1.2em;
        margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .tab-vert-wrap h3 {
        font-size: 18px;
        line-height: 1.2em;
        margin-bottom: 10px; } }

.tab-content-v .tab-pane {
  padding: 40px 50px; }
  .tab-content-v .tab-pane .nav-pills-v {
    display: none; }
  @media (max-width: 1023px) {
    .tab-content-v .tab-pane {
      padding: 20px 30px; } }
  @media (max-width: 767px) {
    .tab-content-v .tab-pane {
      display: block !important;
      padding: 0;
      opacity: 1; }
      .tab-content-v .tab-pane .nav-pills-v {
        display: block !important; } }

@media (min-width: 768px) {
  .tab-content-v .tab-pane .collapse {
    display: block !important; } }
@media (max-width: 767px) {
  .tab-content-m {
    padding: 20px 10px; } }
.tooltip-inner {
  border-radius: 0; }

.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  transform: rotate(135deg);
  bottom: 1px; }

body.touch .tooltip.show {
  display: none !important; }

.info-box-color {
  background-color: var(--mainColor);
  padding: 35px 40px 25px;
  color: #fff; }
  @media (max-width: 1023px) {
    .info-box-color {
      padding: 25px 20px 15px; } }
  .info-box-color a:not(.btn) {
    color: #fff; }
  .info-box-color ul.marker-list {
    margin-left: 10px; }
  .info-box-color ul.marker-list li:before {
    color: #fff; }

.info-box-grey {
  background-color: #f3f3f3;
  padding: 35px 40px 25px; }
  @media (max-width: 1023px) {
    .info-box-grey {
      padding: 15px 20px 15px; } }
  .info-box-grey ul.icn-list li:not(:last-child) {
    margin-bottom: 10px; }

.info-box-price {
  font-size: 50px;
  font-family: var(--fontFamily);
  line-height: 1em; }

.info-box-color + *,
.info-box-grey + * {
  margin-top: 30px; }

.info-grid {
  border-bottom: 1px solid #ebebeb;
  padding: 19px 0;
  text-align: center; }
  .info-grid [class*='col-'] {
    padding: 17px 0;
    border-left: 1px solid #ebebeb; }
  .info-grid [class*='col-']:first-child {
    border-left: 0; }
  @media (max-width: 767px) {
    .info-grid {
      border-bottom: 0; }
      .info-grid [class*='col-'] {
        border-bottom: 1px solid #ebebeb; }
      .info-grid [class*='col-']:nth-last-child(-n+2) {
        border-bottom: 0; } }
  .info-grid-label {
    font-size: 14px;
    color: var(--mainColor); }
  .info-grid-text {
    font-size: 28px;
    line-height: 1em;
    font-family: var(--fontFamily); }
    @media (max-width: 1023px) {
      .info-grid-text {
        font-size: 24px; } }

.page-item.active {
  font-weight: 600; }

.quote {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
  font-style: italic;
  padding-left: 138px; }
  @media (max-width: 1023px) {
    .quote {
      padding-left: 100px; } }
  @media (max-width: 767px) {
    .quote {
      padding-left: 70px; } }
  .quote p {
    padding-left: 0 !important; }
  .quote:after {
    content: '“';
    position: absolute;
    left: 30px;
    top: 0;
    font-size: 160px;
    line-height: 1em;
    font-weight: 600;
    font-style: normal;
    font-family: var(--fontFamily);
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .quote:after {
        font-size: 120px;
        left: 30px; } }
    @media (max-width: 767px) {
      .quote:after {
        font-size: 80px;
        left: 20px; } }

.tags-list {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0; }
  .tags-list li {
    display: inline-block;
    padding: 0;
    margin-bottom: 5px;
    margin-right: 2px; }
    .tags-list li a {
      display: block;
      color: #787878;
      font-size: 14px;
      line-height: 25px;
      padding: 5px 10px;
      text-decoration: none;
      border-radius: 0;
      background: #f8f8f8;
      transition: all 0.2s ease; }
      .tags-list li a:hover {
        background-color: var(--mainColor);
        border-color: var(--mainColor);
        color: #fff; }

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center; }

.bg-norepeat {
  background-repeat: no-repeat; }

.bg-fixed {
  background-attachment: fixed; }

.bg-left {
  background-position-x: left; }

.bg-right {
  background-position-x: right; }

.bg-bottom {
  background-position-y: bottom; }

.bg-top {
  background-position-y: top; }

.bg-full {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center; }

.bg-fullheight {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center; }
  @media (max-width: 1229px) {
    .bg-fullheight {
      background-size: 100% auto; } }

@media (max-width: 1229px) {
  .bg-md-none {
    background: none !important; } }
@media (max-width: 1023px) {
  .video-wrap {
    max-width: 425px;
    margin-left: auto;
    margin-right: auto; } }
.tab-bg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0; }
  .tab-bg img {
    max-width: 100%; }

.nav-pills-v {
  display: block;
  height: 100%;
  padding: 0 40px 40px;
  background-color: #444444; }
  @media (max-width: 1023px) {
    .nav-pills-v {
      padding: 0 25px 30px; } }
  @media (max-width: 767px) {
    .nav-pills-v {
      display: none !important;
      padding: 0; } }
  .nav-pills-v .nav-link {
    display: block;
    position: relative;
    width: 100%;
    padding: 36px 0;
    border: 0;
    border-radius: 0;
    font-size: 20px;
    font-family: var(--fontFamily);
    font-weight: 500;
    color: #ffffff;
    background: transparent;
    border-bottom: 1px solid #696969; }
    @media (max-width: 767px) {
      .nav-pills-v .nav-link {
        width: auto;
        margin: 0 10px;
        padding: 21px 0;
        font-size: 18px; } }
    .nav-pills-v .nav-link span {
      position: relative;
      padding-right: 8px; }
    .nav-pills-v .nav-link:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 15%;
      height: 3px;
      background-color: var(--mainColor);
      transition: 0.2s; }
    .nav-pills-v .nav-link:hover, .nav-pills-v .nav-link.active {
      background: transparent; }
    .nav-pills-v .nav-link:hover:after {
      width: 50%; }
      @media (max-width: 767px) {
        .nav-pills-v .nav-link:hover:after {
          width: 100%; } }
    .nav-pills-v .nav-link.active:after {
      width: 100%; }

.nav-pills-simple {
  justify-content: center; }
  .nav-pills-simple .nav-link {
    padding: 0 25px;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    font-family: var(--fontFamily);
    font-weight: 600;
    letter-spacing: .75px;
    color: #444444; }
    @media (max-width: 575px) {
      .nav-pills-simple .nav-link {
        padding: 0 7px;
        font-size: 12px; } }
    .nav-pills-simple .nav-link:hover {
      background: transparent;
      color: var(--mainColor); }
    .nav-pills-simple .nav-link.active {
      background: transparent;
      color: var(--mainColor); }

.nav-pills .nav-link {
  margin-right: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  text-shadow: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  font-family: var(--fontFamily);
  padding: 14px 16px;
  color: #444;
  border: 1px solid #d5d5d5;
  border-radius: 25px;
  white-space: nowrap;
  transition: 0.3s; }
  @media (max-width: 767px) {
    .nav-pills .nav-link {
      font-size: 15px;
      line-height: 15px;
      min-width: calc(50% - 8px);
      text-align: center; } }
  @media (max-width: 575px) {
    .nav-pills .nav-link {
      width: 100%; } }
  .nav-pills .nav-link:hover {
    background: transparent;
    border-color: var(--mainColor); }

.nav-pills-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  @media (max-width: 575px) {
    .nav-pills-icons {
      margin-left: -4px;
      margin-right: -4px; } }

.nav-pills-icons .nav-link {
  width: 178px;
  max-width: calc(16.666% - 20px);
  margin: 0 10px 20px;
  padding: 20px 17px 15px;
  cursor: pointer;
  text-shadow: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: var(--fontFamily);
  color: #444;
  border: 0;
  border-radius: 4px;
  text-align: center;
  transition: 0.3s; }
  .nav-pills-icons .nav-link [class*='icon'] {
    display: block;
    font-size: 62px;
    color: var(--mainColor); }
  .nav-pills-icons .nav-link span {
    margin-top: 20px;
    display: block; }
  .nav-pills-icons .nav-link:hover, .nav-pills-icons .nav-link.active {
    background: var(--mainColor);
    color: #ffffff; }
    .nav-pills-icons .nav-link:hover [class*='icon'], .nav-pills-icons .nav-link.active [class*='icon'] {
      color: #ffffff; }
  @media (max-width: 1229px) {
    .nav-pills-icons .nav-link {
      font-size: 13px;
      line-height: 18px;
      padding: 19px 10px; }
      .nav-pills-icons .nav-link [class*='icon'] {
        font-size: 52px; }
      .nav-pills-icons .nav-link span {
        margin-top: 15px; } }
  @media (max-width: 1023px) {
    .nav-pills-icons .nav-link {
      width: 170px;
      max-width: 100%; } }
  @media (max-width: 575px) {
    .nav-pills-icons .nav-link {
      width: calc(33.3% - 8px);
      padding: 11px 4px 8px;
      margin: 0 4px 8px;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: -.01em; }
      .nav-pills-icons .nav-link [class*='icon'] {
        font-size: 30px; }
      .nav-pills-icons .nav-link span {
        margin-top: 6px;
        height: 27px;
        overflow: hidden; } }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: transparent;
  border-color: var(--mainColor);
  color: #444;
  box-shadow: inset 0 0 0 1px var(--mainColor); }

.services-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #e6e6e6; }
  .services-nav .nav-item:last-child .nav-link {
    border-bottom: 0; }
  .services-nav > .nav-item > .nav-link {
    display: block;
    border: 0;
    background-color: #f8f8f8;
    margin-bottom: 0;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: #444;
    padding: 15px;
    transition: 0.3s; 
    width: 100%;
    text-align: start;
}
  .services-nav ul .nav-item {
    position: relative;
    transition: 0.3s; }
    .services-nav ul .nav-item:hover {
      position: relative;
      z-index: 1;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); }
  .services-nav ul .nav-link {
    display: block;
    position: relative;
    border: 0;
    background-color: #fff;
    margin-bottom: 0;
    padding: 15px 15px 15px 50px;
    border-bottom: 1px solid #e6e6e6 !important;
    border-radius: 0;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    transition: 0.3s; }
    .services-nav ul .nav-link:before {
      content: '\e921';
      font-family: 'dentco';
      position: absolute;
      left: 24px;
      top: 17px;
      font-size: 9px;
      display: block;
      color: var(--mainColor); }
    .services-nav ul .nav-link:hover, .services-nav ul .nav-link.active {
      background: #469ce2;
      color: #fff; }
      .services-nav ul .nav-link:hover:before, .services-nav ul .nav-link.active:before {
        color: #fff; }

.service-img {
  position: relative; }
  .service-img-caption {
    position: absolute;
    left: 0;
    bottom: 50px;
    margin: 0;
    background-color: #fff;
    font-size: 38px;
    line-height: 45px;
    font-family: var(--fontFamily);
    padding: 15px 28px; }
    @media (max-width: 1023px) {
      .service-img-caption {
        padding: 10px 18px;
        font-size: 28px;
        line-height: 35px;
        bottom: 10%; } }
    @media (max-width: 767px) {
      .service-img-caption {
        padding: 5px 10px;
        font-size: 18px;
        line-height: 25px; } }

.pagination {
  flex-wrap: wrap; }
  .pagination .page-link {
    font-size: 15px;
    line-height: 36px;
    font-weight: 400;
    width: 41px;
    height: 41px;
    border-radius: 50% !important;
    border-width: 2px;
    padding: 0;
    margin-left: 7px;
    color: #787878;
    transition: all 0.2s ease;
    text-align: center; }

.table-striped-custom {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  color: #444444; }
  @media (max-width: 767px) {
    .table-striped-custom {
      font-size: 14px;
      line-height: 20px; } }
  .table-striped-custom td {
    padding: 7px 10px;
    vertical-align: top;
    background-color: #fff;
    border: 0; }
  .table-striped-custom th {
    padding: 7px 10px;
    vertical-align: top;
    background-color: #fff;
    border: 0;
    font-size: 16px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .table-striped-custom th {
        font-size: 14px; } }
  .table-striped-custom tr:nth-child(even) td {
    background-color: #f9f9f9; }

.price-table, .doctor-page-table {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px; }
  @media (max-width: 767px) {
    .price-table, .doctor-page-table {
      font-size: 14px;
      line-height: 20px; } }
  .price-table th,
  .price-table td, .doctor-page-table th,
  .doctor-page-table td {
    padding: 14px 14px;
    vertical-align: top;
    border-top: 1px solid #e6e6e6;
    background-color: #fff; }
    @media (max-width: 767px) {
      .price-table th,
      .price-table td, .doctor-page-table th,
      .doctor-page-table td {
        padding: 7px 10px; } }
  .price-table th, .doctor-page-table th {
    background-color: var(--mainColor);
    color: #fff;
    font-size: 16px;
    font-weight: bold; }
    @media (max-width: 767px) {
      .price-table th, .doctor-page-table th {
        font-size: 14px; } }
  .price-table ul.marker-list-md, .doctor-page-table ul.marker-list-md {
    font-size: 16px;
    line-height: 16px; }

.doctor-page-table {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto; }
  .doctor-page-table tr:nth-child(odd) td {
    background-color: #f6f6f6; }

.price-table {
  font-size: 15px;
  line-height: 24px; }
  .price-table th,
  .price-table td {
    padding: 15px 30px;
    vertical-align: middle; }
    @media (max-width: 767px) {
      .price-table th,
      .price-table td {
        padding: 7px 10px; } }
  .price-table tr > *:nth-child(2), .price-table tr > *:nth-child(3), .price-table tr > *:nth-child(4) {
    text-align: center; }
  .price-table tr:nth-child(even) td {
    background-color: #f6f6f6; }
  .price-table .fixed-side {
    min-width: 200px; }

.table-scroll {
  position: relative;
  margin: auto;
  overflow: hidden; }
  .table-scroll .clone {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none; }
  .table-scroll .clone td {
    visibility: hidden; }
  .table-scroll .clone tbody th {
    visibility: hidden; }
  .table-scroll .clone .fixed-side {
    visibility: visible; }

.table-wrap {
  width: 100%;
  overflow: auto; }

.contact-map iframe, .google-map iframe {
  width: 100%;
  height: 100%;
  border: 0; }

.contact-map {
  width: 100%;
  height: 357px; }

.contact-inline {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  list-style: none; }
  @media (max-width: 575px) {
    .contact-inline {
      margin: 0 -2px; } }
  .contact-inline li {
    margin: 0 5px; }
    @media (max-width: 575px) {
      .contact-inline li {
        margin: 0 2px; } }
  .contact-inline [class*='icon'] {
    font-size: 14px; }
    .contact-inline [class*='icon'] + span {
      padding-left: 3px; }
    @media (max-width: 575px) {
      .contact-inline [class*='icon'] {
        font-size: 12px; } }
  .contact-inline a > [class*='icon'] {
    padding: 0 3px; }
    @media (max-width: 575px) {
      .contact-inline a > [class*='icon'] {
        padding: 0 1px; } }
  .contact-inline a:hover {
    text-decoration: none;
    color: var(--mainColor); }

.content-social {
  font-size: 17px;
  line-height: 69px; }
  @media (max-width: 767px) {
    .content-social {
      line-height: 62px; } }
  .content-social a {
    display: inline-block;
    margin-right: 4px;
    width: 69px;
    height: 69px;
    color: var(--mainColor);
    background-color: #f8f8f8;
    text-align: center;
    border-radius: 50%;
    transition: 0.2s; }
    @media (max-width: 767px) {
      .content-social a {
        width: 60px;
        height: 60px; } }
    .content-social a:hover {
      background-color: #fff;
      color: var(--mainColor);
      text-decoration: none;
      box-shadow: inset 0 0 0 5px rgba(30, 118, 189, 0.2); }

.video-box {
  position: relative; }
  .video-box .video-btn {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    bottom: 35px;
    left: 0;
    padding: 20px 76px 20px 30px;
    height: 61px;
    font-family: var(--fontFamily);
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    border-radius: 0 30px 30px 0;
    color: #fff;
    background: var(--mainColor);
    text-decoration: none;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .video-box .video-btn {
        bottom: 30px;
        padding: 12px 55px 12px 19px;
        height: 45px;
        font-size: 13px;
        line-height: 21px;
        font-weight: 500;
        border-radius: 0 22px 22px 0; } }
    @media (max-width: 767px) {
      .video-box .video-btn {
        bottom: 10px;
        padding: 10px 50px 10px 20px;
        height: 37px;
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        border-radius: 0 18px 18px 0; } }
  .video-box span:last-child {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 41px;
    height: 41px;
    font-size: 16px;
    border-radius: 50%;
    background-color: #ffffff;
    color: var(--mainColor);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
    transition: 0.5s; }
    @media (max-width: 1023px) {
      .video-box span:last-child {
        top: 7px;
        right: 8px;
        width: 30px;
        height: 30px;
        font-size: 14px;
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2); } }
    @media (max-width: 767px) {
      .video-box span:last-child {
        top: 7px;
        right: 8px;
        width: 22px;
        height: 22px;
        font-size: 12px;
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2); } }
    .video-box span:last-child [class*='icon'] {
      position: relative;
      left: 1px; }
  .video-box:hover span:last-child {
    box-shadow: 0 0 0 300px rgba(30, 118, 189, 0.2); }

@media (max-width: 767px) {
  .video-box {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; } }

.video-box-poster {
  position: relative;
  z-index: 1; }

.video-box-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  max-width: 120%; }
  .video-box-bg img {
    max-width: 100%; }

.video-btn-circle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50% - 50px);
  right: -10px;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.3);
  font-size: 25px;
  color: var(--mainColor);
  transition: 0.2s; }
  @media (max-width: 1023px) {
    .video-btn-circle {
      width: 64px;
      height: 64px;
      font-size: 20px; } }
  @media (max-width: 767px) {
    .video-btn-circle {
      right: 0; } }
  .video-btn-circle:hover {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.3);
    background-color: var(--mainColor);
    color: #ffffff;
    text-decoration: none; }

.text-image-block {
  background-color: #f6f7f5; }
  .text-image-block .image-col {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
    .text-image-block .image-col img {
      max-height: 100%; }
      @media (max-width: 1229px) {
        .text-image-block .image-col img {
          max-height: 95%;
          margin-right: -5%; } }
      @media (max-width: 1023px) {
        .text-image-block .image-col img {
          max-height: 95%;
          margin-right: -20%; } }
      @media (max-width: 767px) {
        .text-image-block .image-col img {
          max-width: 100%;
          max-height: none;
          margin: 0; } }
  .text-image-block .text-col {
    position: relative;
    z-index: 1;
    padding: 100px 15px;
    max-width: 570px; }
    @media (max-width: 1023px) {
      .text-image-block .text-col {
        z-index: 2; } }
    @media (max-width: 767px) {
      .text-image-block .text-col {
        padding: 50px 15px 20px;
        order: -1; }
        .text-image-block .text-col .title-wrap {
          text-align: center; } }
  .text-image-block .h-sub {
    position: relative;
    z-index: 1; }
  .text-image-block [data-title] {
    display: inline-flex;
    position: relative;
    z-index: 0; }
    .text-image-block [data-title] span {
      position: relative;
      z-index: 1; }
    .text-image-block [data-title]:after {
      position: absolute;
      z-index: 0;
      right: -7px;
      top: -.52em;
      font-size: 120px;
      line-height: 1em;
      color: #fff;
      white-space: nowrap;
      content: attr(data-title); }
      @media (max-width: 1229px) {
        .text-image-block [data-title]:after {
          font-size: 100px;
          left: 50%;
          right: auto;
          transform: translateX(-47%); } }
      @media (max-width: 575px) {
        .text-image-block [data-title]:after {
          top: -10px;
          font-size: 70px;
          left: 50%;
          right: auto;
          transform: translateX(-50%); } }

.block-full-appointment {
  padding-top: 150px;
  padding-bottom: 150px;
  color: #ffffff; }
  @media (max-width: 1229px) {
    .block-full-appointment {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (max-width: 1023px) {
    .block-full-appointment {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 767px) {
    .block-full-appointment {
      padding-top: 50px;
      padding-bottom: 50px;
      background-position: right; } }
  .block-full-appointment h1, .block-full-appointment h2, .block-full-appointment h3, .block-full-appointment h4, .block-full-appointment h5, .block-full-appointment h6 {
    color: #ffffff; }
  .block-full-appointment .text1 {
    font-size: 36px;
    line-height: 52px; }
    @media (max-width: 1023px) {
      .block-full-appointment .text1 {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .block-full-appointment .text1 {
        font-size: 26px;
        line-height: 30px; } }
  .block-full-appointment .text2 {
    font-size: 14px;
    line-height: 24px;
    font-family: var(--fontFamily); }
    @media (max-width: 1023px) {
      .block-full-appointment .text2 {
        font-size: 14px;
        line-height: 24px; } }
    @media (max-width: 767px) {
      .block-full-appointment .text2 {
        font-size: 13px;
        line-height: 18px; } }

.box-progress {
  display: flex; }
  @media (max-width: 767px) {
    .box-progress {
      flex-direction: column;
      align-items: center; } }
  .box-progress-number {
    font-size: 48px;
    line-height: 1em;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: var(--mainColor);
    min-width: 125px;
    padding-right: 15px; }
    @media (max-width: 1023px) {
      .box-progress-number {
        font-size: 42px;
        min-width: 100px; } }
    @media (max-width: 767px) {
      .box-progress-number {
        font-size: 38px;
        padding: 0 0 10px;
        text-align: center; } }
    .box-progress-number sup {
      font-size: 28px;
      top: -18px; }
      @media (max-width: 1023px) {
        .box-progress-number sup {
          font-size: 24px;
          top: -16px; } }
      @media (max-width: 767px) {
        .box-progress-number sup {
          font-size: 22px;
          top: -15px; } }
  .box-progress-text {
    flex: 1;
    max-width: 380px; }
    .box-progress-text p:last-child {
      margin-bottom: 0; }
    @media (max-width: 767px) {
      .box-progress-text {
        text-align: center;
        max-width: 240px; } }
  .box-progress h5 {
    font-size: 18px;
    line-height: 24px; }
    @media (max-width: 1023px) {
      .box-progress h5 {
        margin-bottom: 15px; } }
    @media (max-width: 767px) {
      .box-progress h5 {
        margin-bottom: 10px; } }

.box-progress + .box-progress {
  margin-top: 42px; }
  @media (max-width: 767px) {
    .box-progress + .box-progress {
      margin-top: 30px; } }

.title-with-clone {
  position: relative;
  display: inline-block; }
  .title-with-clone span {
    position: relative;
    z-index: 1; }
  @media (max-width: 1023px) {
    .title-with-clone {
      display: block; } }

.title-with-clone:after {
  position: absolute;
  z-index: 0;
  left: 90%;
  top: -.35em;
  font-size: 120px;
  line-height: 1em;
  white-space: nowrap;
  content: attr(data-title);
  color: #f6f7f5; }
  @media (max-width: 1229px) {
    .title-with-clone:after {
      font-size: 100px;
      left: 50%; } }
  @media (max-width: 1023px) {
    .title-with-clone:after {
      font-size: 100px;
      left: auto;
      right: 0; } }
  @media (max-width: 575px) {
    .title-with-clone:after {
      font-size: 70px; } }

.bg-grey .title-with-clone:after {
  color: #ffffff; }

.title-wrap.text-center:not(.text-md-left) .title-with-clone {
  display: block; }
.title-wrap.text-center:not(.text-md-left) .title-with-clone:after {
  z-index: -1;
  right: auto;
  left: 50%;
  top: -.12em;
  transform: translateX(-50%); }
  @media (max-width: 1023px) {
    .title-wrap.text-center:not(.text-md-left) .title-with-clone:after {
      top: -.35em; } }

@media (max-width: 1023px) {
  .title-wrap.text-center.text-md-left .title-with-clone {
    display: block; }
  .title-wrap.text-center.text-md-left .title-with-clone:after {
    z-index: 0;
    right: auto;
    left: 50%;
    top: -.5em;
    transform: translateX(-50%); } }
.promo-text {
  padding: 30px 0 30px;
  text-align: center; }
  @media (max-width: 1023px) {
    .promo-text {
      padding: 18px 15px 25px; } }
  @media (max-width: 767px) {
    .promo-text {
      padding: 15px; } }
  .promo-text h4 {
    margin-bottom: 7px; }
    @media (max-width: 1023px) {
      .promo-text h4 {
        font-size: 18px; } }
    @media (max-width: 575px) {
      .promo-text h4 {
        font-size: 15px; } }
  .promo-text .text-lg {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .promo-text .text-lg {
        font-size: 28px; } }
    @media (max-width: 575px) {
      .promo-text .text-lg {
        font-size: 24px; } }
  .promo-text [class*='icon'] {
    position: relative;
    vertical-align: sub;
    padding: 0 8px;
    font-size: 1.7em; }
    @media (max-width: 1023px) {
      .promo-text [class*='icon'] {
        top: 2px;
        font-size: 40px; } }
    @media (max-width: 575px) {
      .promo-text [class*='icon'] {
        display: block;
        top: 0;
        margin-bottom: 5px;
        font-size: 40px; } }

.collapsed-view-all-list {
  display: none; }

@media (max-width: 1023px) {
  .collapsed-view-all-list, .collapsed-view-all-list {
    display: block; }

  ul.collapsed-md.collapsed-md--3 li:nth-child(3) {
    opacity: .5; }
  ul.collapsed-md.collapsed-md--3 li:nth-child(3) ~ li {
    display: none; }

  ul.collapsed-md.collapsed-md--7 li:nth-child(7) {
    opacity: .5; }
  ul.collapsed-md.collapsed-md--7 li:nth-child(7) ~ li {
    display: none; } }
.simple-content-carousel .slick-dots {
  margin: 30px 0 0; }
.simple-content-carousel img {
  display: inline-block;
  max-width: 100%;
  width: auto; }
  @media (max-width: 767px) {
    .simple-content-carousel img {
      max-width: 70%;
      margin-bottom: 20px; } }

.double-title {
  position: relative; }
  .double-title span {
    position: relative;
    z-index: 1; }

.double-title:after {
  position: absolute;
  z-index: 0;
  right: 0;
  left: 0;
  top: -.65em;
  font-size: 120px;
  line-height: 1em;
  white-space: nowrap;
  content: attr(data-title);
  color: #f7f9fb; }
  @media (max-width: 1229px) {
    .double-title:after {
      font-size: 100px; } }
  @media (max-width: 575px) {
    .double-title:after {
      font-size: 70px; } }

.bg-title {
  font-size: 120px;
  line-height: 1em;
  white-space: nowrap;
  content: attr(data-title);
  color: #ffffff; }
  @media (max-width: 1229px) {
    .bg-title {
      font-size: 100px; } }
  @media (max-width: 1229px) {
    .bg-title {
      margin-bottom: -.75em; } }
  @media (max-width: 575px) {
    .bg-title {
      font-size: 70px; } }
  .bg-title + * {
    position: relative;
    margin-top: -60px; }

.double-title--white:after {
  color: #ffffff; }

.double-title--vcenter:after {
  top: -.36em; }

.double-title--center:after {
  right: auto;
  left: 50%;
  transform: translateX(-50%); }

@media (min-width: 1230px) {
  .text-lg-left .double-title:after {
    left: -0.2em; } }
@media (max-width: 1229px) {
  .text-lg-left .double-title:after {
    left: 0; } }
@media (max-width: 1229px) {
  .double-title--center-lg:after {
    right: auto;
    left: 50%;
    transform: translateX(-50%); } }
.double-title--left:after {
  left: -0.2em; }

.double-title--right:after {
  left: auto;
  right: -0.2em; }
  @media (max-width: 1229px) {
    .double-title--right:after {
      right: auto;
      left: 50%;
      transform: translateX(-50%); } }

.double-title--top:after {
  left: -0.2em;
  top: -.76em; }

.double-title--top-md:after {
  left: -0.2em;
  top: -.76em; }

.over-image-card {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 455px;
  padding: 30px;
  background-color: var(--mainColor);
  color: #ffffff; }
  .over-image-card h4 {
    color: #ffffff; }
  .over-image-card h4 {
    margin-bottom: 10px; }
  .over-image-card p {
    margin-bottom: 25px; }
  .over-image-card.pos-right {
    right: 0;
    bottom: 0; }
  .over-image-card.pos-left {
    left: 25px;
    bottom: -15px; }
    @media (max-width: 1229px) {
      .over-image-card.pos-left {
        left: 15px;
        bottom: 0; } }
    @media (max-width: 1023px) {
      .over-image-card.pos-left {
        left: 0; } }
  @media (max-width: 1229px) {
    .over-image-card {
      width: 398px;
      max-width: 100%;
      padding: 25px; }
      .over-image-card h4 {
        font-size: 20px;
        line-height: 1.1em;
        margin-bottom: 10px; } }
  @media (max-width: 767px) {
    .over-image-card {
      position: static; } }

.over-image-card-icon {
  font-size: 70px;
  padding-right: 25px; }
  @media (max-width: 1229px) {
    .over-image-card-icon {
      font-size: 45px;
      padding-right: 15px; } }

.over-hidden {
  overflow: hidden !important; }

.over-visible {
  overflow: visible !important; }

.text-no-uppercase {
  text-transform: none !important; }

.title-button-right {
  position: absolute;
  top: 30px;
  right: 0; }
  @media (max-width: 1023px) {
    .title-button-right {
      position: static;
      margin-top: 20px; } }

.counter-box {
  margin-top: 10px;
  padding: 45px 15px 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); }
  @media (max-width: 1229px) {
    .counter-box {
      padding: 45px 10px 63px; } }
  @media (max-width: 1023px) {
    .counter-box {
      padding: 20px 5px 28px;
      max-width: 192px;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767px) {
    .counter-box {
      padding: 45px 15px 65px;
      max-width: 250px; } }
  .counter-box > *:not(:first-child) {
    margin-top: 16px; }
    @media (max-width: 1023px) {
      .counter-box > *:not(:first-child) {
        margin-top: 8px; } }
  .counter-box-icon {
    color: var(--mainColor); 
    img{
        width: 52px;
    }
}
    @media (max-width: 1023px) {
      .counter-box-icon {
        font-size: 36px; } }
    @media (max-width: 767px) {
      .counter-box-icon {
        font-size: 44px; } }
  .counter-box-number {
    font-size: 42px;
    line-height: 1em;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: #5f5f5f; }
    @media (max-width: 1023px) {
      .counter-box-number {
        font-size: 30px; } }
    @media (max-width: 767px) {
      .counter-box-number {
        font-size: 38px; } }
  .counter-box .decor {
    opacity: 0;
    width: 29px;
    height: 1px;
    background-color: var(--mainColor);
    transform: translateY(100px);
    transition: 1s ease 0.5s; }
    @media (max-width: 1023px) {
      .counter-box .decor:not(:first-child) {
        margin-top: 15px;
        width: 26px; } }
  .counter-box-text {
    opacity: 0;
    font-size: 18px;
    line-height: 1em;
    font-weight: 600;
    font-family: var(--fontFamily);
    transform: translateY(-20px);
    color: #444;
    transition: 0.5s ease 1.8s; }
    @media (max-width: 1023px) {
      .counter-box-text {
        font-size: 14px; }
        .counter-box-text:not(:first-child) {
          margin-top: 13px; } }

.counter-box.counted .decor {
  opacity: 1;
  transform: translateY(0); }
.counter-box.counted .counter-box-text {
  opacity: 1;
  transform: translateY(0); }

.js-counter-carousel {
  margin-bottom: -20px; }
  .js-counter-carousel .slick-dots {
    margin-top: 5px; }

.js-counter-carousel .slick-track {
  padding: 10px 0 20px;
  margin: -10px 0 0; }

.counter-box-sm {
  color: #ffffff; }
  .counter-box-sm-number {
    position: relative;
    font-size: 44px;
    line-height: 1em; }
    .counter-box-sm-number span.sup {
      font-size: .6em;
      line-height: 1em;
      position: absolute;
      top: 0;
      padding: 0 .1em; }
    @media (max-width: 1229px) {
      .counter-box-sm-number {
        font-size: 40px; } }
    @media (max-width: 1023px) {
      .counter-box-sm-number {
        font-size: 38px; } }
    @media (max-width: 767px) {
      .counter-box-sm-number {
        font-size: 32px; } }
  .counter-box-sm-text {
    margin-top: 5px;
    font-size: 17px;
    line-height: 25px;
    font-weight: 600; }
    @media (max-width: 1229px) {
      .counter-box-sm-text {
        font-size: 15px;
        line-height: 23px; } }
    @media (max-width: 767px) {
      .counter-box-sm-text {
        font-size: 13px;
        line-height: 17px; } }

.over-image-counter {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 810px;
  max-width: calc(50vw - 20px);
  padding: 45px 35px;
  background-color: var(--mainColor);
  color: #ffffff;
  padding: 35px 15px; }
  @media (max-width: 1229px) {
    .over-image-counter {
      padding: 25px 10px; } }
  @media (max-width: 1023px) {
    .over-image-counter {
      padding: 28px 10px;
      max-width: 100%; } }
  @media (max-width: 767px) {
    .over-image-counter {
      position: static;
      text-align: center;
      width: auto !important; } }
  @media (max-width: 575px) {
    .over-image-counter {
      padding: 20px 0; } }
  .over-image-counter.pos-left {
    left: 0;
    bottom: 0; }
  .over-image-counter .counter-box-sm {
    position: relative;
    flex: 1;
    padding: 0;
    margin: 0 50px;
    margin: 0 30px; }
    @media (max-width: 1229px) {
      .over-image-counter .counter-box-sm {
        margin: 0 25px; } }
    @media (max-width: 767px) {
      .over-image-counter .counter-box-sm {
        margin: 0 15px; } }
    @media (max-width: 575px) {
      .over-image-counter .counter-box-sm {
        margin: 0;
        padding: 0 10px; } }
    .over-image-counter .counter-box-sm:last-child {
      margin-right: 0; }
    .over-image-counter .counter-box-sm:not(:last-child):after {
      content: '';
      position: absolute;
      top: 0;
      right: -45px;
      height: 100%;
      width: 1px;
      background-color: #ffffff;
      opacity: .3;
      right: -25px; }
      @media (max-width: 1229px) {
        .over-image-counter .counter-box-sm:not(:last-child):after {
          right: -20px; } }
      @media (max-width: 575px) {
        .over-image-counter .counter-box-sm:not(:last-child):after {
          right: -5px; } }

.col-news-bg {
  display: flex;
  flex-direction: column;
  height: auto; }
  .col-news-bg .image-shift-left {
    flex: 1; }

.popover-markup .popover {
  width: 150%; }

.container {
  margin: 0px auto; }

.form-control {
  font-size: 14px;
  font-weight: 400;
  min-height: 41px; }

.form-control:focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05); }

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none; }

@media (max-width: 767px) {
  .form-control {
    min-height: 36px;
    padding: 5px 10px;
    white-space: nowrap;
    font-size: 13px; } }
form label {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 7px; }

.form-control[readonly] {
  background-color: #fff; }

.input-group {
  position: relative;
  align-items: flex-start;
  flex-wrap: wrap; }
  .input-group [class*='icon-'] {
    display: inline-block;
    top: 4px;
    margin: 0 4px;
    position: relative;
    z-index: 3;
    color: var(--mainColor);
    font-size: 18px;
    line-height: 35px;
    width: 35px;
    text-align: center; }
    @media (max-width: 767px) {
      .input-group [class*='icon-'] {
        line-height: 28px; } }
  .input-group:after {
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 43px;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    transition: 0.2s;
    pointer-events: none; }
    @media (max-width: 767px) {
      .input-group:after {
        height: 36px; } }
  .input-group:hover:after {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05); }
  .input-group .datepicker-wrap {
    flex: 1; }
  .input-group input:focus,
  .input-group select:focus,
  .input-group textarea:focus,
  .input-group button:focus {
    outline: none !important;
    box-shadow: none; }

.input-group label.error {
  flex: 1;
  min-width: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.input-group .form-control {
  border: 0;
  z-index: 2;
  background: transparent; }

.input-group .form-control:first-child {
  padding-right: 0 !important; }

.input-group .form-control:last-child {
  padding-left: 0 !important; }

.input-group .form-control.datetimepicker,
.input-group .form-control.timepicker {
  width: 100%; }

.modal-form .input-group:after {
  height: 40px; }
.modal-form textarea.form-control:not(:focus) {
  border: 1px solid #d5d5d5;
  height: 75px; }
.modal-form textarea.form-control:focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05); }
.modal-form .dropdown-menu {
  position: static; }
  .modal-form .dropdown-menu:before, .modal-form .dropdown-menu:after {
    display: none; }
.modal-form .select.form-control {
  background: transparent;
  border: 0; }
.modal-form .selectWrapper:before {
  margin-top: -9px; }

.link-drop .input-group:after {
  height: 35px;
  border: 0; }
.link-drop .input-group [class*='icon-'] {
  top: 1px; }
@media (max-width: 1229px) {
  .link-drop .dropdown-menu {
    position: static;
    margin-bottom: 10px;
    margin-top: 10px; }
    .link-drop .dropdown-menu:before, .link-drop .dropdown-menu:after {
      display: none; } }

.link-drop .input-group .form-control {
  border: none;
  background-color: transparent;
  font-size: 15px;
  line-height: 19px;
  padding: 7px 10px 8px 0;
  min-height: 0;
  height: 35px;
  max-height: 35px; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 285px; }

.bootstrap-datetimepicker-widget .timepicker {
  padding-bottom: 8px; }

.bootstrap-datetimepicker-widget {
  width: 500px;
  transform: translateX(-35px); }
  .bootstrap-datetimepicker-widget table th {
    color: #424242;
    font-family: var(--fontFamily2);
    max-width: 28px;
    line-height: 26px;
    font-size: 14px; }
  .bootstrap-datetimepicker-widget .btn [class^="icon-"],
  .bootstrap-datetimepicker-widget .btn [class*=" icon-"] {
    font-family: 'dentco' !important;
    padding-left: 0 !important;
    transition: 0s !important;
    color: #ffffff !important;
    transform: none !important;
    opacity: 1 !important;
    width: 100% !important; }
  .bootstrap-datetimepicker-widget .btn:hover [class^="icon-"],
  .bootstrap-datetimepicker-widget .btn:hover [class*=" icon-"] {
    padding-left: 10px !important;
    background-color: transparent; }
  .bootstrap-datetimepicker-widget .btn [class*='icon']:first-child,
  .bootstrap-datetimepicker-widget .btn:focus [class*='icon']:first-child,
  .bootstrap-datetimepicker-widget .btn [class*='icon']:last-child,
  .bootstrap-datetimepicker-widget .btn:focus [class*='icon']:last-child {
    transform: none !important;
    opacity: 1;
    width: 100%;
    margin: 0;
    padding: 0;
    display: inline-block;
    line-height: 40px;
    max-width: none;
    height: 40px; }
  .bootstrap-datetimepicker-widget a[data-action] {
    padding: 0 !important; }
  .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
    background: transparent !important; }
    .bootstrap-datetimepicker-widget table thead tr:first-child th:hover [class*='icon'] {
      color: var(--mainColor); }
  .bootstrap-datetimepicker-widget table td.day {
    height: 24px;
    line-height: 24px; }
  .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"] {
    background-color: #1e76bd !important;
    color: #fff !important; }
  .bootstrap-datetimepicker-widget table td.day {
    font-size: 14px;
    height: 25px;
    line-height: 25px; }
  .bootstrap-datetimepicker-widget table td.active,
  .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #1e76bd !important; }
  .bootstrap-datetimepicker-widget table td,
  .bootstrap-datetimepicker-widget td span,
  .bootstrap-datetimepicker-widget td a span {
    height: 30px;
    line-height: 30px;
    width: 28px;
    padding: 0px; }
  .bootstrap-datetimepicker-widget .btn {
    border-radius: 5px;
    background: #f8f8f8; }
  .bootstrap-datetimepicker-widget .datepicker table {
    width: 270px; }
  .bootstrap-datetimepicker-widget a[data-action] {
    border: 0;
    width: 40px;
    height: 40px;
    padding: 0; }
  .bootstrap-datetimepicker-widget td.next,
  .bootstrap-datetimepicker-widget td.prev {
    width: 30px;
    padding: 0; }

label.number-label {
  margin: 0;
  font-size: 15px;
  line-height: 38px; }

.number-spinner {
  width: 108px;
  margin-right: 50px; }
  .number-spinner input[type='text'] {
    background: #fff;
    padding: 5px 0;
    text-align: center !important;
    width: 37px;
    border-radius: 0;
    min-height: 38px; }
  .number-spinner .input-group-btn {
    margin: 0 !important;
    width: 35px;
    height: 38px;
    background-color: #cacaca;
    cursor: pointer; }
    .number-spinner .input-group-btn:first-child {
      border-radius: 2px 0 0 2px; }
    .number-spinner .input-group-btn:last-child {
      border-radius: 0 2px 2px 0; }
    .number-spinner .input-group-btn > a {
      font-size: 12px;
      line-height: 21px;
      padding: 7px 12px;
      color: #fff; }
      .number-spinner .input-group-btn > a:hover {
        color: #fff; }
      .number-spinner .input-group-btn > a [class*='icon'] {
        font-size: 12px; }

.custom-checkbox {
  align-items: center; }

.custom-checkbox .custom-control-indicator {
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  width: 18px;
  height: 18px;
  background: #fff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background: #fff; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-indicator:after {
    position: absolute;
    margin-top: -10px;
    top: 50%;
    right: 2px;
    content: '\e906';
    font-family: 'dentco';
    font-size: 10px;
    color: #212121; }

.custom-control {
  padding-left: 30px; }

.selectWrapper {
  width: 100%;
  position: relative; }
  .selectWrapper:after {
    content: '';
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    width: 100%;
    background: #fff;
    pointer-events: none; }
  .selectWrapper:before {
    content: '\25BE';
    position: absolute;
    z-index: 2;
    right: 12px;
    top: 50%;
    margin-top: -14px;
    pointer-events: none; }
  .selectWrapper select {
    width: 100%;
    padding: 10px;
    margin-left: -20px;
    -webkit-appearance: none; }
  .selectWrapper.input-group select, .selectWrapper.input-group select:last-child {
    margin-left: -34px;
    padding-left: 35px !important; }

.reviews-photo{
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.dropdown-form,
.modal-form {
  line-height: 20px; }
  .dropdown-form .form-control,
  .modal-form .form-control {
    min-height: 41px;
    padding: 8px 12px;
    font-size: 15px;
    line-height: 23px; }
  .dropdown-form form > * + *,
  .modal-form form > * + * {
    margin-top: 8px; }
  .dropdown-form textarea,
  .modal-form textarea {
    min-height: 98px; }
  .dropdown-form .datepicker-wrap:after,
  .modal-form .datepicker-wrap:after {
    margin-top: -11px; }

form label.error {
  display: block;
  color: #ff0000;
  font-size: 13px !important;
  opacity: 1 !important;
  margin-top: -18px; }

.modal-form .modal-content .form-control + label.error {
  margin-top: 0; }

form.contact-form label.error {
  margin-top: 0; }

form.contact-form textarea + label.error {
  margin-top: 0; }



.successform,
.errorform {
  margin-top: 15px; }
  .successform p,
  .errorform p {
    margin-bottom: 0 !important; }

.successform {
  color: green; }

.errorform {
  color: #c0392b; }

.contact-form .form-control {
  font-size: 14px;
  min-height: 45px; }
.contact-form textarea.form-control {
  min-height: 155px; }
  @media (max-width: 767px) {
    .contact-form textarea.form-control {
      min-height: 100px; } }

.review-rating {
  border: none;
  float: left; }
  .review-rating > input {
    display: none; }
  .review-rating > label:before {
    margin: 0 3px 0 0;
    font-size: 22px;
    font-family: 'dentco';
    display: inline-block;
    content: "\e90f";
    transition: 0.2s; }
  .review-rating > .half:before {
    content: "\e938";
    position: absolute;
    transition: 0.2s; }
  .review-rating > label {
    color: #ddd;
    float: right; }
  .review-rating > input:checked ~ label, .review-rating:not(:checked) > label:hover, .review-rating:not(:checked) > label:hover ~ label {
    color: var(--mainColor); }
  .review-rating > input:checked + label:hover, .review-rating > input:checked ~ label:hover, .review-rating > label:hover ~ input:checked ~ label, .review-rating > input:checked ~ label:hover ~ label {
    color: var(--mainColor); }

.content-search {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 767px) {
    .content-search {
      min-width: 250px; } }
  .content-search .input-wrap {
    flex: 1; }
  .content-search .form-control {
    min-height: 43px;
    height: 43px;
    background-color: #fff;
    border-right: 0;
    border-radius: 4px 0 0 4px; }
  .content-search .form-control:focus {
    box-shadow: none; }
  .content-search button, body:not(.touch) .content-search button:focus {
    margin-left: -1px;
    min-height: 43px;
    height: 43px;
    font-size: 22px;
    line-height: 23px;
    padding: 10px 10px;
    background: transparent;
    color: var(--mainColor);
    border-radius: 0 7px 7px 0;
    border: 1px solid #e5e5e5;
    border-left: 0;
    overflow: visible; }
    .content-search button:hover, body:not(.touch) .content-search button:focus:hover {
      background: var(--mainColor);
      color: #fff; }
  .content-search.content-search--style2 .form-control {
    font-size: 16px;
    font-weight: 600; }
  .content-search.content-search--style2 ::-webkit-input-placeholder {
    color: #787878 !important; }
  .content-search.content-search--style2 ::-moz-placeholder {
    color: #787878 !important; }
  .content-search.content-search--style2 :-ms-input-placeholder {
    color: #787878 !important; }
  .content-search.content-search--style2 :-moz-placeholder {
    color: #787878 !important; }

.title-wrap + .content-search {
  margin-top: 40px; }
  @media (max-width: 767px) {
    .title-wrap + .content-search {
      margin-top: 20px; } }

select [disabled="disabled"] {
  color: red; }

.select-wrapper {
  position: relative; }
  .select-wrapper:before {
    content: '\25BE';
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 37px;
    height: 37px;
    font-size: 14px;
    line-height: 37px;
    pointer-events: none;
    color: #787878;
    text-align: center; }
  .select-wrapper select {
    height: 39px;
    width: 100%;
    width: 100%;
    padding: 0 40px 0 15px;
    background-color: #fff;
    font-size: 14px;
    color: #2b2b2b;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #d5d5d5;
    border-radius: 6px; }

::-webkit-input-placeholder {
  color: #a9a9a9 !important; }

:-ms-input-placeholder {
  color: #a9a9a9 !important; }

::-moz-placeholder {
  color: #a9a9a9 !important;
  opacity: 1; }

:-moz-placeholder {
  color: #a9a9a9 !important;
  opacity: 1; }

/*for IE10*/
select::-ms-expand {
  display: none; }

.filterCarousel {
  margin-bottom: 34px; }
  .filterCarousel:not(:first-child) {
    margin-top: 40px; }
    @media (max-width: 1023px) {
      .filterCarousel:not(:first-child) {
        margin-top: 25px; } }
  @media (max-width: 1023px) {
    .filterCarousel {
      margin-bottom: 20px; } }
  @media (max-width: 575px) {
    .filterCarousel {
      margin-bottom: 25px; } }
  .filterCarousel .selectWrapper {
    max-width: 230px;
    margin-left: auto;
    margin-right: auto; }
  .filterCarousel .selectWrapper.input-group select {
    margin-left: 0 !important;
    padding-left: 15px !important; }

.form-container {
  margin: 0 auto;
  max-width: 627px; }
  .form-container:not(:first-child) {
    margin-top: 35px; }
  .form-container .selectWrapper select {
    margin-left: 0; }
  .form-container .selectWrapper:after {
    z-index: -1; }
  .form-container h5 {
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px; }
    .form-container h5:not(:first-child) {
      margin-top: 15px; }
  .form-container .form-row + .form-row {
    margin-top: 12px; }
  .form-container .form-row-info {
    align-items: center; }
  .form-container .col-td-1, .form-container .col-td-3 {
    font-size: 14px;
    line-height: 24px;
    color: #a9a9a9; }
    @media (max-width: 575px) {
      .form-container .col-td-1, .form-container .col-td-3 {
        font-size: 12px;
        line-height: 18px; } }
  .form-container .col-td-2 {
    font-size: 16px;
    line-height: 24px;
    color: #424242; }
  .form-container .col-td-1 {
    flex: 0 0 145px;
    min-width: 145px; }
  .form-container .col-td-2 {
    flex: 0 0 65px;
    min-width: 65px; }
  @media (max-width: 575px) {
    .form-container .form-row {
      flex-wrap: wrap; }
    .form-container .col-td-3 {
      flex: 1;
      min-width: 100%; } }
  .form-container .custom-control-indicator {
    top: 0.1rem; }
  .form-container .custom-control {
    margin: 0 auto;
    max-width: 570px; }
  .form-container .custom-control-description {
    font-size: 14px;
    line-height: 20px;
    color: #a9a9a9; }
    @media (max-width: 575px) {
      .form-container .custom-control-description {
        font-size: 12px;
        line-height: 18px; } }
  .form-container .input-with-image-wrap {
    position: relative; }
    .form-container .input-with-image-wrap .input-images {
      position: absolute;
      right: 15px;
      top: 6px; }
    .form-container .input-with-image-wrap.w1 input {
      padding-right: 130px; }
    .form-container .input-with-image-wrap.w2 input {
      padding-right: 50px; }
  .form-container button[type='submit'] {
    min-width: 143px; }
  @media (max-width: 575px) {
    .form-container .col-sm + .col-sm {
      margin-top: 12px; } }

.download-block {
  display: flex;
  position: relative;
  padding-bottom: 40px; }
  .download-block:before {
    content: '';
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 1px;
    top: 0;
    left: 35px;
    background-color: #e5e5e5; }
  .download-block:last-child {
    padding-bottom: 0; }
    .download-block:last-child:before {
      display: none; }
  .download-block-text {
    padding-top: 8px; }
  .download-block-icon {
    position: relative;
    z-index: 1;
    flex: 0 0 120px;
    min-width: 120px; }
    @media (max-width: 575px) {
      .download-block-icon {
        flex: 0 0 80px;
        min-width: 80px; } }
    .download-block-icon [class*='icon'] {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      font-size: 32px;
      background-color: var(--mainColor);
      border-radius: 50%;
      color: #ffffff; }
      @media (max-width: 575px) {
        .download-block-icon [class*='icon'] {
          width: 62px;
          height: 62px;
          font-size: 28px; } }
  .download-block a:hover {
    text-decoration: none; }

body.is-loaded .loading-content {
  opacity: 0;
  visibility: hidden; }

.loading-content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 200;
  background-color: #00000062;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  visibility: visible;
  transition: 0.5s; }
  .loading-content.disable {
    opacity: 0; }

.inner-circles-loader:not(:required) {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  overflow: hidden;
  text-indent: -9999px;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); }

.inner-circles-loader:not(:required):before,
.inner-circles-loader:not(:required):after {
  content: '';
  position: absolute;
  top: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%; }

.inner-circles-loader:not(:required):before {
  -moz-animation: inner-circles-loader 3s infinite;
  -webkit-animation: inner-circles-loader 3s infinite;
  animation: inner-circles-loader 3s infinite;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  left: 0;
  background: var(--mainColor); }

.inner-circles-loader:not(:required):after {
  -moz-animation: inner-circles-loader 3s 0.2s reverse infinite;
  -webkit-animation: inner-circles-loader 3s 0.2s reverse infinite;
  animation: inner-circles-loader 3s 0.2s reverse infinite;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  right: 0;
  background: #e0eefa; }

@-moz-keyframes inner-circles-loader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); }
  100% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); } }
@-webkit-keyframes inner-circles-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }
@keyframes inner-circles-loader {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  100% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }
.loader-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--mainColor); }

.loader-circle:before {
  content: "";
  display: block;
  width: 70px;
  height: 35px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background-color: #000000;
  opacity: .35;
  transform-origin: bottom center;
  animation: loader-animate 1.5s linear infinite;
  -webkit-animation: loader-animate 1.5s linear infinite; }

@keyframes loader-animate {
  0% {
    transform: rotateX(0deg); }
  70% {
    background-color: var(--mainColor); }
  70%, 100% {
    transform: rotateX(360deg); }
  100% {
    background: var(--mainColor); } }
@-webkit-keyframes loader-animate {
  0% {
    transform: rotateX(0deg); }
  70%, 100% {
    transform: rotateX(360deg);
    background-color: #e0eefa; } }
.loader-circle:after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: transparent;
  border: 7px solid #ddd;
  border-right: 7px solid #f37f1d;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  animation: loader-before-animate 1.5s linear infinite;
  -webkit-animation: loader-before-animate 1.5s linear infinite 1.5s linear;
  transform-origin: center; }

@keyframes loader-before-animate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
@-webkit-keyframes loader-before-animate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
@-webkit-keyframes molecule-animation {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0); } }
@keyframes molecule-animation {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(60px, 0, 0);
    transform: translate3d(60px, 0, 0); } }
@-webkit-keyframes connection-animation {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  to {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1); } }
@keyframes connection-animation {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  to {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1); } }
.loader-dna {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  width: 70px;
  position: relative; }
  .loader-dna column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column;
    width: 10px; }
  .loader-dna column:nth-of-type(3) {
    position: absolute;
    left: 0; }
  .loader-dna dot {
    display: block;
    width: 10px;
    height: 10px;
    margin-bottom: 10px;
    border-radius: 50%;
    background-color: var(--mainColor);
    -webkit-animation-name: molecule-animation;
    animation-name: molecule-animation;
    -webkit-animation-duration: 1400ms;
    animation-duration: 1400ms; }
  .loader-dna column:nth-of-type(1) dot {
    background-color: #5ca8e5;
    -webkit-animation-direction: alternate, normal;
    animation-direction: alternate, normal; }
  .loader-dna column:nth-of-type(3) dot {
    background-color: var(--mainColor);
    -webkit-animation-direction: alternate-reverse, normal;
    animation-direction: alternate-reverse, normal; }
  .loader-dna dash {
    display: block;
    height: 3.3333333333px;
    width: 40px;
    margin-top: 3.3333333333px;
    margin-bottom: 13.3333333333px;
    margin-left: 5px;
    border-radius: 1.6666666667px;
    background-color: silver;
    -webkit-animation-name: connection-animation;
    animation-name: connection-animation;
    -webkit-animation-duration: 700ms;
    animation-duration: 700ms;
    -webkit-animation-direction: alternate;
    animation-direction: alternate; }
  .loader-dna dot,
  .loader-dna dash {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  .loader-dna dot:nth-of-type(1),
  .loader-dna dash:nth-of-type(1) {
    -webkit-animation-delay: -175ms;
    animation-delay: -175ms; }
  .loader-dna dot:nth-of-type(2),
  .loader-dna dash:nth-of-type(2) {
    -webkit-animation-delay: -350ms;
    animation-delay: -350ms; }
  .loader-dna dot:nth-of-type(3),
  .loader-dna dash:nth-of-type(3) {
    -webkit-animation-delay: -525ms;
    animation-delay: -525ms; }
  .loader-dna dot:nth-of-type(4),
  .loader-dna dash:nth-of-type(4) {
    -webkit-animation-delay: -700ms;
    animation-delay: -700ms; }
  .loader-dna dot:nth-of-type(5),
  .loader-dna dash:nth-of-type(5) {
    -webkit-animation-delay: -875ms;
    animation-delay: -875ms; }
  .loader-dna dot:nth-of-type(6),
  .loader-dna dash:nth-of-type(6) {
    -webkit-animation-delay: -1050ms;
    animation-delay: -1050ms; }
  .loader-dna dot:nth-of-type(7),
  .loader-dna dash:nth-of-type(7) {
    -webkit-animation-delay: -1225ms;
    animation-delay: -1225ms; }
  .loader-dna dot:nth-of-type(8),
  .loader-dna dash:nth-of-type(8) {
    -webkit-animation-delay: -1400ms;
    animation-delay: -1400ms; }

@media (max-width: 1229px) {
  body, html {
    overflow-x: hidden; } }
body.noScroll {
  overflow: hidden; }

#overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 1s 0.4s; }

#progress {
  height: 1px;
  background: #fff;
  position: absolute;
  width: 0;
  top: 50%; }

#progstat {
  font-size: 0.7em;
  letter-spacing: 3px;
  position: absolute;
  top: 50%;
  margin-top: -40px;
  width: 100%;
  text-align: center;
  color: #fff; }

.container-fluid.px-0 {
  overflow-x: hidden;
  overflow-y: hidden; }

.stuck {
  position: fixed;
  top: 0;
  z-index: 1030; }

@media (min-width: 1024px) {
  .modal-open .stuck {
    padding-right: 17px; } }
.page-content .section {
  position: relative;
  margin-top: 140px; }

@media (min-width: 1024px) {
  .page-content .mt-lg-large {
    position: relative;
    margin-top: 196px; } }
.page-content .section-top-padding {
  padding-top: 140px;
  margin-top: 0; }

.page-content .section-bottom-padding {
  padding-bottom: 140px; }

.page-content > .section:first-child,
.page-content .section-booking:first-child + .section {
  margin-top: 100px; }

.page-content .page-content-first {
  margin-top: 92px; }

@media (max-width: 1229px) {
  .page-content .section {
    margin-top: 80px; }

  .page-content .section-bottom-padding {
    padding-bottom: 80px; }

  .page-content .section-top-padding {
    padding-top: 80px;
    margin-top: 0; }

  .page-content > .section:first-child,
  .page-content .section-booking:first-child + .section {
    margin-top: 60px; }

  .page-content .page-content-first {
    margin-top: 60px; } }
@media (max-width: 1023px) {
  .page-content .section {
    margin-top: 70px; }

  .page-content .section-bottom-padding {
    padding-bottom: 70px; }

  .page-content .section-top-padding {
    padding-top: 70px;
    margin-top: 0; }

  .page-content > .section:first-child,
  .page-content .section-booking:first-child + .section {
    margin-top: 50px; }

  .page-content .page-content-first {
    margin-top: 50px; } }
@media (max-width: 767px) {
  .page-content .section {
    margin-top: 40px; }

  .page-content .section-bottom-padding {
    padding-bottom: 0; }

  .page-content .section-top-padding {
    padding-top: 0;
    margin-top: 40px; }

  .page-content > .section:first-child,
  .page-content .section-booking:first-child + .section {
    margin-top: 30px; }

  .page-content .page-content-first {
    margin-top: 30px; } }
.full-bg-grey {
  position: relative;
  padding: 75px 0;
  background-color: #e5e5e5;
  overflow: hidden; }
  @media (max-width: 1023px) {
    .full-bg-grey {
      padding: 50px 0; } }

.bg-grey {
  padding: 100px 0;
  background: var(--grayBack); 
}
  @media (max-width: 1023px) {
    .bg-grey {
      padding: 50px 0; } }

.bg-grey-dark {
  background: url("../images/bg-grey-dark.jpg"); }

.bg-grey-lg {
  padding: 140px 0;
  background-color: #f6f7f5; }
  @media (max-width: 1229px) {
    .bg-grey-lg {
      padding: 80px 0; } }
  @media (max-width: 1023px) {
    .bg-grey-lg {
      padding: 70px 0; } }
  @media (max-width: 767px) {
    .bg-grey-lg {
      padding: 70px 0; } }

.bg-grey-1 {
  padding: 50px 0;
  background-color: #e5e5e5; }
  @media (max-width: 1023px) {
    .bg-grey-1 {
      padding: 30px 0; } }

.half-col > [class*='col'] {
  position: static; }

.half-col > .col-text:first-child {
  padding-right: 70px; }
  @media (min-width: 1024px) and (max-width: 1229px) {
    .half-col > .col-text:first-child {
      padding-left: 40px;
      padding-right: 40px; } }

.half-col > .col-text:last-child {
  padding-left: 70px; }
  @media (min-width: 1024px) and (max-width: 1229px) {
    .half-col > .col-text:last-child {
      padding-left: 40px;
      padding-right: 40px; } }

.half-col .image-caption {
  position: absolute;
  width: 35%;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  z-index: 1;
  font-size: 60px;
  line-height: 1em;
  color: #fff;
  font-family: var(--fontFamily); }
  @media (min-width: 1024px) and (max-width: 1229px) {
    .half-col .image-caption {
      width: calc(60% - 135px); } }

.half-col > .col-img:first-child .image-caption {
  right: 105px; }

.half-col > .col-img:last-child .image-caption {
  padding-left: 105px; }

.half-col .img-cover-right,
.half-col .img-cover-left {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-position: center;
  background-size: cover; }

.half-col .img-cover-right {
  right: 0;
  left: auto; }

@media (max-width: 1023px) {
  .half-col > .col-text:first-child {
    padding-right: 0; }

  .half-col > .col-text:last-child {
    padding-left: 0; }

  .half-col .image-caption {
    position: relative;
    width: 100%;
    font-size: 50px;
    line-height: 60px;
    min-height: 200px; }

  .half-col > [class*='col'] {
    position: relative; }

  .half-col > .col-text {
    padding: 0 55px 40px; }

  .half-col > .col-text h3 {
    font-size: 32px; }

  .half-col > .col-img {
    padding: 60px 0;
    margin: 0 -15px -50px;
    flex: 0 0 calc(100% + 30px);
    max-width: calc(100% + 30px); }

  .half-col .img-cover-right,
  .half-col .img-cover-left {
    width: 100%; }

  .half-col > .col-img:first-child .image-caption {
    right: 82px; }

  .half-col > .col-img:last-child .image-caption {
    padding-left: 82px; } }
@media (max-width: 767px) {
  .half-col .image-caption {
    font-size: 32px;
    line-height: 44px; }

  .half-col > .col-img:first-child .image-caption {
    right: 15px; }

  .half-col > .col-img:last-child .image-caption {
    padding-left: 15px; }

  .half-col > .col-text {
    padding: 0 0 40px; }

  .half-col > .col-img {
    padding: 60px 0; }

  .half-col .image-caption {
    align-items: flex-start; } }
.vert-wrap {
  display: table;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1; }

.vert {
  display: table-cell;
  vertical-align: middle; }

.col-20 {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-md-space {
  margin-left: -10px;
  margin-right: -10px; }
  .row.row-md-space [class*='col'] {
    padding-left: 10px;
    padding-right: 10px; }

.row.row-sm-space {
  margin-left: -8px;
  margin-right: -8px; }
  .row.row-sm-space [class*='col'] {
    padding-left: 8px;
    padding-right: 8px; }

.row.row-xs-space {
  margin-left: -4px;
  margin-right: -4px; }
  .row.row-xs-space [class*='col'] {
    padding-left: 4px;
    padding-right: 4px; }

.row.col-equalH {
  margin-top: -30px; }
  .row.col-equalH [class*='col'] {
    display: flex;
    margin-top: 30px; }

.row.slick-slider {
  display: block; 
    margin-bottom: 120px;
}
  .row.slick-slider [class*='col'] {
    display: block; }

.aside {
  padding-right: 30px; }
  @media (max-width: 1023px) {
    .aside {
      padding-right: 15px; } }


.video-wrap:after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  pointer-events: none; }

.shadow-bot {
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.02); }

.shadow-all {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05); }

.max-575 {
  max-width: 575px !important; }

.max-670 {
  max-width: 670px !important; }

.max-730 {
  max-width: 730px !important; }

.max-900 {
  max-width: 900px !important;
  margin-left: auto;
  margin-right: auto; }

.max-500 {
  max-width: 520px !important;
  margin-left: auto;
  margin-right: auto; }

.max-600 {
  max-width: 600px !important;
  margin-left: auto;
  margin-right: auto; }

.container-shift-left {
  margin-left: calc(100% - 570px); }
  @media (max-width: 1229px) {
    .container-shift-left {
      margin: 0;
      padding: 0 15px; } }

.container-shift-right {
  margin-right: calc(100% - 570px); }
  @media (max-width: 1229px) {
    .container-shift-right {
      margin: 0;
      padding: 0 15px; } }

.image-shift-right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  margin-right: -25px; }
  @media (max-width: 1023px) {
    .image-shift-right {
      margin: 0; } }
  @media (max-width: 767px) {
    .image-shift-right {
      display: block; } }

.image-shift-left {
  position: relative;
  z-index: 1;
  margin-left: -50px; }

@media (max-width: 575px) {
  img.w-xs-100 {
    width: 100%; } }
@media (max-width: 767px) {
  img.w-sm-100 {
    width: 100%; } }
@media (max-width: 1023px) {
  img.w-md-100 {
    width: 100%; } }
.col-shift-right {
  background: url("../images/bg-grey.jpg") #f6f7f5; }

@media (min-width: 1230px) {
  .row-shift {
    flex-wrap: nowrap; }

  .col-shift-right {
    flex: 0 0 calc(50% + 70px);
    max-width: calc(50% + 70px);
    padding: 70px 0; } }
  @media (min-width: 1230px) and (max-width: 1023px) {
    .col-shift-right {
      padding: 50px 0; } }
@media (min-width: 1230px) {
    .col-shift-right .container-shift-left {
      margin-left: calc(100% - 640px); }

  .col-shift-left {
    margin-left: -55px; }
    .col-shift-left .container-shift-right {
      padding-left: 135px; } }
.col-shift-right {
  padding: 50px 0; }

.header {
  color: #787878; }

.header:not(.off-shadow) {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1); }

.header-topline {
  display: flex;
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: .5px;
  word-spacing: -2px;
  align-items: center;
  min-height: 48px;
  background: var(--headerColor); 
}
  .header-topline,
  .header-topline a {
    color: var(--headerFontColor);
}
  .header-topline [class*='icon-'] {
    position: relative;
    top: 2px;
    font-size: 16px;
    padding-right: 8px;
    color: var(--headerFontColor); 
}

.header-info:hover [class*='icon-'] {
  color: #fff; 
}

.header-info + *,
.header-phone + * {
  position: relative;
  margin-left: 29px;
  padding-left: 30px; }
  .header-info + *:before,
  .header-phone + *:before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 1px;
    height: 22px;
    background-color: rgba(255, 255, 255, 0.2); }


.header-phone [class*='icon-'] {
  font-size: 16px;
  top: 2px;
  color: var(--headerFontColor); }

.header-social:not(:first-child) {
  margin-left: 6px; }
.header-social a {
  display: inline-block;
  margin: 0 -4px 0 0;
  padding: 0;
  text-decoration: none;
  position: relative;
  border-radius: 50%; }
  .header-social a:hover {
    text-decoration: none; }
    .header-social a:hover [class*='icon-'] {
      opacity: .5; }
  .header-social a:before, .header-social a:last-child:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.2); }
  .header-social a:last-child:after {
    right: 0;
    left: auto; }
.header-social [class*='icon-'] {
  display: block;
  position: relative;
  height: 48px;
  padding: 0 13px;
  z-index: 1;
  font-size: 22px;
  line-height: 48px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  transition: all .2s;
  border-radius: 50%; }

@media (max-width: 1023px) {
  .header-topline {
    background-color: #22201f;
    color: #fff;
    min-height: 36px;
    max-width: 100%; }
    .header-topline > .container > .row > *:last-child {
      padding: 0; }
    .header-topline .dropdown-toggle:after {
      content: none; }

  .header-topline.active .header-toggle-slide {
    border-color: transparent; }

  .header-info span,
  .header-info [class*='icon-'] {
    color: #fff; }

  .header-toggle-slide {
    display: inline-block;
    padding: 0 15px;
    color: #fff;
    border-left: 1px solid #3c3c3c;
    font-size: 14px;
    line-height: 36px; }
    .header-toggle-slide [class*='icon-'] {
      font-size: 15px; }
    .header-toggle-slide:hover {
      color: #fff;
      text-decoration: none;
      opacity: .75; }
    .header-toggle-slide.active {
      background-color: var(--mainColor);
      border-color: transparent; }

  .header-toggle-slide span {
    padding-left: 10px; } }
.header-logo, .header-logo img {
  max-width: 205px;
  max-height: 60px; }

.header-content.stuck .header-logo, .header-content.stuck .header-logo img {
  max-height: 40px; }

@media (max-width: 1023px) {
  .header-logo, .header-logo img {
    max-width: 175px; } }
@media (max-width: 1023px) {
  .header-logo, .header-logo img {
    max-height: 46px; 
    height: 46px;
    object-fit: contain;
} 
}
@media (max-width: 767px) {
  .header-logo, .header-logo img {
    max-height: 30px; 
    height: 30px;
} }
.header-contact {
  font-size: 16px;
  line-height: 1.6em;
  position: relative;
  top: 5px; }
  @media (max-width: 1229px) {
    .header-contact {
      font-size: 15px; } }
  .header-contact .txt-sm {
    font-size: 14px; }
  .header-contact .txt-lg {
    font-size: 22px; }
  .header-contact span {
    color: var(--mainColor); }
  .header-contact [class*='icon-'] {
    position: relative;
    top: 1px;
    width: 28px;
    font-size: 22px;
    color: var(--mainColor); }
  .header-contact-icon {
    min-width: 28px; }
  .header-contact-txt > * {
    margin-bottom: 0; }
  .header-contact-txt > * + * {
    margin-top: 5px; }

.header-contact + * {
  margin-top: 5px; }

.header-content {
  position: relative;
  padding-top: 22px;
  padding-bottom: 22px; 
    background-color: var(--menuColor);
}
  @media (max-width: 1229px) {
    .header-content {
      padding-top: 30.81232493px;
      padding-bottom: 30.81232493px; } }

.header-content.stuck {
  position: fixed;
  padding-top: 9px;
  padding-bottom: 9px;
  background: #fff;
  width: 100%;
  max-width: 100vw;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1); }
  .header-content.stuck .catalog-widget {
    display: none !important; }

@media (max-width: 1229px) {
  .header-content {
    padding-top: 20px;
    padding-bottom: 20px; } }
@media (max-width: 1023px) {
  .header-content {
    padding-top: 15px;
    padding-bottom: 15px; } }
@media (max-width: 767px) {
  .header-content {
    padding-top: 9px;
    padding-bottom: 9px; }

  .header-content.stuck {
    padding-top: 9px;
    padding-bottom: 9px; } }
.header-nav-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.header-nav {
  width: 100%; }
  .header-nav .navbar {
    padding: 0;
    margin: 0; }
  .header-nav .navbar-nav {
    flex-wrap: wrap;
    justify-content: flex-end; }
  .header-nav .nav-item {
    padding: 0 15px; }
    @media (max-width: 1229px) {
      .header-nav .nav-item {
        padding: 0 7px; } }
  .header-nav .nav-item > .nav-link {
    position: relative;
    padding: 0;
    color: var(--menuFontColor);
    font-size: 15px;
    line-height: 52px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: var(--fontFamily);
    background: transparent;
    border: 0;
    transition: 0.3s; }
    @media (max-width: 1229px) {
      .header-nav .nav-item > .nav-link {
        font-size: 14px; } }
    .header-nav .nav-item > .nav-link:hover {
      color: var(--mainColor);
      transition: 0.3s; }
  .header-nav .nav-item.active > .nav-link {
    color: var(--mainColor); }
  .header-nav .nav-item:last-child {
    padding-right: 0; }

.header-quickLinks {
  position: relative;
  z-index: 1001;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden; }
  .header-quickLinks .quickLinks-top {
    position: relative;
    z-index: 2; }
    .header-quickLinks .quickLinks-top + * {
      position: relative;
      z-index: 1; }

.navbar-toggler {
  display: none;
  font-size: 26px;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  z-index: 1; }
  @media (max-width: 767px) {
    .navbar-toggler {
        width: fit-content;
      font-size: 18px;
      right: 0; } }
  .navbar-toggler:not(.collapsed) {
    font-size: 28px; }
    @media (max-width: 767px) {
      .navbar-toggler:not(.collapsed) {
        font-size: 18px; } }
    .navbar-toggler:not(.collapsed) .icon-menu:before {
      content: '\e937';
      position: relative;
      top: -2px; }

.header-content.stuck .navbar-toggler {
  margin-top: -10px; }

@media (max-width: 1023px) {
  .header-content.stuck .navbar-toggler {
    margin-top: 0;
    top: 50% !important;
    transform: translateY(-50%); } }
@media (min-width: 1024px) {
  .header .nav-item {
    position: relative; }

  .header .navbar-nav > .nav-item > .dropdown-menu, .dropdown-item + .dropdown-menu {
    display: block !important;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: -20px;
    pointer-events: none;
    opacity: 0;
    min-width: 230px;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    text-align: left;
    transform: translateX(-20px);
    transition: 0s ease 0s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15) !important;
    border: 0;
    border-top: 2px solid var(--mainColor);
    border-radius: 0; }
    .header .navbar-nav > .nav-item > .dropdown-menu a, .dropdown-item + .dropdown-menu a {
      color: #444;
      font-size: 15px;
      line-height: 26px;
      font-weight: 500;
      font-family: var(--fontFamily);
      background-color: transparent;
      padding: 7px 20px; }
      .header .navbar-nav > .nav-item > .dropdown-menu a.dropdown-toggle, .dropdown-item + .dropdown-menu a.dropdown-toggle {
        padding-right: 40px; }
      .header .navbar-nav > .nav-item > .dropdown-menu a:hover, .dropdown-item + .dropdown-menu a:hover {
        color: var(--mainColor); } }
    @media (min-width: 1024px) and (max-width: 1229px) {
      .header .navbar-nav > .nav-item > .dropdown-menu a, .dropdown-item + .dropdown-menu a {
        font-size: 16px; } }

@media (min-width: 1024px) {
  .header .navbar-nav > .nav-item:hover > .dropdown-menu,
  .header .navbar-nav .dropdown-item:hover + .dropdown-menu, .header .navbar-nav .dropdown-item + .dropdown-menu:hover {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateX(0);
    transition: 0.5s ease 0.1s; }

  .header .navbar-nav > .nav-item:hover > .dropdown-menu {
    transform: translateX(9px); }

  .btco-hover-menu .active a,
  .btco-hover-menu .active a:focus,
  .btco-hover-menu .active a:hover,
  .btco-hover-menu li a:hover,
  .btco-hover-menu li a:focus,
  .navbar > .show > a,
  .navbar > .show > a:focus,
  .navbar > .show > a:hover {
    color: #000;
    background: transparent;
    outline: 0; }

  .dropdown-menu {
    padding: 0px 0;
    margin: 0 0 0;
    border: 0px solid transition !important;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

  .btco-hover-menu .collapse ul ul,
  .btco-hover-menu .collapse ul ul.dropdown-menu {
    background: #fff; }

  .btco-hover-menu .collapse ul ul ul,
  .btco-hover-menu .collapse ul ul ul.dropdown-menu {
    background: #f8f8f8; }

  .btco-hover-menu .collapse ul ul ul ul,
  .btco-hover-menu .collapse ul ul ul ul.dropdown-menu {
    background: #f5f5f5; }

  .btco-hover-menu {
    background: none;
    margin: 0;
    padding: 0;
    min-height: 20px; }

  .btco-hover-menu .collapse ul li {
    position: relative; }

  .btco-hover-menu .collapse ul li:hover > ul {
    display: block; }

  .btco-hover-menu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 250px;
    display: none; }

  .btco-hover-menu .collapse ul ul li {
    position: relative; }

  .btco-hover-menu .collapse ul ul li:hover > ul {
    display: block; }

  .btco-hover-menu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none; }

  .btco-hover-menu .collapse ul ul ul li {
    position: relative; }

  .btco-hover-menu .collapse ul ul ul li:hover ul {
    display: block; }

  .btco-hover-menu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: auto;
    right: 100%;
    min-width: 250px;
    display: none;
    z-index: 1;
    transform: translateX(10px) !important; }

  .btco-hover-menu .collapse ul ul ul li:hover ul {
    transform: translateX(0) !important; }

  .btco-hover-menu .dropdown-menu .dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 25px;
    margin: 0;
    height: auto;
    content: '\e930';
    font-family: 'dentco';
    font-size: 8px;
    transform: translateY(-50%);
    color: var(--mainColor);
    transform: translateY(-50%);
    border: 0; } }
.btco-hover-menu .nav-item > .dropdown-toggle:after {
  border: 0; }

@media (max-width: 1023px) {
  .navbar-toggler {
    display: block; 
    width: fit-content;
    right: 0;
}

  .navbar {
    justify-content: flex-end;
    margin-top: -30px; }
    .navbar .dropdown-menu {
      border: 0;
      box-shadow: 0 !important; }

  .nav-item > .nav-link {
    cursor: pointer; }

  .nav-item > .nav-link:before {
    display: none; }

  .navbar-collapse {
    padding: 0 25px 10px;
    background: #fff;
    overflow-y: auto; }
    .navbar-collapse .nav-item > .nav-link {
      font-size: 16px;
      line-height: 24px;
      padding-top: 10px;
      padding-bottom: 10px; }
    .navbar-collapse .nav-item > .nav-link.dropdown-toggle {
      padding-right: 40px; }
    .navbar-collapse .navbar-nav {
      padding-top: 25px;
      width: calc(100vw - 70px); }

  .header .dropdown {
    max-width: 100%; }

  .header .dropdown-menu {
    padding: unset; }
    .header .dropdown-menu > li > a {
      padding-left: 20px; }
    .header .dropdown-menu ul > li > a {
      padding-left: 40px; }
    .header .dropdown-menu ul ul > li > a {
      padding-left: 60px; }
    .header .dropdown-menu ul ul > li > a {
      padding-left: 80px; }

  .header .dropdown-item {
    padding: 0;
    position: relative;
    color: #444;
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-family: var(--fontFamily);
    white-space: normal; }
    .header .dropdown-item:hover, .header .dropdown-item:focus {
      color: var(--mainColor);
      background: transparent; }
    .header .dropdown-item.dropdown-toggle {
      padding-right: 40px; }
    .header .dropdown-item.dropdown-toggle:after {
      position: absolute;
      top: 50%;
      right: 25px;
      margin: 0;
      height: auto;
      content: '\e930';
      font-family: 'dentco';
      font-size: 12px;
      transform: translateY(-50%);
      color: var(--mainColor);
      transform: translateY(-50%);
      border: 0; }

  .header-nav .nav-item {
    padding: 0; }

  .header .navbar-nav li {
    border-top: 1px solid #f5f5f5; }

  .header .navbar-nav > li:first-child {
    border: 0; }

  .header .dropdown-item.dropdown-toggle:after, .nav-link.dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 15px;
    margin: 0;
    height: auto;
    content: '\e930';
    font-family: 'dentco';
    font-size: 12px;
    transform: translateY(-50%);
    color: var(--mainColor);
    transform: translateY(-50%);
    border: 0;
    pointer-events: none; } }
@media (max-width: 767px) {
  .header-logo {
    width: 120px; }

  .navbar-collapse {
    padding: 0 0 10px; }
    .navbar-collapse .nav-item > .nav-link, .navbar-collapse .dropdown-item {
      font-size: 14px;
      line-height: 20px;
      padding-top: 8px;
      padding-bottom: 8px; }

  .navbar-collapse .navbar-nav {
    width: calc(100vw - 30px); } }
@media (min-width: 1024px) {
  .header-mobile-info, .header-mobile-top {
    display: none; } }
@media (max-width: 1229px) {
  .header-mobile-top {
    background-color: var(--mainColor); }
    .header-mobile-top .row {
      height: 44px; }

  .header-mobile-info-toggle {
    color: #fff;
    cursor: pointer; }
    .header-mobile-info-toggle:before {
      display: inline-block;
      font-family: 'dentco';
      content: '\e931';
      font-size: 16px;
      line-height: 44px;
      position: relative;
      transition: all 0.2s ease-out; }
    .header-mobile-info-toggle.opened:before {
      transform: rotate(180deg); }

  .header-mobile-info-content {
    display: none;
    padding: 15px;
    background-color: #fff; }
    .header-mobile-info-content .social-list {
      margin-bottom: 0; }
    .header-mobile-info-content .social-list a {
      font-size: 20px;
      line-height: 20px; }

  .header-mobile-info-content.opened {
    display: block; }

  .header-button-wrap .btn {
    background-color: #fff !important;
    color: #424242 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    height: 30px !important;
    padding: 7px 15px !important; }
    .header-button-wrap .btn [class*='icon-'] {
      top: 1px !important; } }
.header-list-info {
  font-size: 15px;
  line-height: 24px;
  padding-left: 28px;
  position: relative;
  color: #677d8f; }
  .header-list-info:not(:first-child) {
    margin-top: 10px; }
  .header-list-info a {
    color: #677d8f; }
  .header-list-info .icon {
    position: absolute;
    left: 0;
    top: 4px;
    font-size: 16px; }
    .header-list-info .icon.icon-speech-bubble {
      top: 6px; }

.header-cart {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px; }
  .header-cart a.icon {
    font-size: 20px;
    text-decoration: none;
    color: #444; }
    @media (max-width: 1023px) {
      .header-cart a.icon {
        font-size: 28px; } }
    @media (max-width: 767px) {
      .header-cart a.icon {
        font-size: 18px; } }
  .header-cart .badge {
    padding: 0;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-size: 10px;
    position: absolute;
    right: 2px;
    top: -6px;
    background-color: var(--mainColor);
    color: #fff;
    transition: .2s;
    border-radius: 50%; }
  .header-cart:hover a.icon, .header-cart.opened a.icon {
    color: var(--mainColor); }
  .header-cart:hover .badge, .header-cart.opened .badge {
    background-color: var(--mainColor); }
  @media (min-width: 1024px) {
    .header-cart {
      top: 0 !important; } }
  @media (max-width: 1023px) {
    .header-cart {
      position: absolute;
      right: 67px;
      top: 25px;
      margin-top: 5px; } }
  @media (max-width: 767px) {
    .header-cart {
      top: 7px;
      right: 45px; } }

@media (max-width: 1023px) {
  .has-lang .header-cart {
    right: 109px; } }
@media (max-width: 767px) {
  .has-lang .header-cart {
    right: 75px; } }

.header-content.stuck .header-cart {
  margin-top: 2px; }

@media (max-width: 1229px) {
  .header-content.stuck .header-cart {
    margin-top: 0; } }
@media (max-width: 1023px) {
  .header-content.stuck .header-cart {
    margin-top: 0;
    top: 50% !important;
    transform: translateY(-50%); } }
.header-cart-dropdown {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: -5000px;
  width: 350px;
  padding: 25px 22px 22px;
  background: #fff;
  display: block;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(20px);
  text-align: left; }
  @media (max-width: 575px) {
    .header-cart-dropdown {
      width: 270px;
      padding: 15px 15px;
      right: -35px; } }
  .header-cart-dropdown .header-cart-total {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    border-top: 1px solid #e8e8e8;
    padding: 20px 0;
    overflow: hidden; }
  .header-cart-dropdown .prd-sm {
    margin-bottom: 25px; }
  .header-cart-dropdown .prd-sm-info h3, .header-cart-dropdown .prd-sm-info h3 a {
    color: #000 !important; }
  .header-cart-dropdown .prd-sm-info h3 a:hover {
    color: var(--mainColor); }
  .header-cart-dropdown .prd-sm-info .price {
    color: var(--mainColor); }

.header-cart.opened .header-cart-dropdown {
  transform: translateY(10px);
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
  opacity: 1;
  visibility: visible;
  top: 100%;
  margin-top: 3px; }

.header-lang {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 14px;
  padding-right: 0; }
  .header-lang a.icon {
    font-size: 19px;
    text-decoration: none;
    color: #444; }
    @media (max-width: 1023px) {
      .header-lang a.icon {
        font-size: 28px; } }
    @media (max-width: 767px) {
      .header-lang a.icon {
        font-size: 18px; } }
  .header-lang:hover a.icon, .header-lang.opened a.icon {
    color: var(--mainColor); }
  @media (min-width: 1024px) {
    .header-lang {
      top: 0 !important; } }
  @media (max-width: 1023px) {
    .header-lang {
      position: absolute;
      right: 67px;
      top: 25px;
      margin-top: 5px; } }
  @media (max-width: 767px) {
    .header-lang {
      top: 7px;
      right: 45px; } }

.header-content.stuck .header-lang {
  margin-top: 2px; }

@media (max-width: 1229px) {
  .header-content.stuck .header-lang {
    margin-top: 0; } }
@media (max-width: 1023px) {
  .header-content.stuck .header-lang {
    margin-top: 0;
    top: 50% !important;
    transform: translateY(-50%); } }
.header-lang-dropdown {
  position: absolute;
  z-index: 1000;
  left: -5px;
  top: -5000px;
  width: 220px;
  padding: 25px 30px;
  background: #fff;
  display: block;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(20px);
  text-align: left; }
  @media (max-width: 1570px) {
    .header-lang-dropdown {
      left: auto;
      right: -10px; } }
  @media (max-width: 767px) {
    .header-lang-dropdown {
      width: 170px;
      padding: 10px 15px; } }
  .header-lang-dropdown:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 60px;
    background-color: var(--mainColor); }
    @media (max-width: 1570px) {
      .header-lang-dropdown:after {
        left: auto;
        right: 0; } }
    @media (max-width: 767px) {
      .header-lang-dropdown:after {
        width: 45px; } }
  .header-lang-dropdown ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .header-lang-dropdown ul li > a {
      font-size: 15px;
      line-height: 23px;
      padding: 5px 0;
      display: flex;
      align-items: center;
      color: #787878;
      border-bottom: 1px solid  #f2f2f2;
      transition: .2s; }
      .header-lang-dropdown ul li > a span.header-lang-flag {
        position: relative;
        top: -1px;
        padding-right: 9px; }
      .header-lang-dropdown ul li > a:hover {
        text-decoration: none;
        color: var(--mainColor); }
    .header-lang-dropdown ul li:last-child > a {
      border-bottom: 0; }

.header-lang.opened .header-lang-dropdown {
  transform: translateY(10px);
  transition: opacity 0.4s ease 0s, transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
  opacity: 1;
  visibility: visible;
  top: 100%;
  margin-top: 3px; }

.header-search {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 20px; }
  @media (min-width: 1024px) {
    .header-search {
      top: 0 !important; } }
  .header-search [class*='icon-'] {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    z-index: 4;
    cursor: pointer;
    display: block;
    color: #444;
    font-size: 20px;
    line-height: 35px;
    min-width: 40px;
    text-align: center;
    background-color: #fff; }
  .header-search input[type='text'],
  .header-search input[type='search'] {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 14px;
    line-height: 1.2em;
    color: #444;
    transition: 0.2s; 
}
  .header-search:hover input[type='text'], .header-search:hover input[type='search'],
  .header-search input[type='text']:focus,
  .header-search input[type='search']:focus {
    width: 230px;
    padding: 0 45px 0 10px;
    border-color: #ccc; 
    z-index: 2;
}
  .header-search input[type='submit'],
  .header-search button[type='submit'] {
    display: none; }
  .header-search ::-webkit-input-placeholder {
    color: #444; }
  .header-search ::-moz-placeholder {
    color: #444; }
  .header-search :-ms-input-placeholder {
    color: #444; }
  .header-search :-moz-placeholder {
    color: #444; }

@media (max-width: 1023px) {
  .header-search {
    position: absolute;
    //top: -30px;
    right: 117px; 
}

  .header-search [class*='icon-'] {
    font-size: 28px; }

  .header-content.stuck .header-search {
    margin-top: 0;
    top: -14px !important;
    transform: translateY(-50%); } }
@media (max-width: 767px) {
  .header-search {
    top: 24px;
    right: 80px;
    margin-top: -4px; }

  .header-search [class*='icon-'] {
    font-size: 18px; } }
@media (max-width: 1023px) {
  .header-search:last-child {
    right: 60px !important; 
    top: -44px;
} }
@media (max-width: 767px) {
  .header-search:last-child {
    top: -30px;
    right: 37px !important; } }

@media (max-width: 1023px) {
  .has-lang .header-search {
    right: 158px; } }
@media (max-width: 767px) {
  .has-lang .header-search {
    right: 108px; } }

@media (max-width: 1023px) {
  .header-content.stuck .navbar {
    position: absolute;
    left: 0;
    right: 0; }

  .header-content.stuck .navbar-collapse .navbar-nav {
    padding-left: 30px; } }
.footer {
  margin-top: 140px;
  background: var(--footerColor);
  overflow: hidden; 
}
  @media (max-width: 1023px) {
    .footer {
      margin-top: 80px; } }
  @media (max-width: 767px) {
    .footer {
      margin-top: 60px; } }
  .footer .col-light {
    position: relative;
    z-index: 3;
    background-color: #f3f3f3;
    color: #787878;
    padding-top: 65px !important;
    padding-left: 45px;
    padding-right: 45px;
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.1); }
    @media (max-width: 1023px) {
      .footer .col-light {
        padding-top: 45px !important;
        padding-bottom: 40px !important; } }
  .footer > .container {
    position: relative; }
  .footer > .container > .row > * {
    padding-top: 45px;
    padding-bottom: 45px; }
    @media (max-width: 1023px) {
      .footer > .container > .row > * {
        padding-top: 30px;
        padding-bottom: 25px; } }
    @media (max-width: 767px) {
      .footer > .container > .row > * {
        padding-top: 20px;
        padding-bottom: 15px; } }

.footer-col1 > * + * {
  margin-top: 20px; }

.footer-bottom {
  padding: 11px 0;
  background: var(--footerBottomColor);
  font-size: 15px;
  line-height: 26px;
  font-weight: 300; }
  .footer-bottom b {
    font-weight: 500; }
  .footer-bottom, .footer-bottom a {
    color: #fff; }
  .footer-bottom [class*='icon'] {
    font-size: 16px;
    position: relative;
    top: 1px; }
    @media (max-width: 575px) {
      .footer-bottom [class*='icon'] {
        font-size: 11px; } }
  @media (max-width: 575px) {
    .footer-bottom {
      font-size: 12px;
      line-height: 18px; } }

@media (max-width: 1023px) {
  .footer h2 {
    font-size: 50px; }

  .footer h4 {
    font-size: 32px; }

  .footer .col-light {
    padding-left: 15px;
    padding-right: 15px; } }
@media (max-width: 767px) {
  .footer h2 {
    font-size: 44px; }

  .footer h4 {
    font-size: 32px; }

  body.booking-form-on .footer {
    padding-bottom: 20px; } }
.footer-copyright {
  font-size: 15px; }
  .footer-copyright a,
  .footer-copyright span {
    color: #878787; }

.footer-text p {
  margin-bottom: 10px; }
.footer-text p:last-child {
  margin-bottom: 0; }

.footer-social {
  font-size: 16px;
  line-height: 48px; }
  .footer-social:not(.first-child) {
    margin-top: 35px; }
    @media (max-width: 1023px) {
      .footer-social:not(.first-child) {
        margin-top: 0; } }
    @media (max-width: 767px) {
      .footer-social:not(.first-child) {
        margin-top: 25px; } }
  @media (max-width: 767px) {
    .footer-social {
      line-height: 48px; } }
  .footer-social a {
    display: inline-block;
    margin-right: 4px;
    width: 48px;
    height: 48px;
    color: var(--mainColor);
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
    transition: 0.2s; }
    @media (max-width: 767px) {
      .footer-social a {
        width: 48px;
        height: 48px; } }
    .footer-social a:hover {
      color: var(--mainColor);
      text-decoration: none;
      box-shadow: inset 0 0 0 5px var(--boxShadowColor); }

.footer ul.icn-list {
  font-size: 15px;
  line-height: 24px; }
  @media (max-width: 767px) {
    .footer ul.icn-list {
      font-size: 14px;
      line-height: 20px; } }
  .footer ul.icn-list li {
    position: relative;
    padding-left: 31px; }
    .footer ul.icn-list li > [class*='icon'] {
      position: absolute;
      left: 0;
      top: 4px;
      font-size: 18px;
      color: var(--mainColor); }
      @media (max-width: 767px) {
        .footer ul.icn-list li > [class*='icon'] {
          font-size: 14px;
          left: 6px; } }
  .footer ul.icn-list li:not(:last-child) {
    margin-bottom: 7px; }
    @media (max-width: 767px) {
      .footer ul.icn-list li:not(:last-child) {
        margin-bottom: 3px; } }
  .footer ul.icn-list .phone {
    font-weight: 600;
    color: var(--mainColor); }
  .footer ul.icn-list a, .footer ul.icn-list a:hover {
    text-decoration: none; }
  .footer ul.icn-list .btn {
    margin-top: 5px;
    margin-bottom: 15px;
    font-family: var(--fontFamily2);
    letter-spacing: 0; }

.footer-post-photo {
  min-width: 66px;
  flex-basis: 66px; }
  .footer-post-photo img {
    width: 49px;
    height: 49px;
    object-fit: cover;
    border-radius: 50%; }
.footer-post-text, .footer-post-text p {
  font-size: 15px;
  line-height: 23px; }
.footer-post-title a {
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  font-weight: bold;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: #444;
  transition: 0.2s; }
  .footer-post-title a:hover {
    text-decoration: none;
    color: var(--mainColor); }
  @media (max-width: 767px) {
    .footer-post-title a {
      font-size: 14px;
      line-height: 20px; } }
.footer-post + * {
  margin-top: 18px; }

.footer-subscribe .input-group {
  max-width: 300px; }

.backToTop {
  position: fixed;
  z-index: 1020;
  right: 25px;
  bottom: -25px;
  opacity: 0;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  background: var(--mainColor);
  font-size: 18px;
  line-height: 63px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.25s, bottom 0.75s ease-in-out; }
  @media (max-width: 1023px) {
    .backToTop {
      width: 41px;
      height: 41px;
      right: 15px;
      font-size: 13px;
      line-height: 41px; } }
  .backToTop [class*='icon'] {
    display: inline-block;
    transition: 0.5s; }
  .backToTop:hover {
    box-shadow: 0 0 0 10px rgba(30, 118, 189, 0.2); }
    .backToTop:hover [class*='icon'] {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH"; }
  .backToTop.visible {
    opacity: 1;
    bottom: 45px; }
    @media (max-width: 1023px) {
      .backToTop.visible {
        bottom: 65px; } }

@media (max-width: 767px) {
  .footer-logo {
    max-width: 126px; } }
/*slider*/
#mainSliderWrapper {
  position: relative; }

#mainSlider {
  opacity: 0;
  transition: opacity 0.5s; }

#mainSlider.slick-initialized {
  opacity: 1; }

#mainSlider,
.main-slider .img-holder {
  height: 762px; }
  @media (max-width: 1229px) {
    #mainSlider,
    .main-slider .img-holder {
      height: 590px;
      min-height: 590px;
      max-height: calc(100vh - 178px); } }
  @media (max-width: 1023px) {
    #mainSlider,
    .main-slider .img-holder {
      height: 400px;
      min-height: 0;
      max-height: none; } }
  @media (max-width: 767px) {
    #mainSlider,
    .main-slider .img-holder {
      height: 300px; } }
  @media (max-width: 575px) {
    #mainSlider,
    .main-slider .img-holder {
      height: 175px; } }

#mainSlider {
  margin-bottom: 30px;
  opacity: 0; }
  #mainSlider .inner-circles-loader:not(:required) {
    width: 70px;
    height: 70px; }
  #mainSlider .inner-circles-loader:not(:required):before,
  #mainSlider .inner-circles-loader:not(:required):after {
    width: 70px;
    height: 70px; }
  #mainSlider.slick-initialized {
    opacity: 1;
    z-index: 101; }
  #mainSlider .slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1); }
  #mainSlider .slide .img--holder {
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat; }
  #mainSlider .slide-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    z-index: 100; }
    #mainSlider .slide-content .vert-wrap {
      padding-bottom: 10px; }
      @media (max-width: 767px) {
        #mainSlider .slide-content .vert-wrap {
          padding-bottom: 0; } }
.main-slider .slide-content.center {
    text-align: center; }
  .main-slider .slide-txt2 {
    font-size: 16px;
    line-height: 1em;
    font-family: var(--fontFamily);
    color: #fff;
    margin: 0;
    letter-spacing: .03em; }
    .main-slider .slide-txt2:not(:first-child) {
      margin-top: 25px; }
      .main-slider .slide-txt1 {
    font-size: 48px;
    line-height: 1.26em;
    font-weight: 400;
    font-family: var(--fontFamily);
    margin: 0;
    text-transform: uppercase; }
    .main-slider .slide-txt1:not(:first-child) {
      margin-top: .3em; }
  .main-slider .slide-btn {
    margin-top: 65px; }
  @media (min-width: 1024px) {
    .main-slider .slide-btn .btn,
    .main-slider .slide-btn .btn:focus {
      line-height: 25px;
      padding: 15px 38px;
      border-radius: 30px; } }
  @media (max-width: 1023px) {
    .main-slider .slide-txt1 {
      font-size: 38px;
      line-height: 1.16em; }
    .main-slider .slide-txt2 {
      font-size: 14px; }
    .main-slider .slide-btn {
      margin-top: 35px; } }
  @media (max-width: 575px) {
    .main-slider .slide-txt1 {
      font-size: 20px; }
    .main-slider .slide-txt2 {
      display: none; }
    .main-slider .slide-btn {
      display: none;
      margin-top: 20px; }
      .main-slider .slide-btn [class*='icon-'] {
        display: none; }
      .main-slider .slide-btn .btn,
      .main-slider .slide-btn .btn:focus {
        font-size: 14px;
        line-height: 16px;
        padding: 10px 20px; } }
  #mainSlider .slick-prev,
  #mainSlider .slick-next {
    width: 50px;
    height: 50px;
    margin-top: -25px; }
  #mainSlider .slick-prev:before,
  #mainSlider .slick-next:before {
    font-size: 20px;
    line-height: 48px; }
  #mainSlider .slick-prev {
    left: 50px; }
  #mainSlider .slick-next {
    right: 50px; }
  #mainSlider.arrows-white .slick-prev, #mainSlider.arrows-white .slick-next {
    border-color: #fff; }
  #mainSlider.arrows-white .slick-prev:hover, #mainSlider.arrows-white .slick-next:hover {
    background: rgba(255, 255, 255, 0.2); }
  #mainSlider.arrows-white .slick-prev:before,
  #mainSlider.arrows-white .slick-next:before {
    color: #fff; }
  #mainSlider.arrows-bottom .slick-prev {
    top: auto;
    bottom: 20px;
    left: auto;
    right: 80px; }
  #mainSlider.arrows-bottom .slick-next {
    top: auto;
    bottom: 20px;
    right: 20px; }
  #mainSlider .slick-dots {
    margin: -50px 0 0 0; }
  #mainSlider .slick-dots li button {
    width: 11px;
    height: 11px; }
  @media (max-width: 1023px) {
    #mainSlider .slick-dots {
      margin: -30px 0 0 0; } }
  @media (max-width: 767px) {
    #mainSlider .slick-dots {
      margin: -30px 0 0 0; }
    #mainSlider .slick-dots li button {
      width: 9px;
      height: 9px; } }
  @media (max-width: 575px) {
    #mainSlider .slick-dots {
      margin: -20px 0 0 0; } }

#mainSliderWrapper {
  position: relative; }

.main-slider {
  position: relative; 

  .img-holder{
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
  }

  .vert-wrap{
    padding-bottom: 10px;
  }

  .slide-content{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
  }
}
  .main-slider .fadeIn {
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    animation: fadeIn 2s; }
  .main-slider .fadeInUp {
    -webkit-animation: fadeInUp 1s;
    -moz-animation: fadeInUp 1s;
    -o-animation: fadeInUp 1s;
    animation: fadeInUp 1s; }

.kenburns .slick-slider {
  overflow: hidden; }

.kenburns {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: kenburns;
  animation-name: kenburns; }

.animated.kenburns {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@keyframes kenburns {
  from {
    transform: scale(1, 1); }
  to {
    transform: scale(1.2, 1.2) rotate(0.1deg); } }
@-webkit-keyframes kenburns {
  from {
    -webkit-transform: scale(1, 1); }
  to {
    -webkit-transform: scale(1.2, 1.2) rotate(0.1deg); } }
@-o-keyframes kenburns {
  from {
    -o-transform: scale(1, 1); }
  to {
    -o-transform: scale(1.2, 1.2) rotate(0.1deg); } }
@-moz-keyframes kenburns {
  from {
    -moz-transform: scale(1, 1); }
  to {
    -moz-transform: scale(1.2, 1.2) rotate(0.1deg); } }
@-ms-keyframes kenburns {
  from {
    -ms-transform: scale(1, 1); }
  to {
    -ms-transform: scale(1.2, 1.2) rotate(0.1deg); } }
.services-box-wrap-mobile {
  display: none; }

@media (max-width: 575px) {
  .services-box-wrap-desktop {
    display: none; }

  .services-box-wrap-mobile {
    display: block; } }
.services-box-wrap-desktop {
  margin-left: -7.5px;
  margin-right: -7.5px; }
  .services-box-wrap-desktop .service-box {
    margin: 7.5px; }
  .services-box-wrap-desktop [class*="col-"] {
    display: flex; }

.service-box {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 20.8vw; }
  .service-box-greybg {
    background: var(--serviceBackColor); 
}
  .service-box-icon {
    line-height: 1em;
    position: relative;
    top: 5%;
    z-index: 0;
    color: var(--mainColor);
    transition: 0.5s ease; 
    img{
        height: 95px;
        width:95px;
        object-fit: contain;
    }
}
    .service-box-icon .icon-emergency {
      font-size: .75em;
      position: relative; }
    .service-box-icon .icon-syringe {
      font-size: .75em;
      position: relative; }
    .service-box-icon .icon-ambulance {
      font-size: .835em; }
    .service-box-icon .icon-woman {
      font-size: .9em;
      position: relative; }
    .service-box-icon .icon-man, .service-box-icon .icon-mommy, .service-box-icon .icon-body {
      font-size: 1.2em;
      position: relative; }
    .service-box-icon .icon-surgery {
      font-size: .9em;
      position: relative; }
  .service-box .service-box-title {
    font-size: 26px;
    line-height: 40px; }
  .service-box.service-box--hiddenbtn .service-box-title {
    transition: 0.7s; }
  .service-box-icon-bg {
    line-height: 1em;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--mainColor);
    opacity: 0;
    transition: 0.2s ease 0.1s; 
    img{
        width: 200px;
    }
}
  .service-box .service-box-icon + .service-box-title {
    margin-top: 55px; }
  .service-box-image {
    overflow: hidden;
    width: 100%;
    transition: 0.5s;
    background-size: cover; 
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
  .service-box-caption {
    display: flex;
    position: absolute;
    right: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px; }
    .service-box-caption.text-right {
      align-items: flex-end; }
  .service-box.service-box--hiddenbtn .service-box-caption {
    padding: 8% 12%;
    justify-content: center;
    align-items: center; }
  .service-box-caption > * {
    margin-bottom: 0; }
  .service-box-caption > * + * {
    margin-top: 17px; }
  .service-box-caption > * + .btn-wrap {
    margin-top: 32px; }
  .service-box p {
    max-width: 445px;
    font-size: 16px;
    line-height: 27px;
    margin-top: 20px; }

.service-box-rotator {
  width: 100%;
  margin-bottom: 0 !important; }
  .service-box-rotator .slick-dots {
    margin: 25px 0 -10px !important; }

@media (min-width: 768px) {
  .service-box-image.shift-bg-desktop {
    background-position-x: 30%; } }
.service-box:hover .service-box-image {
  transform: scale(1.1) rotate(0.01deg);
  transition: 0.75s; }

.service-box--hiddenbtn .btn-wrap {
  position: relative;
  z-index: 1;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s; }
@media (min-width: 768px) {
  .service-box--hiddenbtn:hover .service-box-caption {
    justify-content: center; }
  .service-box--hiddenbtn:hover .service-box-icon {
    opacity: 0;
    font-size: 0;
    top: 5%; }
  .service-box--hiddenbtn:hover .service-box-title {
    margin-top: -27px !important; }
  .service-box--hiddenbtn:hover .service-box-icon-bg {
    top: 5%;
    opacity: .1; }
  .service-box--hiddenbtn:hover .btn-wrap {
    height: 59px;
    opacity: 1; } }

@media (max-width: 1679px) {
  .service-box {
    height: 22vw; }

  .service-box.service-box--hiddenbtn .service-box-caption {
    padding: 4% 5%; }

  .service-box p {
    max-width: 420px; } }
@media (max-width: 1500px) {
  .service-box {
    height: 22vw; }

  .service-box.service-box--hiddenbtn .service-box-caption {
    padding: 4% 5%; }

  .service-box .service-box-title {
    font-size: 24px;
    line-height: 1.1875em; }

  .service-box-icon {
    font-size: 95px; }

  .service-box p {
    font-size: 15px;
    line-height: 22px;
    margin-top: 15px; }

  .service-box-caption > * + * {
    margin-top: 15px; }

  .service-box-caption > * + .btn-wrap {
    margin-top: 15px; }

  .services-box-wrap .col-8.order-3 .service-box-image {
    background-position: 28%; } }
@media (max-width: 1229px) {
  .service-box {
    height: 24vw;
    min-height: 230px; }
    .service-box p {
      display: none; }

  .service-box .service-box-title {
    font-size: 20px; }

  .service-box.service-box--hiddenbtn .service-box-title {
    margin-top: 8px !important; }

  .service-box-icon {
    font-size: 75px;
    position: static;
    transform: none;
    opacity: 1 !important; }

  .service-box-icon-bg {
    display: none; }

  .service-box-caption.w-50 {
    width: 41% !important;
    padding-left: 10px;
    padding-right: 10px; }

  .service-box.service-box--hiddenbtn .service-box-caption {
    justify-content: center; }

  .services-box-wrap .col-8.order-3 .service-box-image {
    background-position: 40%; } }
@media (min-width: 768px) and (max-width: 1023px) {
  .service-box .service-box-title {
    font-size: 18px; }

  .service-box-icon {
    font-size: 65px; }

  .service-box.service-box--hiddenbtn .service-box-title {
    margin-top: 6px !important; }

  .services-box-wrap .col-8.order-3 .service-box-image {
    background-position: 40%; } }
@media (min-width: 576px) and (max-width: 767px) {
  .service-box {
    height: 20vw;
    min-height: 200px; }

  .service-box .service-box-title {
    font-size: 18px; }

  .service-box--hiddenbtn .btn-wrap {
    position: absolute;
    margin: 0;
    top: 0;
    height: 100%;
    left: 0;
    right: 0; }

  .service-box.service-box--hiddenbtn.service-box .btn {
    position: absolute;
    margin: 0;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 1; }

  .services-box-wrap .col-8.order-3 .service-box-image {
    background-position: 36%; } }
@media (max-width: 575px) {
  .service-box .service-box-title {
    font-size: 18px;
    line-height: 24px; }

  .service-box-icon {
    font-size: 72px; }

  .services-box-wrap-mobile {
    max-width: 270px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto; }

  .service-box {
    min-height: none; } }
.service-card {
  height: 100%;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px 45px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }

  .service-card2{
    max-width: 100%;
  }
  @media (max-width: 1023px) {
    .service-card {
      padding: 0 15px 25px; } }
  .service-card > * {
    margin-bottom: 0; }
  .service-card > *:not(:first-child) {
    margin-top: 15px; }
  .service-card-photo {
    position: relative;
    overflow: hidden;
    transition: 0.35s;
    background-color: #fff;
    margin-left: -30px;
    margin-right: -30px; 
    img{
        height: 320px;
        object-fit: cover;
    }
}
    @media (max-width: 1023px) {
      .service-card-photo {
        margin-left: -15px;
        margin-right: -15px; } }
    .service-card-photo:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 120%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background: transparent;
      border-radius: 50%;
      box-shadow: inset 0 0 0 30px var(--mainColor);
      opacity: 0;
      transition: opacity 0 ease 0;
      pointer-events: none; }
  .service-card:hover .service-card-photo {
    border-radius: 50%; }
  .service-card:hover .service-card-photo:after {
    opacity: .2;
    transition: opacity 0.5s ease 0.2s; }
  .service-card-photo + .service-card-name {
    margin-top: 30px; }
  .service-card-name, .service-card-name a {
    font-size: 24px;
    line-height: 1.1em;
    text-decoration: none; }
    @media (max-width: 1023px) {
      .service-card-name, .service-card-name a {
        font-size: 20px; } }
  .service-card-name a:hover {
    text-decoration: none;
    color: var(--mainColor); }
  .service-card h5.service-card-name + .h-decor {
    top: 0;
    height: 2px;
    width: 27px;
    margin-top: 15px; }
  .service-card > ul.marker-list-md:not(:first-child) {
    margin-top: 25px; }
    @media (max-width: 1023px) {
      .service-card > ul.marker-list-md:not(:first-child) {
        margin-top: 15px; } }
  .service-card ul.marker-list-md li {
    font-size: 16px; }
    @media (max-width: 1023px) {
      .service-card ul.marker-list-md li {
        font-size: 15px;
        line-height: 20px; } }
  .service-card ul.marker-list-md li:not(:last-child) {
    margin-bottom: 16px; }
    @media (max-width: 1023px) {
      .service-card ul.marker-list-md li:not(:last-child) {
        margin-bottom: 8px; } }

.service-card-style2 {
  height: 100%;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 33px 40px;
  border: 5px solid #f6f7f5;
  transition: 0.2s; }
  @media (max-width: 767px) {
    .service-card-style2 {
      padding: 25px 20px 25px; } }
  .service-card-style2 > * {
    margin-bottom: 0; }
  .service-card-style2 > *:not(:first-child) {
    margin-top: 22px; }
  .service-card-style2 .service-card-icon {
    font-size: 48px;
    line-height: 1em;
    color: var(--mainColor); }
  .service-card-style2 .service-card-name {
    font-size: 20px;
    line-height: 1em; }
    @media (max-width: 1023px) {
      .service-card-style2 .service-card-name {
        font-size: 18px;
        line-height: 1.2em; } }
  .service-card-style2 .service-card-name a:hover {
    text-decoration: none;
    color: var(--mainColor); }
  .service-card-style2 > ul.marker-list-md:not(:first-child) {
    margin-top: 15px; }
  .service-card-style2 ul.marker-list-md li {
    font-size: 16px; }
    @media (max-width: 1023px) {
      .service-card-style2 ul.marker-list-md li {
        font-size: 15px;
        line-height: 20px; } }
  .service-card-style2 ul.marker-list-md li:not(:last-child) {
    margin-bottom: 16px; }
    @media (max-width: 1023px) {
      .service-card-style2 ul.marker-list-md li:not(:last-child) {
        margin-bottom: 8px; } }
  .service-card-style2:hover {
    border: 5px solid #43a0dd; }

.services-tab-wrap {
  padding: 60px 44px 75px 0;
  width: 570px;
  position: relative; }
  @media (max-width: 1229px) {
    .services-tab-wrap {
      width: 100%;
      min-width: 500px;
      padding: 50px 30px; } }
  @media (max-width: 1023px) {
    .services-tab-wrap {
      padding: 50px 30px 50px 15px; } }
  @media (max-width: 767px) {
    .services-tab-wrap {
      min-width: inherit;
      padding: 0 20px 30px; } }
  .services-tab-wrap .nav-pills {
    flex-wrap: nowrap; }
    @media (max-width: 767px) {
      .services-tab-wrap .nav-pills {
        margin-right: auto;
        padding-left: 0;
        flex-wrap: wrap; } }
    .services-tab-wrap .nav-pills .nav-link {
      position: relative;
      min-width: inherit;
      height: 39px;
      margin: 0 20px 0 0;
      padding: 0 20px 0 0;
      font-size: 15px;
      line-height: 18px;
      font-weight: 600;
      font-family: var(--fontFamily);
      border: 0;
      background: transparent;
      color: #444; }
      .services-tab-wrap .nav-pills .nav-link:hover {
        background: transparent !important;
        color: #000; }
      @media (max-width: 575px) {
        .services-tab-wrap .nav-pills .nav-link {
          width: auto; } }
      .services-tab-wrap .nav-pills .nav-link:after {
        content: '';
        position: absolute;
        top: 4px;
        right: 0;
        width: 2px;
        height: 9px;
        background: #a9aaaa; }
      .services-tab-wrap .nav-pills .nav-link:last-child {
        margin: 0;
        padding: 0; }
        .services-tab-wrap .nav-pills .nav-link:last-child:after {
          display: none; }
    .services-tab-wrap .nav-pills .nav-link.active {
      color: var(--mainColor);
      box-shadow: none; }

body:not(.touch) .services-tab-wrap .nav-link:hover {
  background: #fff;
  border-color: #e6e6e6; }

.services-wrap {
  padding: 60px 35px 60px 0;
  width: 570px;
  position: relative; }
  @media (max-width: 1229px) {
    .services-wrap {
      width: 100%;
      padding: 50px 30px; } }
  @media (max-width: 1023px) {
    .services-wrap {
      padding: 50px 30px 50px 15px; } }
  @media (max-width: 767px) {
    .services-wrap {
      min-width: inherit;
      padding: 0 20px 30px; } }

.services-wrap1 {
  padding: 60px 35px 140px 0;
  width: 570px;
  position: relative; }
  @media (max-width: 1229px) {
    .services-wrap1 {
      width: 100%;
      padding: 50px 30px; } }
  @media (max-width: 1023px) {
    .services-wrap1 {
      padding: 50px 30px 50px 15px; } }
  @media (max-width: 767px) {
    .services-wrap1 {
      min-width: inherit;
      padding: 0 20px 30px; } }

.service-tab-banner {
  overflow: hidden; }
  @media (max-width: 1023px) {
    .service-tab-banner img {
      transform: translateX(-15%);
      max-height: 530px; } }
  @media (max-width: 767px) {
    .service-tab-banner {
      margin-left: -20px;
      margin-right: -20px; }
      .service-tab-banner img {
        width: 100%;
        max-height: 450px;
        transform: translateX(0); } }

@media (min-width: 1024px) and (max-width: 1229px) {
  .service-tab-banner-alt.bg-left {
    background-position: -7% center; } }

.single-service > .row {
  align-items: center; }
.single-service img {
  width: auto !important;
  display: inline-block; }

@media (min-width: 1230px) {
  .single-service-carousel .slick-prev {
    left: -23px; }
  .single-service-carousel .slick-next {
    right: -23px; } }
@media (max-width: 767px) {
  .single-service h3 {
    font-size: 19px;
    line-height: 1em;
    margin-bottom: 27px; } }
@media (max-width: 767px) {
  .single-service .col-img {
    order: -1;
    text-align: center !important; }
  .single-service [class*='col-'] > div {
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important; } }
.department-tabs {
  display: flex;
  flex-wrap: wrap;
  margin: -6px -6px 0; }
  .department-tabs > * {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 calc(25% - 12px);
    min-width: calc(25% - 12px);
    padding: 41px 10px 45px;
    margin: 6px;
    background-color: #f6f7f5; }

.department-tab {
  transition: 0.2s;
  cursor: pointer; }
  .department-tab.active, .department-tab:hover {
    background-color: var(--mainColor); }
    .department-tab.active .department-tab-icon, .department-tab:hover .department-tab-icon {
      color: var(--mainColor);
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2); }
    .department-tab.active .department-tab-text, .department-tab:hover .department-tab-text {
      color: #ffffff; }

.department-tab-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  font-size: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #a5a7ac;
  box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  transition: 0.5s; }
  .department-tab-icon:not(:last-child) {
    margin-bottom: 12px; }
  @media (max-width: 575px) {
    .department-tab-icon {
      width: 70px;
      height: 70px; } }

.department-tab-text {
  font-size: 14px;
  line-height: 1em;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: #444444;
  transition: 0.2s; }
  @media (max-width: 575px) {
    .department-tab-text {
      font-size: 13px; } }

.department-tabs2 {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -15px 0; }
  .department-tabs2 > * {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 calc(50% - 30px);
    min-width: calc(50% - 30px);
    margin: 15px;
    background-color: #ffffff; }
  @media (max-width: 1023px) {
    .department-tabs2 {
      margin: -10px -10px 0; }
      .department-tabs2 > * {
        flex: 0 0 calc(50% - 20px);
        min-width: calc(50% - 20px);
        margin: 10px; } }

.department-tab2 {
  padding: 41px 30px;
  transition: 0.2s;
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.04); }
  .department-tab2.active, .department-tab2:hover {
    background-color: var(--mainColor);
    color: #ffffff;
    box-shadow: none; }
    .department-tab2.active .department-tab2-number, .department-tab2.active .department-tab2-text, .department-tab2:hover .department-tab2-number, .department-tab2:hover .department-tab2-text {
      color: #ffffff; }
    .department-tab2.active .department-tab2-decor, .department-tab2:hover .department-tab2-decor {
      background-color: #ffffff; }
  @media (max-width: 1023px) {
    .department-tab2 {
      padding: 25px 5px 25px 15px; } }
  @media (max-width: 575px) {
    .department-tab2 {
      padding: 18px 5px 18px 12px; } }

.department-tabs2-bg {
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 0;
  transform: translateX(-50%); }
  @media (max-width: 1023px) {
    .department-tabs2-bg img {
      max-width: 65vw; } }
  @media (max-width: 767px) {
    .department-tabs2-bg img {
      max-width: 120vw; } }

.department-tab2-number {
  font-size: 90px;
  line-height: 1em;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: var(--mainColor); }
  @media (max-width: 1023px) {
    .department-tab2-number {
      font-size: 62px; } }
  @media (max-width: 575px) {
    .department-tab2-number {
      font-size: 50px; } }

.department-tab2-text {
  font-size: 20px;
  line-height: 1em;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: #444; }
  @media (max-width: 1023px) {
    .department-tab2-text {
      font-size: 15px; } }
  @media (max-width: 575px) {
    .department-tab2-text {
      font-size: 12px;
      font-weight: 500; } }
  .department-tab2-text:not(:first-child) {
    margin-top: 10px; }

.department-tab2-decor {
  width: 33px;
  height: 3px;
  background-color: var(--mainColor); }
  .department-tab2-decor:not(:first-child) {
    margin-top: 15px; }
  @media (max-width: 1023px) {
    .department-tab2-decor {
      width: 22px;
      height: 2px; }
      .department-tab2-decor:not(:first-child) {
        margin-top: 12px; } }
  @media (max-width: 575px) {
    .department-tab2-decor {
      width: 19px;
      height: 2px; }
      .department-tab2-decor:not(:first-child) {
        margin-top: 8px; } }

@media (max-width: 1023px) {
  .department-carousel, .department-carousel2 {
    margin: 45px 0; } }
@media (max-width: 767px) {
  .department-carousel, .department-carousel2 {
    text-align: center;
    margin-left: -15px;
    margin-right: -15px; }
    .department-carousel .department-item, .department-carousel2 .department-item {
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden; } }
@media (min-width: 576px) {
  .department-carousel .slick-list, .department-carousel2 .slick-list {
    overflow: visible; } }
.department-carousel .department-item, .department-carousel2 .department-item {
  position: relative;
  z-index: 0;
  background-color: #ffffff; }
  .department-carousel .department-item h2, .department-carousel2 .department-item h2 {
    line-height: 1.22em;
    margin-bottom: 30px; }
.department-carousel .department-tab, .department-carousel2 .department-tab {
  display: none;
  margin: 0 auto 20px;
  width: 162px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px 30px;
  background-color: var(--mainColor); }
  @media (max-width: 575px) {
    .department-carousel .department-tab, .department-carousel2 .department-tab {
      display: flex; } }
.department-carousel .department-tab-icon, .department-carousel2 .department-tab-icon {
  background-color: #ffffff;
  color: var(--mainColor); }
.department-carousel .department-tab-text, .department-carousel2 .department-tab-text {
  color: #ffffff; }
.department-carousel h3, .department-carousel2 h3 {
  position: relative;
  z-index: -1;
  font-size: 30px;
  line-height: 1.2em;
  margin-bottom: 30px; }
  @media (max-width: 1023px) {
    .department-carousel h3, .department-carousel2 h3 {
      font-size: 24px;
      margin-bottom: 20px; } }
  @media (max-width: 575px) {
    .department-carousel h3, .department-carousel2 h3 {
      font-size: 16px;
      margin-bottom: 20px; } }
  .department-carousel h3:after, .department-carousel2 h3:after {
    position: absolute;
    z-index: 0;
    left: -7px;
    top: -24px;
    font-size: 120px;
    line-height: 1em;
    color: #f6f7f5;
    white-space: nowrap;
    content: attr(data-title);
    opacity: 0;
    left: 100%;
    transition: 1s; }
    @media (max-width: 1023px) {
      .department-carousel h3:after, .department-carousel2 h3:after {
        font-size: 100px; } }
    @media (max-width: 575px) {
      .department-carousel h3:after, .department-carousel2 h3:after {
        top: -20px;
        font-size: 70px;
        left: 50% !important;
        transform: translateX(-50%); } }
  .department-carousel h3 span, .department-carousel2 h3 span {
    position: relative;
    z-index: 1; }
.department-carousel .slick-disabled, .department-carousel2 .slick-disabled {
  pointer-events: none;
  opacity: .75; }
@media (max-width: 767px) {
  .department-carousel .slick-prev, .department-carousel .slick-next, .department-carousel2 .slick-prev, .department-carousel2 .slick-next {
    top: 120px; }
  .department-carousel .slick-prev, .department-carousel .slick-next, .department-carousel .slick-prev:hover, .department-carousel .slick-next:hover, .department-carousel2 .slick-prev, .department-carousel2 .slick-next, .department-carousel2 .slick-prev:hover, .department-carousel2 .slick-next:hover {
    border: 0;
    background-color: transparent !important; }
    .department-carousel .slick-prev:before, .department-carousel .slick-next:before, .department-carousel .slick-prev:hover:before, .department-carousel .slick-next:hover:before, .department-carousel2 .slick-prev:before, .department-carousel2 .slick-next:before, .department-carousel2 .slick-prev:hover:before, .department-carousel2 .slick-next:hover:before {
      color: var(--mainColor); } }
@media (min-width: 576px) {
  .department-carousel .slick-prev, .department-carousel .slick-next, .department-carousel2 .slick-prev, .department-carousel2 .slick-next {
    bottom: -70px;
    top: auto;
    margin: 0; }
  .department-carousel .slick-prev, .department-carousel2 .slick-prev {
    left: 0; }
  .department-carousel .slick-next, .department-carousel2 .slick-next {
    left: 60px; } }
@media (max-width: 1023px) {
  .department-carousel .slick-prev, .department-carousel .slick-next, .department-carousel2 .slick-prev, .department-carousel2 .slick-next {
    bottom: -50px; } }

.department-carousel2 {
  overflow: hidden; }
  .department-carousel2 .department-item {
    background-color: transparent;
    transition: none; }
  @media (max-width: 1023px) {
    .department-carousel2 {
      margin: 0; }
      .department-carousel2 h3 {
        font-size: 26px;
        line-height: 30px; } }
  @media (max-width: 767px) {
    .department-carousel2 {
      margin-top: 40px;
      text-align: left; }
      .department-carousel2 .department-item {
        padding: 0; }
      .department-carousel2 h3 {
        font-size: 26px;
        line-height: 30px; } }

.special-card {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
  .special-card-photo {
    display: block;
    position: relative;
    overflow: hidden;
    transition: 0.3s; }
  .special-card-photo img {
    max-width: 100%;
    width: auto !important;
    transition: 0.75s; }
  .special-card-caption {
    position: absolute;
    top: 0;
    right: 3%;
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10% 0 0 0; }
    .special-card-caption.text-left {
      left: 57%;
      right: auto; }
    @media (max-width: 1229px) {
      .special-card-caption {
        padding-top: 9%;
        left: auto;
        right: 2%;
        width: 42%; } }
    @media (max-width: 1023px) {
      .special-card-caption {
        padding-top: 6%;
        left: auto;
        right: 2%;
        width: 42%; } }
  .special-card-txt1 {
    font-size: 34px;
    line-height: 1em;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: var(--mainColor); }
    @media (max-width: 1229px) {
      .special-card-txt1 {
        font-size: 28px;
        line-height: 32px; } }
    @media (max-width: 1023px) {
      .special-card-txt1 {
        font-size: 20px;
        line-height: 24px; } }
    @media (max-width: 767px) {
      .special-card-txt1 {
        font-size: 18px;
        line-height: 22px; } }
    .special-card-txt1 + .special-card-txt2 {
      margin-top: 0; }
  .special-card-txt2 {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: #444; }
    @media (max-width: 1229px) {
      .special-card-txt2 {
        font-size: 19px;
        line-height: 1em; } }
    @media (max-width: 1023px) {
      .special-card-txt2 {
        font-size: 19px; } }
    @media (max-width: 1023px) {
      .special-card-txt2 {
        font-size: 14px; } }
  .special-card-txt3 {
    margin-top: 17px; }
    @media (max-width: 1229px) {
      .special-card-txt3 {
        margin-top: 15px;
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1023px) {
      .special-card-txt3 {
        height: 34px;
        overflow: hidden;
        font-size: 12px;
        line-height: 17px;
        margin-top: 10px; } }
  .special-card .btn {
    margin-top: 25px; }
  @media (max-width: 1229px) {
    .special-card .btn {
      margin-top: 20px; } }
  @media (max-width: 1023px) {
    .special-card .btn {
      margin-top: 14px; }
    .special-card .btn, .special-card btn:focus {
      font-size: 12px;
      line-height: 16px;
      padding: 8px 10px;
      border-radius: 18px; } }
  @media (max-width: 767px) {
    .special-card .btn, .special-card btn:focus {
      font-size: 12px;
      line-height: 16px;
      padding: 8px 10px;
      border-radius: 18px; } }
  .special-card:hover .special-card-photo {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .special-card:hover .special-card-photo img {
    transform: scale(1.1) rotate(0.01deg); }

.special-carousel {
  overflow: hidden; }
  .special-carousel .special-card {
    margin-top: 15px;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .special-carousel .special-card {
        margin-top: 0; } }
  .special-carousel .slick-track {
    padding-bottom: 10px; }
  .special-carousel .slick-dots {
    margin: 35px 0 -15px; }
    @media (max-width: 1023px) {
      .special-carousel .slick-dots {
        margin: 25px 0 -10px; } }
    @media (max-width: 767px) {
      .special-carousel .slick-dots {
        margin: 15px 0 -9px; } }

.corner-ribbon-wrap {
  background: #f58588;
  background: -moz-linear-gradient(top, #f58588 50%, #af5c75 100%);
  background: -webkit-linear-gradient(top, #f58588 50%, #af5c75 100%);
  background: linear-gradient(to bottom, #f58588 50%, #af5c75 100%);
  color: #FFF;
  width: 140px;
  height: 140px;
  padding: 10px;
  text-align: center;
  position: absolute;
  top: -70px;
  left: -70px;
  transform: rotate(-45deg);
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.corner-ribbon {
  transform: rotate(-1deg);
  -webkit-backface-visibility: hidden; }

.corner-ribbon span {
  display: block;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: .5px;
  line-height: 1.2em; }

.banner-full {
  position: relative;
  padding: 40px 0;
  //background: url(../images/banner-bg.jpg) no-repeat 0 bottom; 
}
  .banner-full-text1 {
    font-size: 70px;
    font-family: var(--fontFamily);
    line-height: 1em;
    color: #fff; }
  .banner-full-text2 {
    font-size: 32px;
    font-family: var(--fontFamily);
    line-height: 1em;
    color: #fff; }
  .banner-full-text3 {
    font-size: 18px;
    line-height: 30px;
    color: #fff; }
  .banner-full-image {
    position: absolute;
    bottom: -40px;
    left: 50%; }
    .banner-full-image img {
      max-width: 100%; }

@media (max-width: 1023px) {
  .banner-full {
    padding: 20px 20px; }
    .banner-full-text1 {
      font-size: 50px;
      line-height: 44px;
      width: 80%; }
    .banner-full-text2 {
      font-size: 24px; }
    .banner-full-image {
      bottom: -35px;
      left: 38%;
      width: 24%; } }
@media (max-width: 767px) {
  .banner-full {
    padding: 20px 0 100px; }
    .banner-full-text1 {
      width: 100%;
      margin-bottom: 5px;
      font-size: 34px;
      line-height: 40px; }
    .banner-full-text2 {
      font-size: 22px; }
    .banner-full-image {
      bottom: -25px;
      left: calc(50% - 75px);
      width: 150px; } }
.banner-left {
  display: flex;
  align-items: center; }
  @media (max-width: 1229px) {
    .banner-left {
      min-height: 40vw;
      max-height: 100vh;
      background-position: center 20%; } }
  .banner-left-caption {
    padding: 18% 50px 50px 60px; }
    @media (max-width: 1023px) {
      .banner-left-caption {
        padding: 62px 25px; } }
    @media (max-width: 767px) {
      .banner-left-caption {
        padding: 30px 25px; } }
  .banner-left-text1 {
    font-size: 44px;
    line-height: 1em;
    font-weight: 500;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-family: var(--fontFamily); }
    @media (max-width: 1023px) {
      .banner-left-text1 {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .banner-left-text1 {
        font-size: 32px; } }
  .banner-left .btn-fill, body:not(.touch) .banner-left .btn-fill:focus {
    font-size: 16px !important; }
  @media (max-width: 767px) {
    .banner-left .btn-fill, body:not(.touch) .banner-left .btn-fill:focus {
      font-size: 14px !important;
      line-height: 16px !important; } }

.banner-center {
  display: flex;
  align-items: center; }
  .banner-center-caption {
    padding: 148px 50px;
    width: 100%; }
    @media (max-width: 1023px) {
      .banner-center-caption {
        padding: 60px 30px; } }
    @media (max-width: 575px) {
      .banner-center-caption {
        padding: 40px 10px; } }
  .banner-center-text1 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 600;
    color: #fff;
    font-family: var(--fontFamily); }
    @media (max-width: 1023px) {
      .banner-center-text1 {
        font-size: 38px;
        line-height: 47px;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .banner-center-text1.max-450-md {
        max-width: 450px; } }
    @media (max-width: 767px) {
      .banner-center-text1 {
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 0; } }
    @media (max-width: 575px) {
      .banner-center-text1 {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        max-width: 230px; } }
  .banner-center-text1-1 {
    font-size: 36px;
    line-height: 47px;
    font-weight: 600;
    color: #fff;
    font-family: var(--fontFamily); }
    @media (max-width: 1023px) {
      .banner-center-text1-1 {
        font-size: 38px;
        line-height: 47px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .banner-center-text1-1 {
        font-size: 30px;
        line-height: 38px; }
        .banner-center-text1-1.max-450-md {
          max-width: 450px;
          margin-left: auto;
          margin-right: auto; } }
    @media (max-width: 767px) {
      .banner-center-text1-1 {
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 0; } }
    @media (max-width: 575px) {
      .banner-center-text1-1 {
        font-size: 26px;
        line-height: 30px;
        font-weight: 500; } }
  .banner-center-text2 {
    font-size: 17px;
    line-height: 1em;
    font-weight: 500;
    font-family: var(--fontFamily);
    color: #fff;
    letter-spacing: 1.5px; }
    .banner-center-text2:not(:first-child) {
      margin-top: 10px; }
    @media (max-width: 1023px) {
      .banner-center-text2 {
        font-size: 14px; } }
  .banner-center-text3 {
    font-size: 24px;
    line-height: 1em;
    font-weight: 400;
    font-family: var(--fontFamily);
    color: #fff; }
    .banner-center-text3:not(:first-child) {
      margin-top: 10px; }
    @media (max-width: 1023px) {
      .banner-center-text3 {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .banner-center-text3 {
        font-size: 18px;
        line-height: 24px;
        padding: 0 20px; } }
    @media (max-width: 575px) {
      .banner-center-text3 {
        font-size: 13px;
        line-height: 18px; } }
  .banner-center-text4 {
    font-size: 18px;
    line-height: 1em;
    font-weight: 400;
    font-family: var(--fontFamily);
    color: #fff;
    letter-spacing: 1px; }
    .banner-center-text4:not(:first-child) {
      margin-top: 20px; }
    @media (max-width: 1023px) {
      .banner-center-text4 {
        font-size: 14px;
        line-height: 20px;
        padding: 0 20px; } }
    @media (max-width: 767px) {
      .banner-center-text4 {
        letter-spacing: .5px;
        padding: 0 35px; }
        .banner-center-text4:not(:first-child) {
          margin-top: 15px; } }
    @media (max-width: 359px) {
      .banner-center-text4 {
        padding: 0 10px; } }
  .banner-center-text5 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: var(--fontFamily);
    color: #fff;
    letter-spacing: .55px; }
    .banner-center-text5:not(:first-child) {
      margin-top: 20px; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .banner-center-text5.max-450-md {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto; } }
    @media (max-width: 575px) {
      .banner-center-text5 {
        font-size: 12px;
        line-height: 18px; } }
  .banner-center .btn-fill, body:not(.touch) .banner-center .btn-fill:focus {
    font-size: 16px !important; }
    .banner-center .btn-fill:not(:first-child), body:not(.touch) .banner-center .btn-fill:focus:not(:first-child) {
      margin-top: 27px; }
    @media (max-width: 767px) {
      .banner-center .btn-fill:not(:first-child), body:not(.touch) .banner-center .btn-fill:focus:not(:first-child) {
        margin-top: 15px; } }
  @media (max-width: 767px) {
    .banner-center .btn-fill, body:not(.touch) .banner-center .btn-fill:focus {
      font-size: 14px !important;
      line-height: 16px !important; } }

.banner-center--p-sm .banner-center-caption {
  padding: 128px 50px; }
  @media (max-width: 767px) {
    .banner-center--p-sm .banner-center-caption {
      padding: 60px 40px; } }
  @media (max-width: 575px) {
    .banner-center--p-sm .banner-center-caption {
      padding: 50px 15px; } }

.banner-call img.shift-right {
  margin-left: -20px;
  display: block;
  position: relative;
  z-index: -1; }
.banner-call img.shift-right-1 {
  margin-left: -20px;
  display: block;
  position: relative;
  z-index: -1; }
.banner-call img.shift-left {
 // margin-left: -110px;
  display: block; 
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-call img.shift-left-1 {
  margin-left: -58px;
  display: block; }
.banner-call .d-flex {
  padding-left: 60px;
  padding-bottom: 35px;
  justify-content: center; }
  @media (max-width: 1229px) {
    .banner-call .d-flex {
      padding-left: 0; } }
@media (max-width: 1229px) {
  .banner-call img.shift-left-1 {
    max-width: 145%;
    margin-left: -80px; } }
@media (max-width: 1023px) {
  .banner-call img:not([class]), .banner-call img.shift-right {
    max-width: 160%;
    margin-left: -50px; }
  .banner-call img.shift-right-1 {
    max-width: 160%;
    margin-left: -50px; }
  .banner-call img:not([class]), .banner-call img.shift-left {
    max-width: 160%;
    margin-left: -100px; }
  .banner-call img.shift-left-1 {
    max-width: 150%;
    margin-left: -58px; } }
@media (max-width: 767px) {
  .banner-call img:not([class]), .banner-call img.shift-right {
    max-width: 130%;
    margin-left: -3%; }
  .banner-call img.shift-right-1 {
    max-width: 110%;
    margin-left: -5%; }
  .banner-call img:not([class]), .banner-call img.shift-left {
    max-width: 130%;
    margin-left: -33px; }
  .banner-call img.shift-left-1 {
    max-width: 120%;
    margin-left: -5%; } }
.banner-call .btn-lg, .banner-call .btn-group-lg > .btn {
  font-family: var(--fontFamily);
  font-weight: 500; }
.banner-call p {
  max-width: 555px;
  margin-left: auto;
  margin-right: auto; }

.banner-call-phone {
  font-size: 26px;
  line-height: 1.2em;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: #444;
  transition: .2s; }
  @media (max-width: 1023px) {
    .banner-call-phone {
      font-size: 21px; } }
  @media (max-width: 767px) {
    .banner-call-phone {
      font-size: 18px; } }
  .banner-call-phone:hover {
    color: var(--mainColor);
    text-decoration: none; }
  .banner-call-phone [class*='icon'] {
    position: relative;
    top: -1px;
    padding-right: 12px;
    font-size: 19px;
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .banner-call-phone [class*='icon'] {
        font-size: 14px; } }
    @media (max-width: 1023px) {
      .banner-call-phone [class*='icon'] {
        font-size: 12px; } }

.banner-contact-us {
  background-position: center center;
  background-repeat: no-repeat; }
  .banner-contact-us img.shift-right {
    display: block;
    position: relative;
    z-index: -1; }
  .banner-contact-us img.shift-left {
    position: absolute;
    margin-left: -150px;
    display: block; }
  @media (max-width: 1229px) {
    .banner-contact-us img:not([class]), .banner-contact-us img.shift-left {
      max-width: 120%;
      margin-left: -115px; } }
  @media (max-width: 1023px) {
    .banner-contact-us img:not([class]), .banner-contact-us img.shift-left {
      max-width: 130%;
      margin-left: -115px; }
    .banner-contact-us img.shift-right {
      margin-left: 0;
      max-width: 135%; } }
  @media (max-width: 767px) {
    .banner-contact-us {
      overflow: hidden; }
      .banner-contact-us img:not([class]), .banner-contact-us img.shift-left {
        max-width: 125%;
        margin-left: -100px; }
      .banner-contact-us img.shift-right {
        max-width: 80%;
        margin: 0 auto; } }
  @media (max-width: 575px) {
    .banner-contact-us img:not([class]), .banner-contact-us img.shift-left {
      position: relative;
      max-width: 100%;
      margin: 0; }
    .banner-contact-us img.shift-right {
      max-width: 100%; } }
  .banner-contact-us .contact-form {
    margin-bottom: 60px; }
    @media (max-width: 575px) {
      .banner-contact-us .contact-form {
        margin-bottom: 0; } }
  @media (max-width: 767px) {
    .banner-contact-us .contact-form .mt-15 {
      margin-top: 5px !important; } }
  .banner-contact-us .contact-form .form-control {
    min-height: 42px;
    padding-top: 9px;
    padding-bottom: 9px;
    line-height: 18px; }
  .banner-contact-us .contact-form textarea.form-control {
    min-height: 125px; }
    @media (max-width: 1023px) {
      .banner-contact-us .contact-form textarea.form-control {
        min-height: 70px; } }
  .banner-contact-us .order-2 {
    position: relative;
    z-index: 1; }
  .banner-contact-us .order-1 {
    position: relative;
    z-index: 0; }
  .banner-contact-us [data-title] {
    margin-bottom: 26px;
    display: inline-flex;
    position: relative;
    z-index: 0;
    line-height: 1.2em; }
    @media (max-width: 767px) {
      .banner-contact-us [data-title] {
        width: 100%;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto; } }
    .banner-contact-us [data-title] span {
      position: relative;
      z-index: 1; }
    .banner-contact-us [data-title]:after {
      position: absolute;
      z-index: 0;
      right: -.8em;
      top: -.52em;
      font-size: 120px;
      line-height: 1em;
      color: #f6f7f5;
      white-space: nowrap;
      content: attr(data-title); }
      @media (max-width: 1023px) {
        .banner-contact-us [data-title]:after {
          top: 0; } }
      @media (max-width: 767px) {
        .banner-contact-us [data-title]:after {
          font-size: 80px; } }
      @media (max-width: 575px) {
        .banner-contact-us [data-title]:after {
          top: -10px;
          font-size: 70px;
          left: 50%;
          right: auto;
          transform: translateX(-50%); } }
    .banner-contact-us [data-title].title-left:after {
      right: auto;
      left: -.25em;
      top: -.35em; }
      @media (max-width: 575px) {
        .banner-contact-us [data-title].title-left:after {
          left: 50%; } }
    .banner-contact-us [data-title] + * {
      position: relative;
      z-index: 1; }

.banner-appointment-form {
  background-position: center center;
  background-repeat: no-repeat; }
  .banner-appointment-form .contact-form {
    margin-bottom: 60px; }
    @media (max-width: 575px) {
      .banner-appointment-form .contact-form {
        margin-bottom: 0; } }
  @media (max-width: 767px) {
    .banner-appointment-form .contact-form .mt-15 {
      margin-top: 5px !important; } }
  .banner-appointment-form .contact-form .form-control {
    min-height: 42px;
    padding-top: 9px;
    padding-bottom: 9px;
    line-height: 18px; }
  .banner-appointment-form .contact-form textarea.form-control {
    min-height: 125px; }
    @media (max-width: 1023px) {
      .banner-appointment-form .contact-form textarea.form-control {
        min-height: 70px; } }
  .banner-appointment-form .order-2 {
    position: relative;
    z-index: 1; }
  .banner-appointment-form .order-1 {
    position: relative;
    z-index: 0; }
  .banner-appointment-form .double-title:after {
    z-index: -1; }
  @media (max-width: 1023px) {
    .banner-appointment-form .double-title--center:after {
      left: 85%; } }

@media (max-width: 1023px) {
  .banner-appointment-form-image {
    max-height: 530px; } }
@media (max-width: 767px) {
  .banner-appointment-form-image {
    max-height: none;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto; } }

.faq-item {
  margin-bottom: 10px;
  border: 0; 
}
.accordion-header{
    display: flex;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--fontFamily);
    line-height: 1em;
    background-color: #fff;
    color: #444;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); 
    
    .accordion-button{
        font-weight: 600;
        color: #444;
    }
}
.accordion-header span:first-child {
      font-size: 16px;
      min-width: 26px;
      font-weight: 600;
      color: var(--mainColor); }
      @media (max-width: 767px) {
        .accordion-header span:first-child {
          font-size: 14px; } }
    .accordion-header:hover {
      text-decoration: none; }

  .faq-item > a:not(.collapsed) {
    color: #444; }
  .faq-item .faq-item-content > * {
    padding: 10px 40px 10px 50px; }
    @media (max-width: 1023px) {
      .faq-item .faq-item-content > * {
        padding: 10px 35px 10px 45px; } }
    @media (max-width: 767px) {
      .faq-item .faq-item-content > * {
        padding: 14px 15px 10px 35px; } }

.faq-accordion {
  margin: 15px 0 0; }
  @media (max-width: 1023px) {
    .faq-accordion {
      margin-top: 5px; } }
  @media (max-width: 767px) {
    .faq-accordion {
      margin-top: 0; } }

  @media (max-width: 1229px) {
    .accordion-header {
      font-size: 18px;
      padding: 18px 42px 18px 20px; } }
  @media (max-width: 1023px) {
    .accordion-header {
      font-size: 14px;
      line-height: 20px;
      padding: 18px 42px 18px 20px; } }
  @media (max-width: 767px) {
    .accordion-header {
      font-size: 14px;
      line-height: 20px;
      padding: 6px 40px 6px 12px; } }

.faq-accordion--style2 .faq-item {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05); }
.faq-accordion--style2 .accordion-header {
  box-shadow: none;
  font-family: var(--fontFamily2); }
.faq-accordion--style2 .faq-item .faq-item-content > * {
  padding-top: 0;
  padding-bottom: 20px; }

.faq-wrap {
  padding: 65px 100px 65px 80px;
  position: relative; }
  .faq-wrap .double-title:after {
    z-index: 0;
    color: #ffffff; }
  @media (max-width: 1023px) {
    .faq-wrap {
      padding: 55px 15px; }
      .faq-wrap .accordion-header:after {
        right: 15px; } }
  @media (max-width: 767px) {
    .faq-wrap {
      padding: 25px 15px; } }
  .faq-wrap .nav-pills {
    flex-wrap: nowrap;
    margin-left: auto; }
    @media (max-width: 1023px) {
      .faq-wrap .nav-pills {
        margin-top: 13px; } }
    @media (max-width: 767px) {
      .faq-wrap .nav-pills {
        margin-right: auto;
        padding-left: 0;
        flex-wrap: wrap; } }
    .faq-wrap .nav-pills .nav-link {
      position: relative;
      min-width: inherit;
      height: 39px;
      margin: 0 20px 0 0;
      padding: 0 20px 0 0;
      font-size: 15px;
      line-height: 18px;
      font-weight: 600;
      font-family: var(--fontFamily);
      letter-spacing: 1px;
      border: 0;
      background: transparent;
      color: #444;
      box-shadow: none; }
      .faq-wrap .nav-pills .nav-link:hover {
        background: transparent !important;
        color: #000; }
      @media (max-width: 1023px) {
        .faq-wrap .nav-pills .nav-link {
          font-size: 13px; } }
      @media (max-width: 575px) {
        .faq-wrap .nav-pills .nav-link {
          width: auto; } }
      .faq-wrap .nav-pills .nav-link:after {
        content: '';
        position: absolute;
        top: 4px;
        right: 0;
        width: 2px;
        height: 9px;
        background: #a9aaaa; }
      .faq-wrap .nav-pills .nav-link:last-child {
        margin: 0;
        padding: 0; }
        .faq-wrap .nav-pills .nav-link:last-child:after {
          display: none; }
    .faq-wrap .nav-pills .nav-link.active {
      color: var(--mainColor); }
    .faq-wrap .nav-pills [class*='icon'] {
      padding-right: 10px;
      color: var(--mainColor); }
  .faq-wrap .nav-pills--noborder .nav-link {
    margin: 0 26px 0 0;
    padding: 0;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0;
    color: #444; }
    @media (max-width: 1229px) {
      .faq-wrap .nav-pills--noborder .nav-link {
        margin-right: 18px; } }
    @media (max-width: 1023px) {
      .faq-wrap .nav-pills--noborder .nav-link {
        font-size: 15px; } }
    @media (max-width: 575px) {
      .faq-wrap .nav-pills--noborder .nav-link {
        font-size: 13px; } }
    .faq-wrap .nav-pills--noborder .nav-link:after {
      display: none; }
    .faq-wrap .nav-pills--noborder .nav-link:last-child {
      margin: 0; }
    @media (max-width: 575px) {
      .faq-wrap .nav-pills--noborder .nav-link {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .faq-wrap .nav-pills--noborder .nav-link [class*='icon'] {
          padding: 0 0 5px; } }

.faq-wrap--pad-lg {
  padding: 95px 100px 95px 80px; }
  @media (max-width: 1023px) {
    .faq-wrap--pad-lg {
      padding: 65px 15px; } }
  @media (max-width: 767px) {
    .faq-wrap--pad-lg {
      padding: 25px 15px; } }

.faq-wrap--pad-md {
  padding: 25px 15px 65px 110px;
  max-width: 855px; }
  @media (max-width: 1229px) {
    .faq-wrap--pad-md {
      padding: 25px 15px; } }
  @media (max-width: 767px) {
    .faq-wrap--pad-md {
      padding: 25px 15px; } }

.faq-wrap-bg {
  background: url("../images/bg-grey.jpg") #f6f7f5; }
  @media (min-width: 1230px) {
    .faq-wrap-bg {
      margin-left: -65px;
      width: calc(100% + 65px); } }

body:not(.touch) .faq-wrap .nav-link:hover {
  background: #fff;
  border-color: #e6e6e6; }

.collapse-wrap {
  cursor: pointer;
  margin-bottom: 30px; }
  @media (max-width: 1023px) {
    .collapse-wrap {
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .collapse-wrap {
      margin-bottom: 0; } }
  .collapse-wrap > * {
    margin-bottom: 0; }
  .collapse-wrap .collapse-title {
    font-size: 26px; }
    @media (max-width: 1023px) {
      .collapse-wrap .collapse-title {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .collapse-wrap .collapse-title {
        font-size: 18px; } }
  .collapse-wrap.collapsed [class*='icon-'] {
    position: relative;
    top: 3px;
    display: block;
    transform: rotate(180deg); }
  .collapse-wrap:hover .collapse-title {
    color: var(--mainColor); }
  .collapse-wrap [class*='icon-'] {
    font-size: 12px;
    color: var(--mainColor); }

@media (max-width: 575px) {
  .collapse .row-sm-space .col-sm-4:not(:last-child) img {
    margin-bottom: 15px; } }
@media (max-width: 575px) and (min-width: 320px) {
  .collapse .row-sm-space .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%; } }
.newsletter-card {
  //background: url(../images/newsletter-bg.jpg) repeat right center;
  border-top: 4px solid var(--mainColor);
  overflow: hidden;
  padding: 36px 40px 50px;
  color: #fff;
  font-size: 15px;
  line-height: 22px; }

@media (max-width: 1023px) {
  .newsletter-card {
    padding: 25px 25px 25px; }
    .newsletter-card h2 {
      margin-top: -10px;
      font-size: 50px; }
    .newsletter-card form {
      display: flex; }
      .newsletter-card form .btn {
        margin-left: 8px;
        min-height: 42px;
        padding: 7px 20px; }
      .newsletter-card form .form-control {
        min-height: 42px;
        padding: 7px 12px; } }
@media (max-width: 767px) {
  .newsletter-card {
    padding: 20px 15px 20px;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px); }
    .newsletter-card h2 {
      margin-top: 0;
      font-size: 44px; }
    .newsletter-card form {
      flex-direction: column; }
      .newsletter-card form .btn {
        margin-left: 0;
        margin-top: 10px; } }
.modal-dialog {
  max-width: 800px;
  margin: 35px auto; }

.modal .close {
  position: absolute;
  right: -32px;
  top: -32px;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  opacity: 1;
  text-shadow: 0;
  padding: 12px 12px; }
  .modal .close:hover {
    opacity: .8; }

.modal-body {
  padding: 5px; }

.modal-content {
  border: 0;
  border-radius: 0; }

.modal-form .modal-dialog {
  max-width: 544px; }
  @media (max-width: 767px) {
    .modal-form .modal-dialog {
      max-width: calc(100% - 20px); } }

.modal-form-sm .modal-dialog {
  max-width: 375px; }
  @media (max-width: 767px) {
    .modal-form-sm .modal-dialog {
      max-width: calc(100% - 20px); } }

.modal-form .modal-content .form-control + * {
  margin-top: 10px; }
.modal-form .modal-content textarea {
  min-height: 98px; }

.modal-form .modal-body {
  padding: 0 35px 35px; }
  @media (max-width: 767px) {
    .modal-form .modal-body {
      padding: 20px; } }
  @media (max-width: 767px) {
    .modal-form .modal-body {
      padding: 8px; } }
  .modal-form .modal-body .form-group {
    margin-bottom: 0; }
  .modal-form .modal-body .popover-content {
    position: relative;
    border-color: #efefef;
    margin-top: -3px;
    border-radius: 0;
    bottom: auto !important; }

.modal-form .close {
  font-size: 30px;
  z-index: 1;
  right: 0;
  top: 0;
  color: #e8e8e8;
  transition: all 0.15s ease-out; }
  .modal-form .close:hover {
    color: var(--mainColor); }

.modal-backdrop {
  z-index: 1030; }

.modal.fade .modal-dialog {
  transition: opacity 0.3s ease-out;
  transform: translate(0, 0); }

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  overflow-x: hidden; }
  @media (max-width: 768px) {
    .modal-dialog {
      min-height: calc(100vh - 20px); } }

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.mfp-content .mfp-close {
  font-size: 0;
  width: 40px;
  top: -25px;
  right: -27px; }

.mfp-content .mfp-close:after {
  width: 40px;
  height: 30px;
  content: '\e937';
  text-align: center;
  font-size: 15px;
  font-family: 'dentco';
  line-height: 30px; }

.mfp-arrow:after {
  width: 40px;
  height: 40px;
  content: '\e93d';
  text-align: center;
  font-size: 35px;
  font-family: 'hotelfont';
  line-height: 35px;
  border: 0;
  color: #fff; }

.mfp-arrow.mfp-arrow-right {
  right: 15px; }

.mfp-arrow.mfp-arrow-left:after {
  content: '\e93c'; }

.slider-gallery,
.slider-single {
  overflow: hidden; }

.slider-gallery-main,
.slider-gallery-thumbs,
.slider-single {
  opacity: 0;
  transition: opacity 0.5s; }
  .slider-gallery-main li,
  .slider-gallery-thumbs li,
  .slider-single li {
    margin-bottom: 0 !important; }
  .slider-gallery-main.slick-initialized,
  .slider-gallery-thumbs.slick-initialized,
  .slider-single.slick-initialized {
    opacity: 1; }

.slider-gallery-main {
  margin-bottom: 13px; }

.slider-gallery-thumbs {
  margin-bottom: 0; }

.slider-gallery-thumbs {
  margin-left: -5px;
  margin-right: -5px; }
  .slider-gallery-thumbs li {
    margin: 0 5px;
    cursor: pointer;
    background-color: #212121; }
  .slider-gallery-thumbs .slick-slide img {
    opacity: .5;
    transition: 0.2s;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    margin-bottom: -1px; }
  .slider-gallery-thumbs .slick-slide:not(.slick-active) + .slick-slide.slick-active img,
  .slider-gallery-thumbs .slick-slide:hover img {
    opacity: 1; }

.slider-gallery .slick-prev, .slider-gallery .slick-next {
  top: 50%;
  display: block;
  width: 30px;
  height: 75px;
  margin-top: -37px;
  cursor: pointer;
  color: var(--mainColor);
  outline: none;
  background-color: #fff;
  text-align: center;
  transition: 0.2s;
  border-radius: 10px 0 0 10px;
  border: 0; }
.slider-gallery .slick-prev {
  border-radius: 0 10px 10px 0; }
.slider-gallery .slick-prev:before, .slider-gallery .slick-next:before {
  font-size: 13px;
  line-height: 75px; }
.slider-gallery .slick-next:before {
  content: "\e930";
  padding-left: 4px; }
.slider-gallery .slick-prev:before {
  content: "\e922";
  padding-right: 2px; }

.slider-gallery-thumbs .slick-prev, .slider-gallery-thumbs .slick-next {
  height: 54px;
  margin-top: -27px; }
.slider-gallery-thumbs .slick-prev {
  left: 5px; }
.slider-gallery-thumbs .slick-next {
  right: 5px; }
.slider-gallery-thumbs .slick-prev:before, .slider-gallery-thumbs .slick-next:before {
  line-height: 56px; }

.filters-toggle {
  position: absolute;
  z-index: 1000;
  top: 18px;
  right: 0;
  display: block;
  width: 36px;
  height: 36px;
  background: var(--mainColor);
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  cursor: pointer; }
  .filters-toggle:hover {
    background: #787878;
    text-decoration: none;
    color: #fff; }
  @media (max-width: 1229px) {
    .filters-toggle {
      top: 12px; } }
  @media (max-width: 1023px) {
    .filters-toggle {
      top: 8px;
      right: 0; } }
  @media (max-width: 767px) {
    .filters-toggle {
      top: 0; } }

.filters-slide {
  position: fixed;
  top: 0;
  right: -304px;
  height: 100vh;
  padding: 15px 0 0;
  z-index: 1045;
  width: 304px;
  height: 100%;
  color: #fff;
  transition: all 0.3s;
  background: var(--mainColor); }
  .filters-slide h3,
  .filters-slide h4 {
    color: #fff; }

.filters-slide-inside {
  height: 100%;
  overflow-y: scroll;
  padding: 0 32px 0; }

.filters-slide-close {
  position: absolute;
  top: 0px;
  right: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  z-index: 1; }
  .filters-slide-close:hover {
    opacity: .5; }

.filters-slide.active {
  right: 0; }

.noUi-horizontal {
  height: 6px; }

.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  left: -7.5px;
  top: -5px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(30, 118, 189, 0.5); }

.noUi-target {
  border-radius: 0;
  border: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 12px; }

.noUi-origin {
  border-radius: 0;
  box-shadow: none; }

.noUi-connect {
  background: rgba(255, 255, 255, 0.7);
  border: 0;
  border-radius: 0;
  height: 6px;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

.noUi-handle:before,
.noUi-handle:after {
  display: none;
  border: 0;
  border-radius: 0;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

.side-block h5 {
  bottom: 0; }
.side-block label {
  width: 100%;
  font-size: 14px;
  line-height: 28px; }

.side-block + .side-block {
  margin-top: 30px; }

.overlay {
  position: fixed;
  top: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0); }

.btn-filter {
  background: #fff;
  border: 2px solid #fff;
  padding: 8px 24px;
  color: var(--mainColor); }
  .btn-filter:hover {
    background: transparent;
    color: #fff; }

.btn-filter-invert {
  background: transparent;
  color: #fff; }
  .btn-filter-invert:hover {
    background: #fff;
    color: var(--mainColor); }

.rating-box {
  margin-top: -5px;
  font-size: 26px;
  line-height: 42px;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: #444;
  text-align: center; }
  .rating-box > *:not(:first-child) {
    margin: 0 0 12px; }
    @media (max-width: 767px) {
      .rating-box > *:not(:first-child) {
        margin: 0; } }
  @media (max-width: 1023px) {
    .rating-box {
      flex-direction: column;
      justify-content: center;
      text-align: center; }
      .rating-box > * {
        padding: 7px 0; } }
  @media (max-width: 1023px) {
    .rating-box {
      font-size: 22px;
      line-height: 30px; } }
  .rating-box .rating-number {
    font-size: 61px;
    line-height: 1em;
    font-weight: 600;
    color: var(--mainColor); }
  .rating-box .star-rating [class*='icon-'] {
    font-size: 16px;
    padding-right: 2px; }

.review-box {
  border: 1px solid #e2e2e2;
  padding: 22px 56px 30px 26px; }
  @media (max-width: 1023px) {
    .review-box {
      padding: 22px 36px 30px 26px; } }
  @media (max-width: 767px) {
    .review-box {
      padding: 20px; } }
  .review-box-rating [class*='icon-'] {
    color: #cecece;
    padding-right: 2px;
    font-size: 14px; }
  .review-box-rating + * {
    margin-top: 26px; }
  .review-box-author, .review-box-arrival {
    position: relative;
    padding-left: 35px;
    font-size: 14px;
    line-height: 26px; }
    .review-box-author b, .review-box-arrival b {
      font-weight: 600; }
    .review-box-author [class*='icon-'], .review-box-arrival [class*='icon-'] {
      position: absolute;
      top: 5px;
      left: 0;
      font-size: 18px;
      color: #cacaca; }
    .review-box-author + *, .review-box-arrival + * {
      margin-top: 26px; }
  .review-box-title > * {
    margin-bottom: 0; }
  .review-box-text {
    font-size: 15px;
    line-height: 28px; }
  .review-box-date {
    font-size: 14px;
    line-height: 28px;
    color: #959595;
    white-space: nowrap; }
  .review-box-bot {
    font-size: 14px;
    line-height: 26px; }
    .review-box-bot b {
      font-weight: 600; }
    .review-box-bot [class*='icon-'] {
      position: relative;
      top: 1px;
      color: var(--mainColor);
      padding-right: 15px; }
  .review-box-text:not(:first-child), .review-box-title:not(:first-child), .review-box-bot:not(:first-child) {
    margin-top: 26px; }
  .review-box-helpful > * {
    padding-right: 10px; }
  .review-box-helpful a {
    padding-right: 4px; }

.review-box + * {
  margin-top: 25px; }

.reviews-wrap {
  max-width: 585px;
  height: 100%;
  padding: 40px 60px 10px 42px; }
  @media (max-width: 1023px) {
    .reviews-wrap {
      padding: 10px 80px 10px 35px; } }
  @media (max-width: 767px) {
    .reviews-wrap {
      padding: 27px 15px; } }
  .reviews-wrap > * {
    width: 100%; }
  .reviews-wrap .title-wrap {
    position: relative;
    z-index: 2; }

.reviews-wrap-alt {
  max-width: 585px;
  height: 100%;
  padding: 65px 70px 65px 42px; }
  @media (max-width: 1229px) {
    .reviews-wrap-alt {
      padding: 25px 80px 25px 0; }
      .reviews-wrap-alt .reviews-text-carousel:before {
        display: none; } }
  @media (max-width: 767px) {
    .reviews-wrap-alt {
      max-width: none;
      padding: 15px 0; } }
  .reviews-wrap-alt > * {
    width: 100%;
    z-index: 2; }

.review p:last-child {
  margin-bottom: 0; }
.review-author-position {
  padding: 0 1px;
  font-style: italic;
  color: var(--mainColor); }
.review .star-rating:not(:first-child) {
  margin-left: 15px; }

.reviews-text-carousel .review-author {
  text-transform: none; }

.reviews-author-carousel {
  margin-bottom: 0 !important; }

.reviews-carousel {
  margin-top: 25px;
  position: relative;
  z-index: 0; }
  @media (max-width: 1023px) {
    .reviews-carousel {
      margin-top: 10px; } }
  @media (max-width: 767px) {
    .reviews-carousel {
      margin-top: 5px; } }
  .reviews-carousel .review-text {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 2em; }
    @media (max-width: 1023px) {
      .reviews-carousel .review-text {
        font-size: 16px;
        line-height: 27px; } }
    @media (max-width: 767px) {
      .reviews-carousel .review-text {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 24px;
        text-align: left; } }
  .reviews-carousel:before {
    content: '„';
    position: absolute;
    right: -65px;
    top: -850px;
    font-size: 850px;
    line-height: 1em;
    font-weight: 400;
    font-family: var(--fontFamily);
    color: #fff; }
    @media (max-width: 1229px) {
      .reviews-carousel:before {
        font-size: 740px;
        top: -740px; } }
    @media (max-width: 1023px) {
      .reviews-carousel:before {
        font-size: 720px;
        top: -720px;
        right: -35px; } }
    @media (max-width: 767px) {
      .reviews-carousel:before {
        font-size: 620px;
        top: -620px; } }
  .reviews-carousel .slick-prev,
  .reviews-carousel .slick-next {
    position: absolute;
    bottom: 0;
    top: auto;
    display: inline-block;
    margin: 85px 0 0 0; }
  .reviews-carousel .slick-next {
    margin-left: 6px; }
  .reviews-carousel .slick-dots {
    margin: 50px 0 -25px;
    text-align: right; }
    @media (max-width: 767px) {
      .reviews-carousel .slick-dots {
        margin: 30px 0 -25px;
        text-align: center; } }

.order-1 .reviews-carousel .slick-dots {
  text-align: left; }
  @media (max-width: 767px) {
    .order-1 .reviews-carousel .slick-dots {
      text-align: center; } }

.reviews-wrap-alt .reviews-carousel .review-text {
  max-height: 81px;
  overflow: hidden;
  font-size: 18px;
  line-height: 27px; }
  .reviews-wrap-alt .reviews-carousel .review-text:not(:first-child) {
    margin-top: 20px; }
  @media (max-width: 1023px) {
    .reviews-wrap-alt .reviews-carousel .review-text {
      font-size: 16px;
      line-height: 27px; } }
  @media (max-width: 767px) {
    .reviews-wrap-alt .reviews-carousel .review-text {
      max-height: 72px;
      font-size: 15px;
      line-height: 24px;
      text-align: left; } }
.reviews-wrap-alt .reviews-carousel .star-rating {
  margin-top: 7px; }
.reviews-wrap-alt .reviews-carousel .slick-prev,
.reviews-wrap-alt .reviews-carousel .slick-next {
  margin-top: 35px; }

.reviews-text-carousel {
  position: relative; }
  .reviews-text-carousel:before {
    content: '“';
    position: absolute;
    left: -40px;
    top: -65px;
    font-size: 160px;
    line-height: 1em;
    font-weight: 400;
    color: var(--mainColor); }
    @media (max-width: 1229px) {
      .reviews-text-carousel:before {
        left: -25px;
        top: -60px;
        font-size: 140px; } }
    @media (max-width: 1023px) {
      .reviews-text-carousel:before {
        left: -30px;
        top: -50px;
        font-size: 120px; } }
    @media (max-width: 767px) {
      .reviews-text-carousel:before {
        left: -3px;
        top: -57px;
        font-size: 120px; } }
  .reviews-text-carousel .slick-prev,
  .reviews-text-carousel .slick-next {
    position: relative;
    bottom: 0;
    top: auto;
    display: inline-block;
    margin: 85px 0 0 0; }
  .reviews-text-carousel .slick-next {
    margin-left: 6px; }

.reviews-author-carousel {
  display: flex;
  height: 100%; }
  .reviews-author-carousel > div {
    display: flex;
    position: relative;
    text-align: right;
    height: 100%; }
    @media (max-width: 767px) {
      .reviews-author-carousel > div {
        margin: 0 auto; } }
    .reviews-author-carousel > div .img-after img {
      display: block;
      float: right;
      transform: translateX(130px);
      margin-top: 35px; }
      @media (max-width: 1229px) {
        .reviews-author-carousel > div .img-after img {
          transform: translateX(100px);
          margin-top: 0; } }
      @media (max-width: 1023px) {
        .reviews-author-carousel > div .img-after img {
          transform: translateX(-60px);
          height: 100%;
          width: auto;
          max-width: none;
          float: none; } }
      @media (min-width: 576px) and (max-width: 767px) {
        .reviews-author-carousel > div .img-after img {
          height: auto;
          width: 350px;
          margin: 0 auto;
          float: none;
          transform: translateX(0); } }
      @media (max-width: 575px) {
        .reviews-author-carousel > div .img-after img {
          transform: translateX(70px);
          height: auto;
          width: 350px; } }
    .reviews-author-carousel > div .img-before {
      position: absolute;
      top: 60%;
      transform: translateY(-50%);
      border: 3px solid #fff;
      border-radius: 50%;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); }
      @media (max-width: 1229px) {
        .reviews-author-carousel > div .img-before {
          top: 66%;
          left: -20px; } }
      @media (max-width: 1023px) {
        .reviews-author-carousel > div .img-before {
          top: 52%;
          left: -70px; } }
      @media (min-width: 576px) and (max-width: 767px) {
        .reviews-author-carousel > div .img-before {
          top: 58%;
          left: 50%;
          margin-left: -225px; } }
      @media (max-width: 575px) {
        .reviews-author-carousel > div .img-before {
          top: 58%;
          left: -34px; } }
      .reviews-author-carousel > div .img-before img {
        border-radius: 50%; }
        @media (max-width: 1229px) {
          .reviews-author-carousel > div .img-before img {
            max-width: 170px; } }
        @media (max-width: 767px) {
          .reviews-author-carousel > div .img-before img {
            max-width: 140px; } }
      .reviews-author-carousel > div .img-before span {
        position: absolute;
        z-index: 1;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        padding: 0 7px;
        font-size: 13px;
        line-height: 19px;
        font-weight: 500; }
  .reviews-author-carousel .slick-list {
    overflow: visible; }

@media (min-width: 576px) {
  .reviews-photo.order-0 {
    display: flex;
    justify-content: flex-end; } }
@media (max-width: 1023px) {
  .reviews-photo img {
    max-height: 450px; } }
@media (max-width: 575px) {
  .reviews-photo img {
    max-width: 100%; } }
.star-rating [class*='icon-'] {
  font-size: 11px; }

.reviews-style2 .reviews-carousel {
  position: relative; }
  .reviews-style2 .reviews-carousel:before {
    content: '“';
    position: absolute;
    top: -.25em;
    left: -5px;
    margin-top: -34px;
    font-size: 200px;
    line-height: 1em;
    font-family: var(--fontFamily);
    color: var(--mainColor);
    font-weight: bold; }
    @media (max-width: 1023px) {
      .reviews-style2 .reviews-carousel:before {
        font-size: 120px;
        margin-top: -30px; } }
    @media (max-width: 767px) {
      .reviews-style2 .reviews-carousel:before {
        font-size: 140px;
        margin-top: -20px; } }
  .reviews-style2 .reviews-carousel .review-text {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
    font-style: italic;
    color: #424242; }
    @media (max-width: 1229px) {
      .reviews-style2 .reviews-carousel .review-text {
        font-size: 18px;
        line-height: 28px; } }
    @media (max-width: 1023px) {
      .reviews-style2 .reviews-carousel .review-text {
        font-size: 16px;
        line-height: 24px; } }
  .reviews-style2 .reviews-carousel .slick-prev, .reviews-style2 .reviews-carousel .slick-next {
    margin-top: 35px; }
    @media (max-width: 1229px) {
      .reviews-style2 .reviews-carousel .slick-prev, .reviews-style2 .reviews-carousel .slick-next {
        margin-top: 20px; } }
  .reviews-style2 .reviews-carousel .slick-dots {
    margin: 35px 0 -25px; }
.reviews-style2.reviews-wrap {
  max-width: 660px;
  height: 100%;
  padding: 40px 60px 10px 63px; }
  @media (max-width: 1023px) {
    .reviews-style2.reviews-wrap {
      padding: 85px 30px 30px; } }
  @media (max-width: 767px) {
    .reviews-style2.reviews-wrap {
      padding: 85px 15px 30px; } }

.reviews-left, .reviews-right {
  width: 30%;
  background-size: auto 100%;
  background-repeat: no-repeat; }
  @media (max-width: 1229px) {
    .reviews-left, .reviews-right {
      width: 25%;
      position: absolute;
      top: 0;
      bottom: 0; } }

.reviews-left {
  background-position: left; }
  @media (max-width: 1229px) {
    .reviews-left {
      background-position: right;
      left: 0; } }
  @media (max-width: 767px) {
    .reviews-left {
      position: relative;
      height: 380px;
      width: 72%;
      background-position: left; } }
  @media (max-width: 575px) {
    .reviews-left {
      background-position: right; } }

.reviews-right {
  background-position: right; }
  @media (max-width: 1229px) {
    .reviews-right {
      right: 0;
      background-position: left; } }
  @media (max-width: 767px) {
    .reviews-right {
      order: -1;
      height: 380px;
      width: 50%;
      background-position: right; } }
  @media (max-width: 767px) {
    .reviews-right {
      background-position: left; } }

.reviews-title {
  position: relative; }
  .reviews-title span {
    position: relative;
    z-index: 1; }

.reviews-title:after {
  position: absolute;
  z-index: -1;
  right: 0;
  left: 0;
  top: -.65em;
  font-size: 120px;
  line-height: 1em;
  white-space: nowrap;
  content: attr(data-title);
  color: #f6f7f5; }
  @media (max-width: 1229px) {
    .reviews-title:after {
      font-size: 100px; } }
  @media (max-width: 575px) {
    .reviews-title:after {
      font-size: 70px; } }

.reviews-title-icon {
  content: '“';
  position: absolute;
  top: -.25em;
  left: 50%;
  margin-top: -34px;
  font-size: 200px;
  line-height: 1em;
  font-family: var(--fontFamily);
  color: #707070;
  font-weight: bold;
  transform: translateX(-50%); }
  @media (max-width: 1023px) {
    .reviews-title-icon {
      font-size: 120px;
      margin-top: -30px; } }
  @media (max-width: 767px) {
    .reviews-title-icon {
      font-size: 140px;
      margin-top: -20px; } }

.reviews-style3-wrap {
  display: flex; }
  @media (max-width: 767px) {
    .reviews-style3-wrap {
      flex-direction: column; } }

.reviews-style3 {
  max-width: 750px;
  padding: 170px 0 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (max-width: 1229px) {
    .reviews-style3 {
      max-width: 100%;
      padding: 85px 25% 25px; } }
  @media (max-width: 1023px) {
    .reviews-style3 {
      padding: 65px 23% 25px; } }
  @media (max-width: 767px) {
    .reviews-style3 {
      padding: 65px 15px 0; } }
  .reviews-style3 .reviews-carousel {
    position: relative;
    margin-top: 30px; }
    .reviews-style3 .reviews-carousel:before {
      display: none; }
    .reviews-style3 .reviews-carousel .review-text {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;
      font-style: italic;
      color: #424242; }
      @media (max-width: 1229px) {
        .reviews-style3 .reviews-carousel .review-text {
          font-size: 18px;
          line-height: 28px; } }
      @media (max-width: 1023px) {
        .reviews-style3 .reviews-carousel .review-text {
          font-size: 16px;
          line-height: 24px; } }
      @media (max-width: 767px) {
        .reviews-style3 .reviews-carousel .review-text {
          text-align: center; } }
    .reviews-style3 .reviews-carousel .slick-dots {
      margin: 45px 0 -25px;
      text-align: center; }

.reviews-style4 {
  max-width: 750px;
  padding: 170px 0 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (max-width: 1229px) {
    .reviews-style4 {
      max-width: 100%;
      padding: 65px 0; } }
  @media (max-width: 1023px) {
    .reviews-style4 {
      padding: 55px 0; } }
  @media (max-width: 767px) {
    .reviews-style4 {
      padding: 55px 0 25px;
      text-align: center; } }
  .reviews-style4 .reviews-carousel {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .reviews-style4 .reviews-carousel {
        margin-bottom: 0; } }
    .reviews-style4 .reviews-carousel:before {
      display: none; }
    .reviews-style4 .reviews-carousel .review-text {
      overflow: hidden;
      padding: 0 4px;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 30px;
      font-style: italic;
      color: #424242; }
      @media (max-width: 1229px) {
        .reviews-style4 .reviews-carousel .review-text {
          font-size: 18px;
          line-height: 28px; } }
      @media (max-width: 1023px) {
        .reviews-style4 .reviews-carousel .review-text {
          font-size: 16px;
          line-height: 24px; } }
      @media (max-width: 767px) {
        .reviews-style4 .reviews-carousel .review-text {
          text-align: center;
          margin-bottom: 20px; } }
    .reviews-style4 .reviews-carousel .slick-dots {
      margin: 32px 0 -25px;
      text-align: right; }
      @media (max-width: 767px) {
        .reviews-style4 .reviews-carousel .slick-dots {
          text-align: center;
          margin-top: 25px; } }
  @media (max-width: 1229px) {
    .reviews-style4-image-right {
      max-height: 500px; } }
  @media (max-width: 767px) {
    .reviews-style4-image-right {
      max-height: 300px; } }
  @media (max-width: 575px) {
    .reviews-style4-image-right {
      max-height: none;
      width: 115%; } }

.testimonial-wrap {
  margin: 25px auto 75px; }

.testimonial {
  position: relative;
  padding: 45px 50px 40px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 767px) {
    .testimonial {
      padding: 35px 30px 20px; } }
  .testimonial > * {
    margin-bottom: 0; }
  .testimonial > *:not(:first-child) {
    margin-top: 20px; }
    @media (max-width: 767px) {
      .testimonial > *:not(:first-child) {
        margin-top: 10px; } }
  .testimonial-photo {
    display: inline-block;
    position: relative;
    background-color: #fff;
    width: 104px;
    border-radius: 50%; }
    .testimonial-photo img {
      display: inline-block;
      width: 100%;
      border-radius: 50%;
      z-index: 1;
      overflow: hidden; }
    .testimonial-photo img + img {
      opacity: 0;
      position: absolute;
      width: 104px;
      height: 104px;
      left: 0;
      top: 0;
      transition: 0.35s;
      transform: rotate(-45deg); }
  .testimonial:hover .testimonial-photo img + img {
    opacity: 1;
    width: 204px;
    height: 204px;
    left: 90%;
    top: -35px;
    transform: rotate(0deg); }
    @media (max-width: 1229px) {
      .testimonial:hover .testimonial-photo img + img {
        width: 150px;
        height: 150px;
        left: 80%;
        top: -25px; } }
    @media (max-width: 767px) {
      .testimonial:hover .testimonial-photo img + img {
        width: 120px;
        height: 120px;
        left: 55%;
        top: -40px; } }
  .testimonial:before {
    position: absolute;
    top: 100%;
    left: 32px;
    visibility: visible;
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    border: 0 solid transparent;
    border-left-width: 0px;
    border-right-width: 64px;
    border-top: 30px solid #fff;
    -webkit-filter: drop-shadow(0 12px 10px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 12px 10px rgba(0, 0, 0, 0.1)); }
  .testimonial-photo + .testimonial-title {
    margin-top: 20px; }
    @media (max-width: 1023px) {
      .testimonial-photo + .testimonial-title {
        margin-top: 15px; } }
  .testimonial-title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: #444; }
    @media (max-width: 1023px) {
      .testimonial-title {
        font-size: 18px;
        line-height: 26px; } }
    @media (max-width: 767px) {
      .testimonial-title {
        font-size: 16px;
        line-height: 22px; } }
  .testimonial-name {
    font-size: 16px;
    line-height: 1em;
    font-weight: 400; }
    @media (max-width: 767px) {
      .testimonial-name {
        font-size: 14px; } }
  .testimonial-position {
    font-size: 16px;
    line-height: 1em;
    font-weight: 400;
    font-style: italic;
    color: var(--mainColor); }
    @media (max-width: 767px) {
      .testimonial-position {
        font-size: 14px; } }
  .testimonial .star-rating:not(:first-child) {
    margin-top: 15px; }
  .testimonial p {
    font-size: 15px;
    line-height: 24px; }
    @media (max-width: 767px) {
      .testimonial p {
        font-size: 14px;
        line-height: 20px; } }

.testimonial-bg1 {
  background: url("../images/bg-grey.jpg");
  box-shadow: none; }
  .testimonial-bg1:before {
    border-top-color: #f4f4f4;
    -webkit-filter: none;
    filter: none; }

.testimonial-bg2 {
  background: #44b0ee;
  color: #fff;
  box-shadow: none; }
  .testimonial-bg2:before {
    border-top-color: #44b0ee;
    -webkit-filter: none;
    filter: none; }
  .testimonial-bg2 .testimonial-title {
    color: #fff; }
  .testimonial-bg2 .star-rating {
    color: #0059a1; }
  .testimonial-bg2 .star-rating .txt-gradient {
    background: #0059a1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .testimonial-bg2 .testimonial-position {
    color: #0059a1; }

.testimonial-card {
  position: relative;
  overflow: hidden;
  padding: 48px 35px 50px;
  border: 1px solid #f0f0f0;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08); }
  @media (max-width: 767px) {
    .testimonial-card {
      padding: 20px 20px; } }
  .testimonial-card h2 {
    line-height: 1.18em; }
  .testimonial-card-text {
    margin-top: 30px;
    font-size: 18px;
    line-height: 29px; }
    @media (max-width: 1023px) {
      .testimonial-card-text {
        font-size: 15px;
        line-height: 24px;
        margin-top: 15px; } }
    @media (max-width: 767px) {
      .testimonial-card-text {
        font-size: 14px;
        line-height: 20px; } }
  .testimonial-card-author {
    margin-top: 20px; }
  .testimonial-card-name {
    font-size: 17px;
    line-height: 1em;
    font-weight: 400; }
    @media (max-width: 1023px) {
      .testimonial-card-name {
        font-size: 15px; } }
    @media (max-width: 767px) {
      .testimonial-card-name {
        font-size: 14px; } }
  .testimonial-card-position {
    padding-right: 1px;
    font-size: 17px;
    line-height: 1em;
    font-weight: 400;
    font-style: italic;
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .testimonial-card-position {
        font-size: 15px; } }
    @media (max-width: 767px) {
      .testimonial-card-position {
        font-size: 14px; } }
  .testimonial-card:before {
    content: '„';
    position: absolute;
    z-index: -1;
    right: 50%;
    transform: translateX(50%);
    top: -652px;
    font-size: 850px;
    line-height: 1em;
    font-weight: 400;
    font-family: var(--fontFamily);
    color: #f6f7f5; }
    @media (max-width: 767px) {
      .testimonial-card:before {
        right: 50%;
        transform: translateX(60%); } }

.testimonial-card-carousel {
  margin-bottom: 0 !important; }

.testimonial-card-carousel .slick-dots {
  margin: 30px 0 0; }

.reviews-author-carousel img {
  max-width: 100%;
  width: auto; }

.gallery-smiles .gallery-item {
  position: relative;
  float: left;
  width: calc(50% - 15px);
  margin-bottom: 30px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .gallery-smiles .gallery-item {
      width: calc(50% - 15px); } }
  @media (max-width: 575px) {
    .gallery-smiles .gallery-item {
      width: 100%;
      margin-bottom: 15px; } }
  .gallery-smiles .gallery-item img {
    width: 100%; }
  .gallery-smiles .gallery-item:hover .gallery-caption {
    transform: translateY(0);
    opacity: 1; }
.gallery-smiles .gallery-caption {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 64%;
  left: 18%;
  bottom: 0;
  padding: 18px;
  color: #d0d0d0;
  transform: translateY(50%);
  transition: 0.5s;
  opacity: 0; }
  .gallery-smiles .gallery-caption b {
    color: #fff; }
  .gallery-smiles .gallery-caption-title,
  .gallery-smiles .gallery-caption h5 {
    margin-bottom: 10px;
    color: #fff; }
  .gallery-smiles .gallery-caption p {
    font-size: 15px;
    line-height: 22px; }
  .gallery-smiles .gallery-caption p:not(:last-child) {
    margin-bottom: 5px; }
  @media (max-width: 1229px) {
    .gallery-smiles .gallery-caption {
      padding: 15px; }
      .gallery-smiles .gallery-caption .gallery-caption,
      .gallery-smiles .gallery-caption h5 {
        font-size: 18px; }
      .gallery-smiles .gallery-caption p {
        font-size: 14px;
        line-height: 18px; } }
  @media (max-width: 1023px) {
    .gallery-smiles .gallery-caption {
      padding: 12px;
      top: 50%;
      bottom: auto;
      width: 80%;
      left: 10%;
      transform: translateY(-50%) !important;
      opacity: 0; }
      .gallery-smiles .gallery-caption .gallery-caption,
      .gallery-smiles .gallery-caption h5 {
        font-size: 16px; }
      .gallery-smiles .gallery-caption p {
        font-size: 13px;
        line-height: 18px; } }
.gallery-smiles .before-label,
.gallery-smiles .after-label {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  line-height: 28px;
  padding: 0 20px; }
  @media (max-width: 1023px) {
    .gallery-smiles .before-label,
    .gallery-smiles .after-label {
      line-height: 22px;
      padding: 0 15px; } }
.gallery-smiles .before-label {
  left: 0; }
.gallery-smiles .after-label {
  right: 0; }

.gallery-wrap .twentytwenty-container img:first-child {
  position: relative !important;
  z-index: 0; }

.gallery-wrap .twentytwenty-container img:last-child {
  z-index: 1; }

.gallery-item:hover .hover {
  opacity: 1; }
  .gallery-item:hover .hover .view {
    transform: scale(1); }

.gallery-specialist .gallery-item {
  position: relative;
  float: left;
  width: calc(33.333% - 20px);
  margin-bottom: 15px; }
  .gallery-specialist .gallery-item img {
    width: 100%; }
  @media (max-width: 767px) {
    .gallery-specialist .gallery-item {
      width: calc(50% - 15px); } }
  @media (max-width: 575px) {
    .gallery-specialist .gallery-item {
      width: 100%;
      margin-bottom: 15px; } }

.gallery-specialist .gallery-item:hover .hover {
  opacity: 1; }
  .gallery-specialist .gallery-item:hover .hover .view {
    transform: scale(1); }

.filters-by-category ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }
  .filters-by-category ul li {
    display: block;
    margin-right: 4px;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .filters-by-category ul li {
        min-width: calc(50% - 8px); } }
    @media (max-width: 575px) {
      .filters-by-category ul li {
        width: 100%; } }
  .filters-by-category ul a {
    display: block;
    cursor: pointer;
    text-shadow: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    font-family: var(--fontFamily);
    padding: 14px 21px;
    color: #444;
    border: 1px solid #d5d5d5;
    border-radius: 25px;
    text-decoration: none;
    transition: 0.3s; }
    @media (max-width: 767px) {
      .filters-by-category ul a {
        font-size: 15px;
        line-height: 15px; } }
    .filters-by-category ul a.selected {
      background: transparent;
      border-color: var(--mainColor);
      color: #444;
      box-shadow: inset 0 0 0 1px var(--mainColor); }

body:not(.touch) .filters-by-category ul a:hover {
  text-decoration: none;
  background: transparent;
  border-color: var(--mainColor);
  box-shadow: inset 0 0 0 1px var(--mainColor); }

.gallery-wrap {
  position: relative;
  min-height: 50vh; }
  .gallery-wrap.loaded .loading-content {
    opacity: 0;
    pointer-events: none; }

.gallery-hover-item {
  display: flex;
  position: relative; }
  .gallery-hover-item-before {
    opacity: 1;
    position: relative;
    transition: 0.3s;
    margin-right: -100%; }
  .gallery-hover-item-after {
    opacity: 0;
    position: relative;
    transition: 0.3s; }
  .gallery-hover-item:hover .gallery-hover-item-before {
    opacity: 0; }
  .gallery-hover-item:hover .gallery-hover-item-after {
    opacity: 1; }
  .gallery-hover-item-title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 25px;
    font-family: var(--fontFamily);
    color: #444; }
  .gallery-hover-item-state {
    font-size: 20px;
    line-height: 30px;
    font-weight: 60;
    font-family: var(--fontFamily);
    color: var(--mainColor); }
  .gallery-hover-item-name {
    font-size: 32px;
    line-height: 40px;
    color: #444;
    font-style: italic; }
  .gallery-hover-item-txt1, .gallery-hover-item-txt2 {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center; }
  .gallery-hover-item-txt2 {
    z-index: 1; }
  .gallery-hover-item p {
    font-size: 16px;
    line-height: 28px; }
  .gallery-hover-item table {
    font-size: 16px;
    line-height: 1em; }
    .gallery-hover-item table td {
      padding: 10px 10px 0 0; }

@media (max-width: 1023px) {
  .gallery-hover {
    margin-left: -15px;
    margin-right: -15px; } }

.gallery-hover-item-before, .gallery-hover-item-after {
  height: 435px;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%; }

.gallery-hover-item-before .gallery-hover-item-txt1 {
  width: 28%;
  padding: 30px 0 15px 6.2%;
  left: 0; }
.gallery-hover-item-before .gallery-hover-item-txt2 {
  width: 28%;
  padding: 30px 5.2% 15px 0;
  right: 0;
  text-align: right;
  justify-content: flex-end; }

.gallery-hover-item-after .gallery-hover-item-txt2 {
  width: 28%;
  padding: 30px 0 15px 6.2%;
  left: 0; }
.gallery-hover-item-after .gallery-hover-item-txt1 {
  width: 28%;
  padding: 30px 5.2% 15px 0;
  right: 0;
  text-align: right;
  justify-content: flex-end; }

.gallery-hover-item.style-2 .gallery-hover-item-before .gallery-hover-item-txt1 {
  width: 227px;
  padding: 0;
  left: 41%;
  bottom: 17%;
  top: auto;
  text-align: left;
  align-items: center; }
.gallery-hover-item.style-2 .gallery-hover-item-before .gallery-hover-item-txt2 {
  width: 40%;
  transform: translateX(-56%);
  padding: 6% 0;
  left: 50%;
  text-align: left;
  justify-content: center;
  align-items: flex-start; }
.gallery-hover-item.style-2 .gallery-hover-item-after .gallery-hover-item-txt1 {
  width: 227px;
  padding: 0;
  left: 38%;
  bottom: 17%;
  top: auto;
  text-align: left;
  align-items: center; }
.gallery-hover-item.style-2 .gallery-hover-item-after .gallery-hover-item-txt2 {
  width: 40%;
  transform: translateX(-47%);
  padding: 6% 0;
  left: 50%;
  text-align: left;
  justify-content: center;
  align-items: flex-start; }

.gallery-hover-item.style-3 .gallery-hover-item-before .gallery-hover-item-txt2 {
  width: 35%;
  padding: 20px 0 15px 10.2%;
  left: 0;
  text-align: left;
  justify-content: flex-start; }
.gallery-hover-item.style-3 .gallery-hover-item-before .gallery-hover-item-txt1 {
  width: 28%;
  padding: 20px 8.0% 15px 0;
  right: 0;
  left: auto;
  text-align: right;
  justify-content: flex-end; }
.gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt1 {
  width: 28%;
  padding: 20px 0 15px 7.2%;
  left: 0;
  text-align: left; }
.gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt2 {
  width: 35%;
  padding: 20px 10% 15px 0;
  right: 0;
  left: auto;
  text-align: right;
  justify-content: flex-end; }
  .gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt2 table {
    float: right; }
    .gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt2 table td {
      padding: 10px 0 0 10px; }
.gallery-hover-item.style-3 table {
  margin-top: 20px; }

.gallery-hover-carousel .slick-dots {
  margin: 40px 0 0 0; }
.gallery-hover-carousel .slick-slide img {
  width: 100%; }

@media (min-width: 768px) and (max-width: 1023px) {
  .gallery-hover-item.style-1 .gallery-hover-item-before, .gallery-hover-item.style-1 .gallery-hover-item-after {
    background-position: 80% 0; }
  .gallery-hover-item.style-1 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 32%;
    padding: 0 30px 35px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; }
  .gallery-hover-item.style-1 .gallery-hover-item-before .gallery-hover-item-txt2 {
    width: 40%;
    padding: 30px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left; }
  .gallery-hover-item.style-1 .gallery-hover-item-after .gallery-hover-item-txt2 {
    width: 40%;
    padding: 30px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left; }
  .gallery-hover-item.style-1 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 32%;
    padding: 0 30px 35px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; } }
@media (max-width: 767px) {
  .gallery-hover-item-title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 15px; }

  .gallery-hover-item.style-1 .gallery-hover-item-before {
    background-position: 8% 0; }
  .gallery-hover-item.style-1 .gallery-hover-item-after {
    background-position: 86% 0; }
  .gallery-hover-item.style-1 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 50%;
    padding: 20px 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-1 .gallery-hover-item-before .gallery-hover-item-txt2 {
    width: 50%;
    padding: 0 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end; }
  .gallery-hover-item.style-1 .gallery-hover-item-after .gallery-hover-item-txt2 {
    width: 50%;
    padding: 20px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right; }
  .gallery-hover-item.style-1 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 50%;
    padding: 0 30px 15px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; }

  .gallery-hover-item.style-2 .gallery-hover-item-before {
    background-position: 10% 0; }
  .gallery-hover-item.style-2 .gallery-hover-item-after {
    background-position: 85% 0; }
  .gallery-hover-item.style-2 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 50%;
    padding: 20px 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-2 .gallery-hover-item-after .gallery-hover-item-txt2 {
    transform: translateX(0);
    width: 50%;
    padding: 0 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-2 .gallery-hover-item-before .gallery-hover-item-txt2 {
    transform: translateX(0);
    width: 50%;
    padding: 20px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right; }
  .gallery-hover-item.style-2 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 50%;
    padding: 0 30px 15px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; }

  .gallery-hover-item.style-3 .gallery-hover-item-before {
    background-position: 15% 0; }
  .gallery-hover-item.style-3 .gallery-hover-item-after {
    background-position: 85% 0; }
  .gallery-hover-item.style-3 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 50%;
    padding: 20px 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-3 .gallery-hover-item-before .gallery-hover-item-txt2 {
    width: 50%;
    padding: 0 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end; }
  .gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt2 {
    width: 50%;
    padding: 20px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right; }
  .gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 50%;
    padding: 0 30px 15px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; } }
@media (max-width: 575px) {
  .gallery-hover-item-title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 15px; }

  .gallery-hover-item.style-1 .gallery-hover-item-before {
    background-position: 25% 0; }
  .gallery-hover-item.style-1 .gallery-hover-item-after {
    background-position: 73% 0; }
  .gallery-hover-item.style-1 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 70%;
    padding: 20px 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-1 .gallery-hover-item-before .gallery-hover-item-txt2 {
    width: 70%;
    padding: 0 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end; }
  .gallery-hover-item.style-1 .gallery-hover-item-after .gallery-hover-item-txt2 {
    width: 70%;
    padding: 20px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right; }
  .gallery-hover-item.style-1 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 70%;
    padding: 0 30px 15px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; }

  .gallery-hover-item.style-2 .gallery-hover-item-before {
    background-position: 20% 0; }
  .gallery-hover-item.style-2 .gallery-hover-item-after {
    background-position: 75% 0; }
  .gallery-hover-item.style-2 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 70%;
    padding: 20px 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-2 .gallery-hover-item-after .gallery-hover-item-txt2 {
    transform: translateX(0);
    width: 70%;
    padding: 0 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-2 .gallery-hover-item-before .gallery-hover-item-txt2 {
    transform: translateX(0);
    width: 70%;
    padding: 20px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right; }
  .gallery-hover-item.style-2 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 70%;
    padding: 0 30px 15px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; }

  .gallery-hover-item.style-3 .gallery-hover-item-before {
    background-position: 35% 0; }
  .gallery-hover-item.style-3 .gallery-hover-item-after {
    background-position: 65% 0; }
  .gallery-hover-item.style-3 .gallery-hover-item-before .gallery-hover-item-txt1 {
    width: 70%;
    padding: 20px 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
  .gallery-hover-item.style-3 .gallery-hover-item-before .gallery-hover-item-txt2 {
    width: 70%;
    padding: 0 0 35px 30px;
    left: 0;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end; }
  .gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt2 {
    width: 70%;
    padding: 20px 30px 0 0;
    left: auto;
    right: 0;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right; }
  .gallery-hover-item.style-3 .gallery-hover-item-after .gallery-hover-item-txt1 {
    width: 70%;
    padding: 0 30px 15px 0;
    left: auto;
    right: 0;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end; } }
.gallery-wrap .twentytwenty-container {
  height: auto !important; }


@media (max-width: 1023px) {
  .sm-gallery-row .col {
    min-width: 25%;
    flex-basis: 25%; 

}

  .sm-gallery-row .col:nth-child(8) ~ .col {
    display: none; } }
@media (max-width: 575px) {
  .sm-gallery-row .col {
    min-width: 50%;
    flex-basis: 50%; }
  .sm-gallery-row .col:nth-child(6) ~ .col {
    display: none; } }
.smile-popover {
  margin-top: -100px;
  width: 346px;
  min-height: 253px;
  background-color: #fff;
  border-radius: 0;
  pointer-events: none; }
  @media (max-width: 575px) {
    .smile-popover {
      width: 240px;
      min-height: 175px; } }
  .smile-popover .inner-circles-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .smile-popover .popover-body {
    padding: 0;
    position: relative;
    height: 100%;
    width: 100%;
    border: 8px solid #fff;
    background-color: #fff; }
  .smile-popover .arrow {
    top: 0 !important; }
    @media (max-width: 575px) {
      .smile-popover .arrow {
        display: none; } }
  .smile-popover .arrow:before {
    display: none; }
  .smile-popover[x-placement^="right"] {
    left: -60px !important; }
  .smile-popover[x-placement^="left"] {
    left: 60px !important; }
  .smile-popover[x-placement^="right"] .arrow:after {
    left: -40px !important;
    top: 30px !important;
    border-right-color: #fff !important;
    border-width: 24px; }
  .smile-popover[x-placement^="left"] .arrow:after {
    right: -40px !important;
    top: 30px !important;
    border-left-color: #fff !important;
    border-width: 24px; }
  .smile-popover img {
    position: relative;
    z-index: 100;
    max-width: 100%; }
  .smile-popover.fade {
    transition: opacity 0s; }
  .smile-popover.fade.show {
    transition: opacity 0.75s; }

.gallery-popover-link {
  display: block;
  width: 100%;
  background: #000; }
  .gallery-popover-link img {
    transition: opacity 0.75s;
    max-width: none;
    width: 100%; }
  .gallery-popover-link.darken img {
    opacity: .75; }

/* slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat; }

.slick-slide {
    max-width: none !important; 

}
.js-service-box-rotator{
    .slick-slide {
      &:not(.slick-active){
        visibility: hidden;
      }
    } 
}
/* arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 45px;
  height: 45px;
  margin-top: -23px;
  padding: 0;
  cursor: pointer;
  color: var(--mainColor);
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 99;
  text-align: center;
  transition: 0.2s;
  border-radius: 50%;
  border: 2px solid #dedede; }

.slick-prev:hover,
.slick-next:hover {
  border-color: var(--mainColor);
  background-color: var(--mainColor);
  color: var(--mainColor); }

.slick-prev:before,
.slick-next:before {
  font-family: 'dentco';
  font-size: 10px;
  line-height: 43px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #fff; }

.slick-prev {
  left: 0; }

.slick-prev:before {
  content: "\e922";
  padding-right: 1px; }

.slick-next {
  right: 0; }

.slick-next:before {
  content: "\e930";
  padding-left: 2px; }

/* dots */
.slick-slider {
  padding: 0;
  list-style: none; }

.slick-slider.nav-dot {
  margin-bottom: 75px; }

@media (max-width: 767px) {
  .slick-slider {
    margin-bottom: 30px; } }
.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 50px 0 -35px;
  list-style: none;
  text-align: center; }
  @media (max-width: 1023px) {
    .slick-dots {
      margin: 35px 0 -20px; } }
  @media (max-width: 575px) {
    .slick-dots {
      margin: 25px 0 -20px; } }

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 -4px 0 0;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
  margin: 0 9px;
  padding: 0;
  color: transparent;
  background-color: transparent;
  border: 0;
  outline: none;
  border-radius: 50%;
  background: #e1e5e9;
  cursor: pointer; 
  &::before{
    display: none;
  }
}
  @media (max-width: 575px) {
    .slick-dots li button {
      width: 6px;
      height: 6px; } }

.slick-dots li button:after {
  display: none; }

.slick-dots li.slick-active button,
.slick-dots li.slick-active button:hover {
  background-color: var(--mainColor); }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

@media (max-width: 1023px) {
  .slick-slide .animation {
    opacity: 1 !important;
    animation-name: none !important;
    -webkit-animation-name: none !important; } }
.blog-post {
  margin-bottom: 80px; }
  @media (max-width: 767px) {
    .blog-post {
      margin-bottom: 50px; } }
  .blog-post:not(.post-preview) > * + * {
    margin-top: 35px; }
  .blog-post:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
  .blog-post img {
    max-width: 100%; }
  .blog-post .post-video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0; }
    .blog-post .post-video iframe,
    .blog-post .post-video object,
    .blog-post .post-video embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  .blog-post .post-music iframe,
  .blog-post .post-music object,
  .blog-post .post-music embed {
    width: 100%;
    height: auto;
    border: 0; }
  .blog-post .post-image {
    position: relative;
    border-radius: 0;
    overflow: hidden; }
    .blog-post .post-image img {
      max-width: none;
      width: 100%; }
    .blog-post .post-image .post-link-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center; }
    .blog-post .post-image .post-link {
      font-size: 38px;
      line-height: 1em;
      color: #fff;
      transition: all 200ms ease;
      text-decoration: none; }
      @media (max-width: 767px) {
        .blog-post .post-image .post-link {
          font-size: 5vw; } }
      .blog-post .post-image .post-link [class*='icon'] {
        font-size: .8em;
        padding-right: .5em; }
      .blog-post .post-image .post-link:hover {
        opacity: .6;
        text-decoration: none; }
  .blog-post .post-title {
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 36px; }
    @media (max-width: 767px) {
      .blog-post .post-title {
        font-size: 24px;
        line-height: 30px; } }
  .blog-post .post-meta {
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden; }
    @media (max-width: 767px) {
      .blog-post .post-meta {
        flex-wrap: wrap; } }
    .blog-post .post-meta > * {
      float: left;
      font-size: 15px; }
      .blog-post .post-meta > *:not(:last-child) {
        padding-right: 20px; }
        @media (max-width: 767px) {
          .blog-post .post-meta > *:not(:last-child) {
            padding-right: 15px; } }
      @media (max-width: 767px) {
        .blog-post .post-meta > * {
          font-size: 13px; } }
      .blog-post .post-meta > * [class*='icon'],
      .blog-post .post-meta > * span {
        display: inline-block;
        vertical-align: middle; }
      .blog-post .post-meta > * [class*='icon'] {
        position: relative;
        top: -1px;
        font-size: 18px;
        padding-right: 7px;
        color: #aeaeae; }
        @media (max-width: 767px) {
          .blog-post .post-meta > * [class*='icon'] {
            display: none; } }
    .blog-post .post-meta .post-meta-reviews span {
      font-size: 14px; }
  .blog-post .post-teaser p:last-child {
    margin-bottom: 0; }
  .blog-post .post-teaser + * {
    margin-top: 40px; }
    @media (max-width: 767px) {
      .blog-post .post-teaser + * {
        margin-top: 30px; } }
  .blog-post .post-image + .post-meta {
    margin-top: 0 !important; }
  .blog-post .post-meta-author a {
    color: var(--mainColor); }
  .blog-post .post-meta-social a {
    margin-right: 15px; }
    .blog-post .post-meta-social a:hover [class*='icon'] {
      color: var(--mainColor); }
    .blog-post .post-meta-social a [class*='icon'] {
      display: inline-block !important;
      font-size: 14px;
      padding-right: 0;
      color: #c8c8c8;
      transition: .2s; }
  .blog-post .post-date-inline {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: var(--mainColor); }
  .blog-post .post-image img {
    -webkit-transition: transform 0.5s ease 0s;
    -o-transition: transform 0.5s ease 0s;
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    image-rendering: optimizeQuality;
    vertical-align: middle;
    max-width: 100%; }
  .blog-post:hover .post-image img {
    -webkit-transition: transform 0.6s ease 0.05s;
    -o-transition: transform 0.6s ease 0.05s;
    transition: -webkit-transform 0.6s ease 0.05s;
    transition: transform 0.6s ease 0.05s;
    transition: transform 0.6s ease 0.05s, -webkit-transform 0.6s ease 0.05s;
    -webkit-transform: scale(1.1) rotate(0.0001deg);
    -ms-transform: scale(1.1) rotate(0.0001deg);
    transform: scale(1.1) rotate(0.0001deg); }

.blog-post:not(.post-preview) .post-date {
  display: flex;
  margin-right: 36px;
  flex-direction: column;
  justify-content: center;
  width: 74px;
  min-width: 74px;
  height: 74px;
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  font-family: var(--fontFamily);
  background-color: #f5f6f7;
  color: #424242;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.03); 
    span{
        text-transform: uppercase;
    }
}
  .blog-post:not(.post-preview) .post-date span {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: var(--mainColor); }

.blog-post-info {
  display: flex; }
  @media (max-width: 575px) {
    .blog-post-info {
      flex-direction: column;
      align-items: center; }
      .blog-post-info > * + * {
        margin-top: 10px; } }

.post-carousel {
  margin-bottom: 0;
  overflow: hidden; }

.blog-post-single {
  padding-bottom: 70px; }
  @media (max-width: 1023px) {
    .blog-post-single {
      padding-bottom: 40px; } }

.blog-post.post-preview {
  display: flex;
  padding-bottom: 12px;
  margin-bottom: 22px; }
  .blog-post.post-preview:last-child {
    padding-bottom: 0;
    margin-bottom: 0; }
  .side-block-title + .blog-post.post-preview {
    margin-top: 10px; }
  .blog-post.post-preview .post-image {
    margin-right: 20px;
    margin-bottom: 10px;
    min-width: 77px;
    flex-basis: 77px;
    font-size: 15px;
    line-height: 18px; }
    .blog-post.post-preview .post-image > *:not(:first-child) {
      padding-left: 10px; }
      @media (max-width: 767px) {
        .blog-post.post-preview .post-image > *:not(:first-child) {
          padding-left: 5px; } }
  .blog-post.post-preview .post-meta-date [class*='icon'] {
    color: var(--mainColor);
    font-size: 13px;
    top: -1px;
    left: 1px; }
  .blog-post.post-preview .post-title {
    margin-top: -.25em !important;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: var(--fontFamily);
    font-weight: 600; }
    .blog-post.post-preview .post-title a {
      text-decoration: none;
      transition: color 200ms ease; }
      .blog-post.post-preview .post-title a:hover {
        color: var(--mainColor); }
  .blog-post.post-preview .post-title + * {
    margin-top: 5px; }

.side-block {
  padding-bottom: 34px; }
  .side-block .side-block-title {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 25px; }

.side-block:last-child {
  margin-bottom: 0; }

/* comments */
.comments-block {
  padding-bottom: 45px; }
  .comments-block:not(:last-child) {
    border-bottom: 1px solid #e8e8e8; }
  .comments-block .comment {
    margin: 35px 0 0; }
    .comments-block .comment,
    .comments-block .comment a {
      color: #424242; }
    .comments-block .comment + * {
      margin-top: 30px; }
    .comments-block .comment .userpic {
      float: left;
      color: #e4e4e4;
      font-size: 76px;
      width: 100px; }
    .comments-block .comment .text {
      overflow: hidden;
      padding-top: 2px; }
      .comments-block .comment .text p {
        margin-bottom: 10px; }
      .comments-block .comment .text .meta {
        margin-bottom: 5px; }
        .comments-block .comment .text .meta [class*='icon'] {
          color: var(--mainColor);
          font-size: 13px;
          top: -1px;
          left: 1px;
          padding-right: 7px; }
        .comments-block .comment .text .meta > *:not(:first-child) {
          margin-left: 14px;
          padding-left: 14px;
          position: relative; }
          .comments-block .comment .text .meta > *:not(:first-child):before {
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            z-index: 1;
            display: block;
            width: 1px;
            height: 17px;
            background-color: #dddddd; }
        .comments-block .comment .text .meta b {
          font-weight: 600; }
      .comments-block .comment .text a.reply {
        display: inline-block;
        font-weight: 500;
        font-family: var(--fontFamily);
        color: var(--mainColor); }
        .comments-block .comment .text a.reply:hover {
          color: #787878;
          text-decoration: none; }
          .comments-block .comment .text a.reply:hover [class*='icon'] {
            color: #787878; }
        .comments-block .comment .text a.reply [class*='icon'] {
          position: relative;
          color: var(--mainColor);
          font-size: 15px;
          padding-right: 12px;
          top: 1px;
          left: 1px; }
    .comments-block .comment.replay {
      margin-left: 115px; }
      @media (max-width: 767px) {
        .comments-block .comment.replay {
          margin-left: 70px; } }
      @media (max-width: 575px) {
        .comments-block .comment.replay {
          margin-left: 30px; } }

.comment-form {
  margin-bottom: 50px; }
  .comment-form .textarea-custom {
    height: 170px; }

.blog-isotope {
  overflow: hidden;
  margin-bottom: 50px;
  margin-top: 10px;
  overflow: visible; }
  .blog-isotope .blog-post > * + * {
    margin-top: 20px; }
  .blog-isotope .post-title {
    font-size: 22px;
    line-height: 1.1em;
    margin-top: 6px; }
    @media (max-width: 575px) {
      .blog-isotope .post-title {
        font-size: 18px; } }
  .blog-isotope .post-meta-social [class*='icon'] {
    font-size: 14px; }
  .blog-isotope .blog-post .post-meta > * [class*='icon'] {
    font-size: 15px; }
  .blog-isotope .blog-post .post-image {
    margin-left: -20px;
    margin-right: -20px; }
    .blog-isotope .blog-post .post-image:first-child {
      margin-top: -20px; }
  .blog-isotope .blog-post .post-image .post-link {
    font-size: 32px; }
    @media (max-width: 767px) {
      .blog-isotope .blog-post .post-image .post-link {
        font-size: 5vw; } }
  .blog-isotope .blog-post .post-date {
    margin-right: 12px; }
  .blog-isotope .blog-post {
    position: relative;
    float: left;
    width: calc(50% - 15px);
    margin-bottom: 30px;
    padding: 20px 20px 24px;
    margin-bottom: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }
    @media (max-width: 1023px) {
      .blog-isotope .blog-post {
        width: 100%; } }
  .blog-isotope .blog-post.bg-grey {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
    .blog-isotope .blog-post.bg-grey .post-date {
      background-color: #fff; }

.blog-grid {
  margin-bottom: 50px;
  margin-top: 10px;
  overflow: visible; }
  .blog-grid .blog-post > * + * {
    margin-top: 27px; }
    @media (max-width: 1023px) {
      .blog-grid .blog-post > * + * {
        margin-top: 20px; } }
  .blog-grid .blog-post .post-image {
    margin-top: 10px; }
  .blog-grid .post-title {
    font-size: 26px;
    line-height: 34px;
    margin-top: 6px;
    margin-bottom: -2px; }
    @media (max-width: 1229px) {
      .blog-grid .post-title {
        font-size: 22px;
        line-height: 30px; } }
    @media (max-width: 1023px) {
      .blog-grid .post-title {
        font-size: 18px;
        line-height: 24px;
        max-width: 80%; } }
  .blog-grid .post-meta-social [class*='icon'] {
    font-size: 14px; }
  @media (max-width: 575px) {
    .blog-grid .blog-post-info {
      flex-direction: row; }
      .blog-grid .blog-post-info > * + * {
        margin-top: 0; } }
  .blog-grid .blog-post .post-meta > * [class*='icon'] {
    font-size: 15px; }
  .blog-grid .blog-post .post-image .post-link {
    font-size: 32px; }
    @media (max-width: 767px) {
      .blog-grid .blog-post .post-image .post-link {
        font-size: 5vw; } }
  .blog-grid .blog-post .post-date {
    margin-right: 26px; }
  @media (max-width: 1023px) {
    .blog-grid .blog-post:not(.post-preview) .post-date {
      margin-right: 13px;
      width: 63px;
      min-width: 63px;
      height: 63px;
      font-size: 27px;
      line-height: 27px; }
      .blog-grid .blog-post:not(.post-preview) .post-date span {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400; } }
  @media (max-width: 767px) {
    .blog-grid .blog-post:not(.post-preview) .post-date {
      margin-right: 13px;
      width: 49px;
      min-width: 49px;
      height: 49px;
      font-size: 22px;
      line-height: 22px; }
      .blog-grid .blog-post:not(.post-preview) .post-date span {
        font-size: 10px;
        line-height: 10px;
        font-weight: 300;
        color: var(--mainColor); } }
  .blog-grid .blog-post .post-teaser {
    font-size: 16px;
    line-height: 28px; }
  @media (max-width: 575px) {
    .blog-grid .blog-post-info {
      flex-direction: row; } }

.blog-grid-carousel .blog-post {
  padding-bottom: 0; }

.blog-grid-full .blog-post {
  margin-bottom: 30px; }
  @media (max-width: 575px) {
    .blog-grid-full .blog-post {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto; } }
.blog-grid-full .blog-post > * + * {
  margin-top: 25px; }
  @media (max-width: 1023px) {
    .blog-grid-full .blog-post > * + * {
      margin-top: 15px; } }
.blog-grid-full .post-title {
  font-size: 22px;
  line-height: 1.545em;
  margin-top: 6px;
  margin-bottom: -2px; }
  @media (max-width: 1023px) {
    .blog-grid-full .post-title {
      font-size: 18px; } }
.blog-grid-full .blog-post-style2 .post-title {
  margin-top: 34px;
  margin-bottom: 0; }
  .blog-grid-full .blog-post-style2 .post-title + * {
    margin-top: 10px; }
  @media (min-width: 1024px) {
    .blog-grid-full .blog-post-style2 .post-title {
      font-size: 24px;
      line-height: 30px; } }
  @media (max-width: 1023px) {
    .blog-grid-full .blog-post-style2 .post-title {
      margin-top: 22px; } }
.blog-grid-full .blog-post-style3 .post-title {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 28px; }
  .blog-grid-full .blog-post-style3 .post-title + * {
    margin-top: 10px; }
  @media (max-width: 1023px) {
    .blog-grid-full .blog-post-style3 .post-title {
      font-size: 18px;
      line-height: 24px;
      margin-top: 0; } }
.blog-grid-full .post-meta-social [class*='icon'] {
  font-size: 14px; }
.blog-grid-full .blog-post .post-meta > * [class*='icon'] {
  font-size: 15px; }
.blog-grid-full .blog-post .post-image {
  margin-left: -20px;
  margin-right: -20px; }
  .blog-grid-full .blog-post .post-image:first-child {
    margin-top: -20px; }
.blog-grid-full .blog-post-style2 .post-image, .blog-grid-full .blog-post-style3 .post-image {
  margin-left: -37px;
  margin-right: -37px; }
  .blog-grid-full .blog-post-style2 .post-image:first-child, .blog-grid-full .blog-post-style3 .post-image:first-child {
    margin-top: -37px; }
@media (max-width: 1229px) {
  .blog-grid-full .blog-post-style2 .post-image, .blog-grid-full .blog-post-style3 .post-image {
    margin-left: -20px;
    margin-right: -20px; }
    .blog-grid-full .blog-post-style2 .post-image:first-child, .blog-grid-full .blog-post-style3 .post-image:first-child {
      margin-top: -20px; } }
.blog-grid-full .blog-post .post-date {
  margin-right: 15px; }
.blog-grid-full .blog-post {
  padding: 20px 20px 32px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }
.blog-grid-full .blog-post-style2, .blog-grid-full .blog-post-style3 {
  padding: 37px 37px 32px; }
  @media (max-width: 1229px) {
    .blog-grid-full .blog-post-style2, .blog-grid-full .blog-post-style3 {
      padding: 20px 20px 32px; } }
@media (max-width: 767px) {
  .blog-grid-full .blog-post:not(.post-preview) .post-date {
    margin-right: 13px;
    width: 49px;
    min-width: 49px;
    height: 49px;
    font-size: 22px;
    line-height: 22px; }
    .blog-grid-full .blog-post:not(.post-preview) .post-date span {
      font-size: 10px;
      line-height: 10px;
      font-weight: 300;
      color: var(--mainColor); } }
.blog-grid-full .blog-post .post-teaser {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 1023px) {
    .blog-grid-full .blog-post .post-teaser {
      font-size: 14px;
      line-height: 20px; } }
.blog-grid-full .slick-dots {
  margin-top: 20px;
  margin-bottom: -15px; }
  @media (max-width: 575px) {
    .blog-grid-full .slick-dots {
      margin-top: 0; } }
@media (max-width: 575px) {
  .blog-grid-full .blog-post-info {
    flex-direction: row; } }

@media (max-width: 1023px) {
  .blog-grid-carousel-full {
    margin-left: 50px;
    margin-right: 50px; } }
@media (max-width: 767px) {
  .blog-grid-carousel-full {
    margin: 0; } }
.blog-grid-full.blog-grid-carousel-full .blog-post.bg-grey {
  background: none; }

.blog-grid-full:not(.blog-grid-carousel-full) .blog-post.bg-grey {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
  .blog-grid-full:not(.blog-grid-carousel-full) .blog-post.bg-grey .post-date {
    background-color: #fff; }

.blog-post-style3 .post-date-bg {
  position: relative;
  display: inline-block;
  height: 36px;
  top: -18px;
  padding: 0 27px;
  margin: 0;
  font-size: 14px;
  line-height: 36px;
  font-weight: 500;
  font-family: var(--fontFamily);
  color: #ffffff;
  background-color: #707070;
  border-radius: 18px;
  text-align: center;
  letter-spacing: 1px; }
  @media (max-width: 575px) {
    .blog-post-style3 .post-date-bg {
      height: 30px;
      padding: 0 20px;
      top: -15px;
      font-size: 12px;
      line-height: 30px;
      border-radius: 15px;
      letter-spacing: .5px; } }

.blog-post-sm {
  display: flex;
  max-width: 480px; }
  .blog-post-sm-photo {
    min-width: 158px;
    flex-basis: 158px; }
    @media (max-width: 1023px) {
      .blog-post-sm-photo {
        min-width: 128px;
        flex-basis: 128px; } }
    @media (max-width: 767px) {
      .blog-post-sm-photo {
        min-width: 100px;
        flex-basis: 100px; } }
    .blog-post-sm-photo img {
      width: calc(100% - 30px); }
      @media (max-width: 1023px) {
        .blog-post-sm-photo img {
          width: calc(100% - 20px); } }
      @media (max-width: 767px) {
        .blog-post-sm-photo img {
          width: calc(100% - 18px); } }
  .blog-post-sm-text {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .blog-post-sm-text {
        padding-right: 10px; } }
  .blog-post-sm-date {
    font-size: 14px;
    line-height: 1em;
    font-weight: 600;
    font-family: var(--fontFamily);
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .blog-post-sm-date {
        font-size: 13px; } }
  .blog-post-sm-title:not(:first-child) {
    margin-top: 14px; }
    @media (max-width: 1023px) {
      .blog-post-sm-title:not(:first-child) {
        margin-top: 8px; } }
    @media (max-width: 767px) {
      .blog-post-sm-title:not(:first-child) {
        margin-top: 4px; } }
  .blog-post-sm-title {
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    font-family: var(--fontFamily); }
    @media (max-width: 1023px) {
      .blog-post-sm-title {
        font-size: 13px;
        line-height: 22px; } }
    @media (max-width: 1023px) {
      .blog-post-sm-title {
        font-size: 13px;
        line-height: 17px; } }
    .blog-post-sm-title a {
      color: #444;
      transition: 0.2s;
      text-decoration: none; }
      .blog-post-sm-title a:hover {
        text-decoration: none;
        color: var(--mainColor); }
  .blog-post-sm-readmore {
    position: relative;
    margin-top: auto;
    padding-top: 10px;
    font-size: 14px;
    line-height: 1em;
    font-weight: 600;
    color: var(--mainColor); }
    @media (max-width: 1023px) {
      .blog-post-sm-readmore {
        padding-top: 7px; } }
    @media (max-width: 767px) {
      .blog-post-sm-readmore {
        margin-top: 4px; } }
    .blog-post-sm-readmore:hover {
      text-decoration: none;
      color: #444; }
  .blog-post-sm + * {
    margin-top: 26px; }

.blog-post-sm-carousel {
  display: none; }

@media (min-width: 768px) and (max-width: 1023px) {
  .blog-post-sm-carousel {
    display: block;
    margin-top: 24px; }
    .blog-post-sm-carousel .blog-post-sm {
      margin-top: 0 !important; }
    .blog-post-sm-carousel.slick-initialized .blog-post-sm.slick-slide {
      display: flex; }
    .blog-post-sm-carousel .slick-dots {
      margin: 35px 0 20px; }

  .blog-post-sm-vertical {
    display: none; } }
.calendar {
  max-width: 350px; }
  .calendar__header {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    padding: 0 0 15px;
    text-transform: uppercase; }
    .calendar__header .prev, .calendar__header .next {
      position: relative;
      top: 2px;
      font-size: 18px;
      color: var(--mainColor);
      text-decoration: none; }
      .calendar__header .prev:hover, .calendar__header .next:hover {
        color: #424242;
        text-decoration: none; }
    .calendar__header .prev {
      margin-right: auto; }
    .calendar__header .next {
      margin-left: auto; }
  .calendar table {
    background: #fff;
    border-collapse: collapse;
    font-size: 15px;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate; }
  .calendar td {
    border: 1px solid #fff;
    color: #2c2c2c;
    cursor: pointer;
    line-height: 35px;
    text-align: center;
    font-weight: 300;
    transition: all 0.2s ease; }
    .calendar td:hover {
      background-color: #f5f5f5; }
  .calendar th {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 39px;
    font-weight: 300;
    text-align: center;
    background-color: var(--mainColor); }
  .calendar tr:first-child td {
    color: #2c2c2c;
    padding-bottom: 10px; }
  .calendar tr:nth-child(even) {
    background-color: #f8f8f8; }
  .calendar .selected,
  .calendar .selected:hover {
    color: #000;
    background-color: #fff;
    position: relative; }
    .calendar .selected:after,
    .calendar .selected:hover:after {
      position: absolute;
      z-index: 1;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--mainColor);
      border-radius: 0; }

.contact-box {
  background-color: #3493df;
  padding: 28px 25px;
  height: 100%; }
  .contact-box, .contact-box a {
    color: #fff; }
  .contact-box-title {
    margin-bottom: 18px;
    color: #fff; }
  .contact-box ul.icn-list {
    font-size: 15px;
    line-height: 26px; }
    .contact-box ul.icn-list li {
      padding-left: 30px; }
      .contact-box ul.icn-list li b {
        color: #fff; }
    .contact-box ul.icn-list li:not(:last-child) {
      margin-bottom: 5px; }
    .contact-box ul.icn-list [class*='icon-'] {
      top: 5px;
      font-size: 17px;
      color: #b4d7f3; }

.contact-box-2 {
  background-color: #4fa1e3; }

.question-box {
  background-color: #f8f8f8;
  padding: 18px 20px; }
  .question-box-title {
    margin-bottom: 18px; }
  .question-box .form-control:not(:focus), .question-box .form-control:active {
    border-color: #fff; }
  .question-box .form-control + * {
    margin-top: 6px; }
  .question-box textarea.form-control {
    height: 100px; }

.quickLinks-wrap {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 102;
  width: 98px;
  align-items: center;
  pointer-events: none; }

body > .quickLinks-wrap {
  top: 0; }
  body > .quickLinks-wrap .quickLinks {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  body > .quickLinks-wrap .quickLinks:not(.stuck) .quickLinks-open {
    display: none; }
  body > .quickLinks-wrap .quickLinks.stuck {
    opacity: 1;
    visibility: visible;
    pointer-events: all; }

body:not(.touch) .quickLinks.stuck {
  transition: transform 0.75s; }

.quickLinks:not(.stuck) {
  transform: translateY(0) !important; }

.quickLinks {
  width: 98px;
  z-index: 102;
  right: 0;
  pointer-events: auto;
  transform: translateY(-100px); }
  .quickLinks:not(.stuck) > .container {
    padding: 0; }
    .quickLinks:not(.stuck) > .container > .row {
      flex-direction: column; }
  .quickLinks:not(.stuck) > .container > .row > .col {
    width: 100%;
    min-height: inherit; }
    .quickLinks:not(.stuck) > .container > .row > .col.col-close {
      display: none; }
  .quickLinks .col > .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 12px;
    min-height: 98px;
    background-color: var(--mainColor);
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-family: var(--fontFamily);
    text-transform: uppercase;
    transition: background-color 0.3s; }
    @media (max-width: 1023px) {
      .quickLinks .col > .link {
        font-size: 10px; } }
    .quickLinks .col > .link > [class*='icon-'] {
      font-size: 38px;
      line-height: 1.3em; }
    body:not(.touch) .quickLinks .col > .link:hover, .quickLinks .col > .link.active {
      text-decoration: none;
      color: var(--mainColor);
      background-color: #fff !important;
      box-shadow: inset 0 8px 8px rgba(0, 0, 0, 0.05); }
  .quickLinks.stuck {
    position: fixed;
    width: 100%;
    max-width: 100vw;
    top: 0; }
    .quickLinks.stuck .col > .link {
      min-height: 98px; }
  .quickLinks.closed.stuck {
    transform: translateY(-30px) !important; }
  .quickLinks .col-close {
    max-width: 38px;
    flex: 38px;
    color: var(--mainColor);
    background-color: #fff !important;
    box-shadow: inset 0 8px 8px rgba(0, 0, 0, 0.05); }
    .quickLinks .col-close a {
      display: block;
      position: relative;
      z-index: 1;
      text-decoration: none;
      width: 38px;
      height: 95px;
      text-shadow: none;
      font-size: 12px;
      color: #787878; }
      .quickLinks .col-close a:hover {
        text-decoration: none; }
      .quickLinks .col-close a i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .quickLinks .link-drop {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #f5f5f5;
    color: #424242;
    padding: 30px;
    top: 100%;
    left: 0;
    width: 390px;
    max-width: 100vw;
    transform: translateY(-20px);
    z-index: -1;
    transition: opacity 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    pointer-events: none;
    text-align: left;
    text-transform: none;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); }
    @media (max-width: 1229px) {
      .quickLinks .link-drop {
        width: 357px; } }
    .quickLinks .link-drop-title {
      text-transform: none;
      margin-bottom: 18px; }
      .quickLinks .link-drop-title > [class*='icon-'] {
        position: relative;
        top: 3px;
        font-size: 25px;
        line-height: 1em;
        color: var(--mainColor);
        padding-right: 10px; }
    .quickLinks .link-drop .row-table {
      width: 100%;
      font-size: 15px;
      line-height: 23px;
      font-weight: 400;
      color: #787878; }
      .quickLinks .link-drop .row-table tr:not(:last-child) {
        border-bottom: 1px solid #ededed; }
      .quickLinks .link-drop .row-table td {
        padding: 5px 0; }
      .quickLinks .link-drop .row-table td:last-child {
        text-align: right;
        color: #444;
        padding-right: 5px;
        padding-left: 5px; }
    .quickLinks .link-drop p {
      font-size: 15px;
      line-height: 24px; }
  .quickLinks:not(.stuck) .link-drop {
    left: -390px !important;
    top: 0;
    right: auto !important;
    transform: translateY(20px); }
    @media (max-width: 1229px) {
      .quickLinks:not(.stuck) .link-drop {
        left: -357px !important; } }
  .quickLinks .col:nth-last-child(2) .link-drop {
    left: auto;
    right: 0; }
  .quickLinks:not(.stuck) .col:nth-last-child(3) .link-drop, .quickLinks:not(.stuck) .col:nth-last-child(2) .link-drop, .quickLinks:not(.stuck) .col:nth-last-child(1) .link-drop {
    bottom: 0;
    top: auto;
    transform: translateY(-20px); }
  .quickLinks .google-map {
    width: 390px;
    max-width: 100vw;
    height: 312px; }
    @media (max-width: 1229px) {
      .quickLinks .google-map {
        width: 357px; } }
  .quickLinks ul.icn-list li .phone {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    color: #424242;
    position: relative;
    top: -2px; }
  .quickLinks ul.icn-list li {
    padding-left: 24px; }
  .quickLinks ul.icn-list li > [class*='icon'] {
    top: 6px;
    font-size: 14px; }
  .quickLinks .col:nth-child(1) > .link {
    background-color: #1b6aaa; }
  .quickLinks .col:nth-child(2) > .link {
    background-color: var(--mainColor); }
  .quickLinks .col:nth-child(3) > .link {
    background-color: #2f81c8; }
  .quickLinks .col:nth-child(4) > .link {
    background-color: #3890d2; }
  .quickLinks .col:nth-child(5) > .link {
    background-color: #43a0dd; }
  .quickLinks .col:nth-child(6) > .link {
    background-color: #4caee6; }

.quickLinks.stuck .link-drop {
  top: -1000%; }

@media (min-width: 1024px) {
  body:not(.touch) .quickLinks .col:hover .link-drop, body.touch .col.hovered .link-drop {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%) !important;
    transition-delay: 0s, 0s, 0s, 0.3s;
    pointer-events: auto; }

  .quickLinks.stuck .col:hover .link-drop {
    top: 100%; } }
.link-drop-title {
  text-transform: none;
  margin-bottom: 15px; }
  .link-drop-title > [class*='icon-'] {
    position: relative;
    top: 3px;
    font-size: 25px;
    line-height: 1em;
    color: var(--mainColor);
    padding-right: 10px; }
.link-drop .row-table {
  width: 100%;
  max-width: 400px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400; }
  .link-drop .row-table i {
    color: #787878;
    font-style: normal; }
  .link-drop .row-table tr:not(:last-child) {
    border-bottom: 1px solid #ededed; }
  .link-drop .row-table td {
    padding: 5px 0; }
  .link-drop .row-table td:last-child {
    text-align: right; }

.header-quickLinks .quickLinks, .header-quickLinks .quickLinks.stuck {
  transform: translateY(0) !important; }

@media (max-width: 1023px) {
  .header-quickLinks .link {
    font-size: 10px;
    line-height: 12px; }

  .quickLinks .col > .link > [class*='icon-'] {
    font-size: 30px; }

  .quickLinks.stuck .col > .link {
    min-height: 76px; } }
@media (max-width: 767px) {
  .quickLinks .col > .link > [class*='icon-'] {
    font-size: 18px; }

  .header-quickLinks .link > span {
    display: none; }

  .quickLinks.stuck .col > .link {
    min-height: 36px; } }
.quickLinks-top .link-drop {
  background-color: #f5f5f5;
  color: #424242;
  padding: 15px;
  position: relative;
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%) !important;
  transition-delay: 0s, 0s, 0.3s;
  pointer-events: auto; }
  @media (max-width: 767px) {
    .quickLinks-top .link-drop {
      font-size: 14px;
      line-height: 20px;
      padding: 15px 15px 25px; } }
  .quickLinks-top .link-drop .google-map {
    width: 100%;
    height: 200px; }
  @media (max-width: 767px) {
    .quickLinks-top .link-drop-title {
      font-size: 19px; }
      .quickLinks-top .link-drop-title > [class*='icon-'] {
        font-size: 25px; } }

@media (max-width: 1023px) {
  .quickLinks.stuck {
    position: relative !important;
    transform: translateY(0); }
  .quickLinks > .container {
    padding: 0; }
  .quickLinks .col-close {
    display: none; }

  .header-quickLinks .quickLinks {
    position: relative !important;
    transform: translateY(0);
    width: 100%;
    max-width: 100vw;
    top: 0; }

  .header-quickLinks .quickLinks:not(.stuck) > .container > .row {
    flex-direction: row; } }
.quickLinks .quickLinks-open {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
  border-left: 45px solid rgba(30, 118, 189, 0.25);
  transform: rotate(-45deg);
  right: -10px;
  top: -20px;
  cursor: pointer; }
  .quickLinks .quickLinks-open span {
    position: absolute;
    user-select: none;
    top: -16px;
    right: 20px;
    z-index: 1;
    color: #424242;
    font-size: 30px;
    transform: rotate(45deg); }

.quickLinks.closed .quickLinks-open {
  top: 70px;
  opacity: 1;
  pointer-events: auto; }
  body:not(.touch) .quickLinks.closed .quickLinks-open:hover {
    border-left: 45px solid rgba(30, 118, 189, 0.5); }
    body:not(.touch) .quickLinks.closed .quickLinks-open:hover span {
      color: #fff; }

.page-content .quickLinks:not(.stuck) .quickLinks-open {
  display: none !important; }

.quickLinks ul.icn-list .phone {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #444; }

.cd-schedule {
  position: relative;
  margin: 2em 0; }
  .cd-schedule ol, .cd-schedule ul {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    padding: 0; }
  .cd-schedule table {
    border-collapse: collapse;
    border-spacing: 0; }
  .cd-schedule::before {
    content: 'mobile';
    display: none; }
  @media (min-width: 1024px) {
    .cd-schedule::after {
      clear: both;
      content: "";
      display: block; }
    .cd-schedule::before {
      content: 'desktop'; } }

.cd-schedule .timeline {
  display: none; }
  @media (min-width: 1024px) {
    .cd-schedule .timeline {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: 55px; }
      .cd-schedule .timeline li {
        position: relative;
        height: 55px; }
        span .cd-schedule .timeline li::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #e6e6e6; }
        .cd-schedule .timeline li:last-of-type::after {
          display: none; }
        .cd-schedule .timeline li:last-child {
          border-bottom: 1px solid #e6e6e6; } }
  @media (min-width: 1024px) {
    .cd-schedule .timeline li:after {
      width: 100%;
      left: 0; }
    .cd-schedule .timeline li span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 145px;
      height: 55px;
      border: 1px solid #e6e6e6;
      border-top: 0; } }

.timeline .timeline-top-info {
  position: absolute;
  top: 0;
  width: 145px; }

.timeline li:nth-child(odd) > span {
  background: #f6f6f6; }

.schedule-top-info, .timeline-top-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  padding: 0;
  background: var(--mainColor);
  color: #fff; }
  .schedule-top-info > span, .timeline-top-info > span {
    display: table-cell;
    vertical-align: middle;
    padding: 0 .5em;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0; }

.cd-schedule .events {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #fff; }
  .cd-schedule .events .events-group {
    margin-bottom: 30px; }
  .cd-schedule .events .events-group > ul {
    position: relative;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; }
    .cd-schedule .events .events-group > ul::after {
      display: inline-block;
      content: '-';
      width: 1px;
      height: 100%;
      opacity: 0;
      color: transparent; }
  .cd-schedule .events .doctor-card {
    flex-shrink: 0;
    float: left;
    height: 180px;
    width: 100%;
    max-width: 200px;
    margin-right: 20px;
    transition: opacity .2s, background .2s; }
    .cd-schedule .events .doctor-card:last-of-type {
      margin-right: 0; }
    .cd-schedule .events .doctor-card a {
      display: block;
      height: 100%;
      padding: .8em; }
  @media (min-width: 768px) {
    .cd-schedule .events .cd-schedule .doctor-card {
      width: 40%; } }
  @media (min-width: 1024px) {
    .cd-schedule .events {
      float: left;
      width: 100%; }
      .cd-schedule .events .events-group {
        width: 14.2857142857%;
        float: left;
        border: 1px solid #fff;
        margin-bottom: 0;
        border-top: 0; }
        .cd-schedule .events .events-group:not(:first-of-type) {
          border-left-width: 0; }
        .cd-schedule .events .events-group:last-child {
          border-right: 1px solid #e6e6e6; }
      .cd-schedule .events .events-group > ul {
        height: 660px;
        display: block;
        overflow: visible;
        padding: 0; }
        .cd-schedule .events .events-group > ul::after {
          clear: both;
          content: "";
          display: block; }
        .cd-schedule .events .events-group > ul:after {
          display: none; }
      .cd-schedule .events .doctor-card {
        position: absolute;
        z-index: 3;
        width: calc(100% + 2px);
        left: -1px;
        flex-shrink: 1;
        height: auto;
        max-width: none;
        margin-right: 0;
        margin-top: 1px;
        border: 1px solid #fff;
        border-bottom: 0; }
        .cd-schedule .events .doctor-card a {
          padding: 14px 5px;
          display: block;
          text-decoration: none !important;
          text-align: center;
          color: inherit; }
        .cd-schedule .events .doctor-card:first-of-type {
          border-top: 0; }
        .cd-schedule .events .doctor-card:last-of-type {
          margin-right: 0;
          margin-top: 0; }
        .cd-schedule .events .doctor-card:last-of-type.before-last-time:after {
          position: absolute;
          content: '';
          z-index: 1;
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid #e6e6e6; }
        .cd-schedule .events .doctor-card.selected-event {
          visibility: hidden; }
      .cd-schedule .events .events-group:last-child .doctor-card {
        border-right: 0; }
      .cd-schedule .events .events-group:first-child .doctor-card {
        border-left: 1px solid #e6e6e6;
        left: -2px;
        width: calc(100% + 3px); } }
  @media (min-width: 1024px) {
    .cd-schedule .events {
      width: calc(100% - 145px);
      margin-left: 145px; } }

.doctor-card a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  text-align: center; }
.doctor-card a > * + * {
  margin-top: 10px; }
.doctor-card .doctor-photo img {
  display: block;
  border-radius: 50%;
  width: 60px;
  transition: all 0.3s; }
.doctor-card .doctor-name {
  font-size: 14px;
  line-height: 1em;
  font-weight: 600;
  color: #444; }
.doctor-card .doctor-position {
  font-style: italic;
  font-size: 14px;
  line-height: 1em;
  color: var(--mainColor); }
.doctor-card .doctor-time {
  font-size: 14px;
  line-height: 1em;
  font-weight: 300; }
.doctor-card:hover .doctor-photo img {
  box-shadow: 0 0 0 5px rgba(30, 118, 189, 0.2);
  color: #fff; }

.cd-schedule.loading .events .doctor-card {
  opacity: 0; }

.cd-schedule .event-name,
.cd-schedule .event-date {
  display: block;
  font-weight: bold; }

.cd-schedule .event-name {
  font-size: 14px; }
  @media (min-width: 1024px) {
    .cd-schedule .event-name {
      font-size: 14px; } }

.cd-schedule .event-date {
  font-size: 14px; }

.doctor-card[data-event="event-1"],
.cd-schedule [data-event="event-1"] .header-bg {
  background: #e1e7eb; }

.doctor-card[data-event="event-1"]:hover {
  background: #f0f3f5; }

.doctor-card[data-event="event-2"],
.cd-schedule [data-event="event-2"] .header-bg {
  background: #d5dbe0; }

.doctor-card[data-event="event-2"]:hover {
  background: #e4e8eb; }

.doctor-card[data-event="event-3"],
.cd-schedule [data-event="event-3"] .header-bg {
  background: #ebeff2; }

.doctor-card[data-event="event-3"]:hover {
  background: #fafbfc; }

.doctor-card[data-event="event-4"],
.cd-schedule [data-event="event-4"] .header-bg {
  background: #f2f4f5; }

.doctor-card[data-event="event-4"]:hover {
  background: white; }

.cd-schedule .event-modal {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  transform: translateZ(0);
  transform: translateX(100%);
  transition: transform .4s, visibility .4s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); }
  .cd-schedule .event-modal .header {
    position: relative;
    height: 70px;
    /* vertically center its content */
    display: table;
    width: 100%; }
    .cd-schedule .event-modal .header .content {
      position: relative;
      z-index: 3;
      /* vertically center inside its parent */
      display: table-cell;
      vertical-align: middle;
      padding: .6em 5%; }
  .cd-schedule .event-modal .body {
    position: relative;
    width: 100%;
    /* 70px is the .header height */
    height: calc(100% - 70px); }
  .cd-schedule .event-modal .event-info {
    position: relative;
    z-index: 2;
    line-height: 1.4;
    height: 100%;
    overflow: hidden; }
    .cd-schedule .event-modal .event-info > div {
      overflow: auto;
      height: 100%;
      padding: 1.4em 5%; }
  .cd-schedule .event-modal .header-bg, .cd-schedule .event-modal .body-bg {
    /* these are the morphing backgrounds - visible on desktop only */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .cd-schedule .event-modal .body-bg {
    z-index: 1;
    background: #fff;
    transform-origin: top left; }
  .cd-schedule .event-modal .header-bg {
    z-index: 2;
    transform-origin: top center; }
  .cd-schedule .event-modal .close {
    /* this is the 'X' icon */
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.1);
    /* replace text with icon */
    color: transparent;
    white-space: nowrap;
    text-indent: 100%;
    height: 70px;
    width: 70px; }
    .cd-schedule .event-modal .close::before, .cd-schedule .event-modal .close::after {
      /* these are the two lines of the 'X' icon */
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 22px;
      background: #fff;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
    .cd-schedule .event-modal .close::before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg); }
    .cd-schedule .event-modal .close::after {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg); }
  .cd-schedule .event-modal .event-date {
    display: none; }
  .cd-schedule .event-modal.no-transition {
    transition: none; }
    .cd-schedule .event-modal.no-transition .header-bg, .cd-schedule .event-modal.no-transition .body-bg {
      transition: none; }
  @media (min-width: 1024px) {
    .cd-schedule .event-modal {
      /* reset style */
      right: auto;
      width: auto;
      height: auto;
      transform: translateX(0);
      will-change: transform, width, height;
      transition: height .4s, width .4s, transform .4s, visibility .4s;
      transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); }
      .cd-schedule .event-modal .header {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%; }
        .cd-schedule .event-modal .header .content {
          /* reset style */
          display: block;
          padding: .8em; }
      .cd-schedule .event-modal .event-info > div {
        padding: 2em 3em 2em 2em; }
      .cd-schedule .event-modal .body {
        height: 100%;
        width: auto; }
      .cd-schedule .event-modal .header-bg, .cd-schedule .event-modal .body-bg {
        /* Force Hardware acceleration */
        transform: translateZ(0);
        will-change: transform;
        backface-visibility: hidden; }
      .cd-schedule .event-modal .header-bg {
        transition: transform .4s;
        transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); }
      .cd-schedule .event-modal .body-bg {
        opacity: 0;
        transform: none; }
      .cd-schedule .event-modal .event-date {
        display: block; }
      .cd-schedule .event-modal .close, .cd-schedule .event-modal .event-info {
        opacity: 0; }
      .cd-schedule .event-modal .close {
        width: 40px;
        height: 40px;
        background: transparent; }
        .cd-schedule .event-modal .close::after, .cd-schedule .event-modal .close::before {
          background: #424242;
          height: 16px; } }
  @media (min-width: 1230px) {
    .cd-schedule .event-modal .header .content {
      padding: 1.2em; } }

.cd-schedule.modal-is-open .event-modal {
  /* .modal-is-open class is added as soon as an event is selected */
  transform: translateX(0);
  visibility: visible; }
  .cd-schedule.modal-is-open .event-modal .event-info > div {
    /* smooth scroll on iOS touch devices */
    -webkit-overflow-scrolling: touch; }

@media (min-width: 1024px) {
  .cd-schedule.animation-completed .event-modal .close,
  .cd-schedule.content-loaded.animation-completed .event-modal .event-info {
    /* 	the .animation-completed class is added when the modal animation is completed
    	the .content-loaded class is added when the modal content has been loaded (using ajax) */
    opacity: 1;
    transition: opacity .2s; }

  .cd-schedule.modal-is-open .body-bg {
    opacity: 1;
    transition: transform .4s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1); } }
.cd-schedule .cover-layer {
  /* layer between the content and the modal window */
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s, visibility .4s; }

.cd-schedule.modal-is-open .cover-layer {
  opacity: 1;
  visibility: visible; }

.doctor-box {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 0 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }
  @media (max-width: 1023px) {
    .doctor-box {
      padding: 0 10px; } }
  .doctor-box > * {
    margin-bottom: 0; }
  .doctor-box > *:not(:first-child) {
    margin-top: 16px; }
  .doctor-box-photo {
    display: block;
    position: relative;
    overflow: hidden;
    transition: 0.35s;
    background-color: #fff;
    margin-left: -15px;
    margin-right: -15px;
    text-decoration: none; }
    .doctor-box-photo:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 120%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background: transparent;
      border-radius: 50%;
      box-shadow: inset 0 0 0 30px var(--mainColor);
      opacity: 0;
      transition: opacity 0 ease 0;
      pointer-events: none; }
  .doctor-box:hover .doctor-box-photo {
    border-radius: 50%; }
  .doctor-box:hover .doctor-box-photo:after {
    opacity: .2;
    transition: opacity 0.5s ease 0.2s; }
  .doctor-box-photo + .doctor-box-name {
    margin-top: 30px; }
  .doctor-box-name + .doctor-box-position {
    margin-top: 16px; }
  .doctor-box-name {
    line-height: 1em; }
  .doctor-box-position {
    font-size: 16px;
    line-height: 1em;
    font-weight: 400;
    color: var(--mainColor); }
  .doctor-box-phone {
    font-size: 18px;
    line-height: 30px;
    font-family: var(--fontFamily);
    font-weight: 600;
    color: #444;
    white-space: nowrap; }
    .doctor-box-phone a {
      color: #444;
      text-decoration: none; }
      .doctor-box-phone a:hover {
        color: var(--mainColor); }
    .doctor-box-phone [class*='icon-'] {
      position: relative;
      top: -1px;
      padding-right: 7px;
      font-size: 11px;
      color: var(--mainColor); }
    @media (max-width: 1023px) {
      .doctor-box-phone {
        font-size: 13px; } }
    @media (max-width: 767px) {
      .doctor-box-phone {
        font-size: 13px; } }

.doctor-box-bottom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px 30px;
  border-top: 1px solid #f7f7f7; }
  @media (max-width: 1023px) {
    .doctor-box-bottom {
      padding: 10px 0 20px; } }

.doctor-box-social {
  font-size: 15px;
  line-height: 30px;
  padding-left: 15px; }
  @media (max-width: 1023px) {
    .doctor-box-social {
      padding-left: 5px; } }
  .doctor-box-social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    color: var(--mainColor);
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
    transition: 0.2s; }
    .doctor-box-social a:hover {
      background-color: var(--mainColor);
      color: #fff;
      text-decoration: none;
      box-shadow: 0 0 0 3px rgba(30, 118, 189, 0.2); }

.doctor-box-top {
  margin: 0 -15px !important;
  padding: 20px 15px;
  background: #fafbfa; }

.doctor-box-shedule {
  margin: 20px auto;
  max-width: 250px;
  padding: 0;
  list-style: none; }
  .doctor-box-shedule li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px; }
    .doctor-box-shedule li span:first-child {
      color: #787878; }
    .doctor-box-shedule li span:last-child {
      color: #444444; }

.schedule-row {
  max-width: 250px; }
  .schedule-row > span {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .schedule-row > span span:last-child {
      color: #444444; }

.doctor-box-booking > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--fontFamily);
  color: var(--mainColor);
  border-top: 1px solid #fafafa;
  text-decoration: none; }
  @media (max-width: 767px) {
    .doctor-box-booking > a {
      height: 47px;
      font-size: 12px; } }
  .doctor-box-booking > a [class*='icon'] {
    position: relative;
    transition: 0.2s;
    left: 8px;
    font-size: 12px; }
  .doctor-box-booking > a:hover [class*='icon'] {
    left: 15px; }

@media (max-width: 1023px) {
  .specialist-carousel {
    margin-left: 50px;
    margin-right: 50px; } }
@media (max-width: 767px) {
  .specialist-carousel {
    margin: 0; } }
.specialist-carousel .slick-slide {
  padding-bottom: 20px; }

.specialist-carousel .slick-dots {
  margin-top: 30px; }

.doctor-box-style2 .doctor-box-name {
  margin-bottom: 14px; }
.doctor-box-style2 .doctor-box-position {
  font-size: 15px; }
.doctor-box-style2 .doctor-box-name + .doctor-box-position {
  margin-top: 10px; }
.doctor-box-style2 .doctor-box-photo:after {
  display: none; }
.doctor-box-style2 .doctor-box-photo {
  overflow: hidden; }
.doctor-box-style2 .doctor-box-photo img {
  -webkit-transition: transform 0.5s ease 0s;
  -o-transition: transform 0.5s ease 0s;
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  vertical-align: middle;
  max-width: 100%; }
.doctor-box-style2:hover .doctor-box-photo {
  border-radius: 0; }
.doctor-box-style2:hover .doctor-box-photo img {
  -webkit-transition: transform 0.6s ease 0.05s;
  -o-transition: transform 0.6s ease 0.05s;
  transition: -webkit-transform 0.6s ease 0.05s;
  transition: transform 0.6s ease 0.05s;
  transition: transform 0.6s ease 0.05s, -webkit-transform 0.6s ease 0.05s;
  -webkit-transform: scale(1.1) rotate(0.0001deg);
  -ms-transform: scale(1.1) rotate(0.0001deg);
  transform: scale(1.1) rotate(0.0001deg); }

.doctor-box-style3 {
  box-shadow: none; }
  .doctor-box-style3 .doctor-box-name {
    margin-bottom: 14px; }
  .doctor-box-style3 .doctor-box-position {
    font-size: 16px; }
  .doctor-box-style3 cname + .doctor-box-position {
    margin-top: 10px; }
  .doctor-box-style3 .doctor-box-photo:after {
    display: none; }
  .doctor-box-style3 .doctor-box-photo {
    overflow: hidden; }
  .doctor-box-style3 .doctor-box-top {
    margin: 0 !important;
    padding: 25px 15px 0;
    background-color: transparent; }
  .doctor-box-style3 .doctor-box-photo img {
    -webkit-transition: transform 0.5s ease 0s;
    -o-transition: transform 0.5s ease 0s;
    transition: -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    image-rendering: optimizeQuality;
    vertical-align: middle;
    max-width: 100%; }
  .doctor-box-style3:hover .doctor-box-photo {
    border-radius: 0; }
  .doctor-box-style3:hover .doctor-box-photo img {
    -webkit-transition: transform 0.6s ease 0.05s;
    -o-transition: transform 0.6s ease 0.05s;
    transition: -webkit-transform 0.6s ease 0.05s;
    transition: transform 0.6s ease 0.05s;
    transition: transform 0.6s ease 0.05s, -webkit-transform 0.6s ease 0.05s;
    -webkit-transform: scale(1.1) rotate(0.0001deg);
    -ms-transform: scale(1.1) rotate(0.0001deg);
    transform: scale(1.1) rotate(0.0001deg); }
  .doctor-box-style3 .doctor-box-links {
    position: absolute;
    z-index: 1;
    top: 19px;
    right: 0;
    width: 42px;
    background-color: #ffffff; }
    .doctor-box-style3 .doctor-box-links > * {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-decoration: none;
      height: 42px;
      width: 42px;
      color: var(--mainColor);
      transition: .2s; }
      .doctor-box-style3 .doctor-box-links > *:hover {
        color: #444; }

.specialist-carousel2 .slick-list {
  overflow: visible; }
.specialist-carousel2 .slick-dots {
  margin: 50px 0 0; }
  @media (max-width: 1023px) {
    .specialist-carousel2 .slick-dots {
      margin: 35px 0 0; } }
  @media (max-width: 575px) {
    .specialist-carousel2 .slick-dots {
      margin: 25px 0 0; } }
@media (max-width: 767px) {
  .specialist-carousel2 {
    margin-left: 0;
    margin-right: 0; } }

.doctor-box-h {
  display: flex;
  padding: 30px;
  opacity: .35;
  pointer-events: none;
  transition: opacity 0.5s; }
  @media (max-width: 1023px) {
    .doctor-box-h {
      padding: 25px;
      margin: 0 15px; } }
  @media (max-width: 767px) {
    .doctor-box-h {
      flex-direction: column;
      max-width: 370px;
      margin-left: auto;
      margin-right: auto;
      padding: 15px; } }
  .doctor-box-h-photo {
    width: 46%; }
    @media (max-width: 1023px) {
      .doctor-box-h-photo {
        width: 43%; } }
    @media (max-width: 767px) {
      .doctor-box-h-photo {
        width: 100%; } }
    .doctor-box-h-photo img {
      max-width: 100%;
      width: auto; }
  .doctor-box-h-name {
    font-size: 24px;
    line-height: 1em; }
    @media (max-width: 1023px) {
      .doctor-box-h-name {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .doctor-box-h-name {
        font-size: 18px; } }

.doctor-box-h-wrap.slick-active .doctor-box-h {
  opacity: 1;
  background-color: #ffffff;
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.07);
  pointer-events: all; }

.doctor-box-h-info {
  width: 54%;
  padding: 0 20px 0 50px;
  align-self: center; }
  @media (max-width: 1023px) {
    .doctor-box-h-info {
      width: 57%;
      padding: 0 0 0 25px; } }
  @media (max-width: 767px) {
    .doctor-box-h-info {
      width: 100%;
      padding: 20px 0 0; } }
  .doctor-box-h-info > * {
    margin-bottom: 0; }
  .doctor-box-h-info > *:not(:first-child) {
    margin-top: 32px; }
    @media (max-width: 1023px) {
      .doctor-box-h-info > *:not(:first-child) {
        margin-top: 25px; } }
    @media (max-width: 767px) {
      .doctor-box-h-info > *:not(:first-child) {
        margin-top: 20px; } }

.doctor-box-h-table {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  color: #787878; }
  @media (max-width: 1023px) {
    .doctor-box-h-table {
      font-size: 15px;
      line-height: 21px; } }
  @media (max-width: 767px) {
    .doctor-box-h-table {
      font-size: 14px;
      line-height: 20px; } }
  @media (max-width: 575px) {
    .doctor-box-h-table {
      font-size: 12px; } }
  .doctor-box-h-table td {
    padding: 7px 10px;
    vertical-align: top;
    background-color: #fff;
    border: 0; }
    @media (max-width: 767px) {
      .doctor-box-h-table td {
        padding: 5px; } }
  .doctor-box-h-table th {
    padding: 7px 10px;
    vertical-align: top;
    background-color: #fff;
    border: 0;
    font-size: 16px;
    font-weight: 600; }
    @media (max-width: 1023px) {
      .doctor-box-h-table th {
        padding: 5px 10px;
        font-size: 15px; } }
    @media (max-width: 767px) {
      .doctor-box-h-table th {
        font-size: 14px; } }
    @media (max-width: 575px) {
      .doctor-box-h-table th {
        font-size: 12px; } }
  .doctor-box-h-table tr:nth-child(odd) td {
    background-color: #f9f9f9; }
  .doctor-box-h-table tr > td:first-child {
    font-weight: 600;
    color: #444444; }
    @media (max-width: 767px) {
      .doctor-box-h-table tr > td:first-child {
        font-weight: 500; } }

@media (max-width: 767px) {
  .doctor-box-h-booking {
    text-align: center; } }
.doctor-info {
  display: flex; }
  @media (max-width: 1023px) {
    .doctor-info {
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .doctor-info > * + * {
        margin-top: 5px; } }
  .doctor-info-phone {
    padding: 0 30px;
    font-size: 18px;
    line-height: 37px;
    font-family: var(--fontFamily);
    font-weight: 600;
    color: #444; }
    .doctor-info-phone a {
      color: #444;
      text-decoration: none; }
      .doctor-info-phone a:hover {
        color: var(--mainColor); }
    .doctor-info-phone [class*='icon-'] {
      position: relative;
      top: -1px;
      padding-right: 7px;
      font-size: 11px;
      color: var(--mainColor); }
  .doctor-info-name {
    flex: 1; }
    .doctor-info-name h3 {
      font-size: 32px;
      line-height: 1em;
      margin-bottom: 18px; }
    .doctor-info-name h6 {
      font-size: 16px;
      line-height: 1em;
      margin-bottom: 0;
      color: var(--mainColor); }

.doctor-info-social {
  font-size: 15px;
  line-height: 37px; }
  .doctor-info-social a {
    display: inline-block;
    width: 37px;
    height: 37px;
    color: var(--mainColor);
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
    transition: 0.2s; }
    .doctor-info-social a:hover {
      background-color: var(--mainColor);
      color: #fff;
      text-decoration: none;
      box-shadow: 0 0 0 3px rgba(30, 118, 189, 0.2); }

.doctor-review-row {
  display: flex;
  align-items: center; }
  .doctor-review-row > * {
    padding-right: 15px; }
  .doctor-review-row .star-rating {
    color: var(--mainColor);
    position: relative;
    top: -2px; }

.doctor-page-table, .doctor-page-table td {
  border: 0; }

.column-filters {
  margin-bottom: 35px; }
  .column-filters-inside {
    background: url("../images/bg-grey.jpg") repeat #f7f7f7; }
  .column-filters .side-block {
    padding: 20px 20px 30px;
    margin-top: 0 !important;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6; }
    .column-filters .side-block:last-child {
      border-bottom: 0;
      margin-bottom: 0; }
  .column-filters .side-block > h3 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600; }

ul.category-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 20px; }
  ul.category-list li {
    position: relative;
    padding-left: 19px; }
    ul.category-list li.active {
      font-weight: 700; }
    ul.category-list li:before {
      font-family: 'dentco';
      content: '\e900';
      font-size: 8px;
      position: absolute;
      left: 0;
      top: 0;
      color: var(--mainColor);
      font-weight: 400; }
    ul.category-list li a:hover {
      text-decoration: none; }
  ul.category-list li:not(:last-child) {
    margin-bottom: 18px; }
    @media (max-width: 1023px) {
      ul.category-list li:not(:last-child) {
        margin-bottom: 14px; } }

/*range slider*/
.noUi-horizontal {
  height: 6px; }

.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  left: -7.5px;
  top: -5px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: var(--mainColor);
  border-radius: 50%;
  cursor: pointer; }

.noUi-target {
  border-radius: 0;
  border: 0;
  box-shadow: none;
  background-color: #e6e6e6;
  margin-bottom: 24px; }

.noUi-origin {
  border-radius: 0;
  box-shadow: none; }

.noUi-connect {
  background: #6a6a6a;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  height: 6px;
  transition: background 450ms; }

.noUi-handle:before,
.noUi-handle:after {
  display: none;
  border: 0;
  border-radius: 0;
  transition: background 450ms; }

.range {
  margin-top: 35px; }

.range p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px; }

.prd-sm {
  margin-bottom: 25px;
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }
  .prd-sm:not(:last-child) {
    margin-bottom: 20px; }
  .prd-sm-img {
    width: 87px;
    min-width: 87px; }
    .prd-sm-img img {
      border: 1px solid #f0f0f0; }
  .prd-sm-delete {
    position: absolute;
    top: 0;
    right: -4px;
    cursor: pointer;
    font-size: 16px;
    color: var(--mainColor); }
    .prd-sm-delete:hover {
      opacity: .5; }
  .prd-sm-info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 15px; }
    .prd-sm-info h3 {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
      font-family: var(--fontFamily2);
      text-transform: none;
      letter-spacing: -.01em;
      color: #787878; }
      .prd-sm-info h3 a {
        display: block;
        text-decoration: none;
        transition: .2s;
        color: #787878; }
        .prd-sm-info h3 a:hover {
          color: #1e76bd !important;
          text-decoration: none; }
    .prd-sm-info .price {
      margin-top: auto;
      padding-top: 15px;
      font-size: 20px;
      font-weight: 600;
      color: #424242; }

.header-cart-dropdown .prd-sm-info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding-right: 30px; }

.prd-grid:not(.prd-carousel) {
  margin: -25px -15px 0; }

.prd-grid.prd-carousel {
  margin: 0; }
  .prd-grid.prd-carousel .prd {
    margin-bottom: 30px; }
  .prd-grid.prd-carousel .slick-dots {
    margin-top: 15px; }

.prd-grid:not(.prd-carousel) .prd {
  width: calc(33.333% - 30px);
  float: left; }

.prd {
  background: #fff;
  margin: 30px 15px 0;
  padding-bottom: 25px;
  border: 1px solid #f0f0f0;
  transition: .3s; }
  @media (max-width: 575px) {
    .prd {
      margin-top: 15px; } }
  .prd:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-color: transparent; }
  .prd-img {
    border-bottom: 1px solid #f0f0f0; }
    .prd-img img {
      width: 100%; }
  .prd-info {
    padding: 0 15px;
    text-align: center; }
    @media (max-width: 575px) {
      .prd-info {
        padding: 0 8px; } }
    .prd-info > * {
      margin-top: 15px; }
      @media (max-width: 575px) {
        .prd-info > * {
          margin-top: 10px; } }
    .prd-info h3 {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
      font-family: var(--fontFamily2);
      color: #787878;
      text-transform: none; }
      .prd-info h3 a {
        display: block;
        text-decoration: none;
        color: #787878;
        transition: .2s; }
        .prd-info h3 a:hover {
          color: #1e76bd !important;
          text-decoration: none; }
    .prd-info .price {
      font-size: 26px;
      margin-top: 13px;
      font-weight: 600;
      font-family: var(--fontFamily);
      color: #5f5f5f; }
    .prd-info .star-rating {
      margin-top: 8px; }
    .prd-info .btn, body:not(.touch) .prd-info .btn:focus {
      font-size: 15px;
      line-height: 19px;
      padding: 10px 18px;
      border-radius: 22px;
      text-transform: none; }
      @media (max-width: 767px) {
        .prd-info .btn, body:not(.touch) .prd-info .btn:focus {
          padding: 10px 15px; }
          .prd-info .btn i, body:not(.touch) .prd-info .btn:focus i {
            display: none; } }

@media (max-width: 575px) {
  .prd-md-grid .col-xs-6 {
    width: 100%; } }
@media (min-width: 1024px) {
  .prd-grid:not(.prd-carousel) .prd:nth-child(3n) {
    margin-right: 0; }

  .prd-grid:not(.prd-carousel) .prd:nth-child(3n+1) {
    clear: left; } }
@media (min-width: 768px) and (max-width: 1023px) {
  .prd-grid:not(.prd-carousel) .prd {
    width: calc(50% - 30px); }

  .prd-grid:not(.prd-carousel) .prd:nth-child(2n) {
    margin-right: 0; }

  .prd-grid:not(.prd-carousel) .prd:nth-child(2n+1) {
    clear: left; } }
@media (max-width: 767px) {
  .prd-grid:not(.prd-carousel) .prd {
    width: calc(50% - 30px); }

  .prd-grid:not(.prd-carousel) .prd:nth-child(2n) {
    margin-right: 0; }

  .prd-grid:not(.prd-carousel) .prd:nth-child(2n+1) {
    clear: left; } }
@media (max-width: 374px) {
  .prd-grid {
    margin-right: 0;
    margin-left: 0; }

  .prd-grid:not(.prd-carousel) .prd {
    width: calc(100% - 30px); }

  .prd-info a.btn span {
    display: none; } }
.product-card img {
  width: 100%; }

.prd-carousel .slick-next {
  right: -50px; }
.prd-carousel .slick-prev {
  left: -50px; }

.filters-row {
  display: flex;
  width: 100%;
  padding: 0 0 25px;
  font-size: 16px; }
  @media (max-width: 1023px) {
    .filters-row {
      flex-direction: column;
      font-size: 15px; } }
  .filters-row-left {
    margin-right: auto; }
  .filters-row-right {
    margin-left: auto; }
    @media (max-width: 1023px) {
      .filters-row-right {
        margin: 10px auto 0 0; } }
  .filters-row .form-inline {
    display: inline-block; }
    .filters-row .form-inline:not(:first-child) {
      margin-left: 20px; }
    .filters-row .form-inline select {
      min-width: 200px; }
      @media (max-width: 1023px) {
        .filters-row .form-inline select {
          min-width: 100px; } }
    @media (max-width: 575px) {
      .filters-row .form-inline {
        display: block;
        margin-left: 0 !important;
        margin-top: 10px; } }
  .filters-row .pagination {
    margin-bottom: 0; }
  .filters-row .pagination .page-link {
    font-size: 14px;
    line-height: 26px;
    width: 30px;
    height: 30px; }

.simple-pagination {
  display: inline-block;
  font-size: 15px; }
  .simple-pagination span,
  .simple-pagination a.pagination-arrow {
    display: inline-block;
    padding: 3px 13px;
    vertical-align: top;
    text-decoration: none; }
  .simple-pagination a:not(.pagination-arrow) {
    display: inline-block;
    padding: 3px 13px;
    border: 1px solid #717171;
    border-radius: 2px;
    vertical-align: top;
    color: #717171;
    text-decoration: none; }
  .simple-pagination a.active,
  .simple-pagination a:hover {
    border-color: var(--mainColor); }
  .simple-pagination a.pagination-arrow:hover {
    color: #1e76bd !important; }
  .simple-pagination a + a {
    margin-left: 2px; }

.product-block .rating-text {
  font-size: 15px;
  color: #787878;
  cursor: pointer;
  text-decoration: underline; }
  .product-block .rating-text:hover {
    text-decoration: none; }
.product-block .rating-text:not(:first-child) {
  margin-left: 25px; }

.product-block-info > *:not(:first-child):not(span) {
  margin-top: 20px; }

.product-previews {
  float: left;
  width: 106px; }
  @media (max-width: 1023px) {
    .product-previews {
      width: 80px; } }
  @media (max-width: 575px) {
    .product-previews {
      width: 60px; } }
  .product-previews img {
    width: 100%; }

.product-previews-item {
  display: block;
  margin-bottom: 11px;
  border: 1px solid #f0f0f0;
  opacity: .7;
  transition: .2s; }
  .product-previews-item:hover, .product-previews-item.active {
    opacity: 1; }

.product-block-add-to-cart {
  height: 52px !important;
  font-size: 15px !important;
  line-height: 22px !important;
  padding: 14px 33px !important; }
  @media (max-width: 1023px) {
    .product-block-add-to-cart {
      padding-right: 20px !important;
      padding-left: 20px !important; } }
  .product-block-add-to-cart [class*='icon'] {
    top: 1px !important;
    font-size: 16px !important;
    margin: 0 0 0 15px !important; }

.product-block-gallery {
  margin-bottom: 30px;
  overflow: hidden; }

.product-block-mainimage {
  float: right;
  width: calc(100% - 127px);
  border: 1px solid #f0f0f0; }
  @media (max-width: 1023px) {
    .product-block-mainimage {
      width: calc(100% - 100px); } }
  @media (max-width: 575px) {
    .product-block-mainimage {
      width: calc(100% - 85px); } }
  .product-block-mainimage img {
    width: 100%; }

.product-block-title {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 0;
  margin-top: -.25em; }
  @media (max-width: 1023px) {
    .product-block-title {
      font-size: 28px;
      line-height: 36px; } }
  @media (max-width: 575px) {
    .product-block-title {
      font-size: 22px;
      line-height: 32px; } }

.product-block-price {
  margin-top: auto;
  font-size: 32px;
  line-height: 1em;
  font-weight: 700;
  font-family: var(--fontFamily);
  color: var(--mainColor); }

.product-block-price-comment {
  font-size: 14px;
  margin-top: 5px !important; }

@media (max-width: 575px) {
  .btn.product-block-add-to-cart {
    width: 100%;
    margin-top: 10px; } }
.product-block-description {
  padding: 10px 0 0;
  font-size: 16px;
  line-height: 26px; }
  @media (max-width: 1023px) {
    .product-block-description {
      font-size: 15px;
      line-height: 20px; } }
  @media (max-width: 575px) {
    .product-block-description {
      padding: 10px 0;
      font-size: 14px;
      line-height: 18px; } }
  .product-block-description table {
    margin-bottom: 0; }
  .product-block-description table td,
  .product-block-description table tr {
    border: 0 !important;
    padding: 14px;
    color: #787878; }
  .product-block-description table td:first-child {
    color: #444; }
    .product-block-description table td:first-child span {
      position: relative;
      padding-left: 35px; }
      @media (max-width: 1023px) {
        .product-block-description table td:first-child span {
          padding-left: 20px; } }
      @media (max-width: 767px) {
        .product-block-description table td:first-child span {
          padding-left: 15px; } }
      .product-block-description table td:first-child span:before {
        position: absolute;
        top: -1px;
        left: 10px;
        display: inline-block;
        vertical-align: middle;
        content: '\e906';
        font-size: 10px;
        font-family: 'dentco';
        color: var(--mainColor);
        transition: .2s; }
        @media (max-width: 1023px) {
          .product-block-description table td:first-child span:before {
            top: -1px;
            left: 0; } }

.product-block-actions {
  display: flex;
  align-items: center;
  margin-top: 37px !important; }
  @media (max-width: 575px) {
    .product-block-actions {
      flex-direction: column;
      text-align: center; } }

.qty-count {
  height: 51px;
  background-color: transparent;
  display: inline-block;
  border-radius: 25px;
  margin-right: 25px;
  border: 1px solid #d5d5d5; }
  @media (max-width: 575px) {
    .qty-count {
      margin-right: 0; } }

.count-add,
.count-input,
.count-reduce {
  float: left;
  margin-right: -1px;
  position: relative;
  z-index: 0; }

.count-add,
.count-reduce {
  height: 49px;
  width: 40px;
  text-align: center;
  line-height: 49px;
  font-size: 24px;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none; }

.count-reduce {
  line-height: 47px; }

.count-add:hover,
.count-reduce:hover {
  cursor: pointer;
  color: var(--mainColor); }

.count-input {
  width: 30px;
  height: 49px;
  border: 0;
  border-radius: 0;
  text-align: center;
  background-color: transparent; }
  .count-input:focus {
    outline: 0;
    border: 0; }

.zoomWrapper {
  position: relative; }

.zoomContainer {
  z-index: 100; }

.zoomLens {
  border: 1px solid #b0b0b0 !important; }

.zoomWindowContainer > * {
  border: 2px solid #b0b0b0 !important; }

.prd-tabs-wrap .btn, body:not(.touch) .prd-tabs-wrap .btn:focus {
  height: 51px;
  padding: 15px;
  font-size: 15px;
  line-height: 18px; }
.prd-tabs-wrap .nav-pills .nav-link {
  margin: 0;
  padding: 14px 24px !important;
  cursor: pointer;
  text-shadow: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 14px 16px;
  color: #787878;
  border: 1px solid #f0f0f0;
  border-bottom: 2px solid transparent;
  border-radius: 0; }
  .prd-tabs-wrap .nav-pills .nav-link:hover {
    background-color: #f8f8f8; }
  .prd-tabs-wrap .nav-pills .nav-link.active {
    border-bottom-color: var(--mainColor);
    color: var(--mainColor);
    box-shadow: none; }
.prd-tabs-wrap .nav-pills .nav-link + * {
  margin-left: -1px; }
.prd-tabs-wrap .tab-content {
  border: 1px solid #f0f0f0;
  padding: 35px; }
  @media (max-width: 575px) {
    .prd-tabs-wrap .tab-content {
      padding: 15px; } }
.prd-tabs-wrap h6 {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--fontFamily2); }
.prd-tabs-wrap .star-rating:not(:last-child) {
  margin-bottom: 10px; }

@media (min-width: 1024px) {
  body.shop-page .header-nav {
    display: inline-block; }

  .header-content .col-lg.ml-auto {
    text-align: right; } }
@media (max-width: 1023px) {
  body.shop-page .header-content .col-lg:last-child {
    position: static; } }
